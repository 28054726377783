// reducer is not using currently
const Reducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      return {
        ...state,
        isAuthenticated: true,
      };

    case "LOGOUT":
      return {
        ...state,
        isAuthenticated: false,
      };
    case "USER_NAME":
      return {
        ...state,
        userName: action.payload,
      };
    case "PROFILE_PIC":
      return {
        ...state,
        profilePic: action.payload,
      };
    // case "NOTIFICATION":
    //   return {
    //     ...state,
    //     notification: action.payload,
    //   };

    // case "API_CHANGE":
    //   localStorage.setItem("olly_url", action.payload.api);
    //   localStorage.setItem("login_phone", action.payload.phone);
    //   return {
    //     ...state,
    //     isAuthenticated: false,
    //     api: action.payload.api,
    //   };

    default:
      return state;
  }
};

export default Reducer;
