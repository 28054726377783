import axios from "axios";

let API_URL;
// set api url according to environment

  // window.location.origin === "https://userwebsite.ollylogistics.com" ||
  // window.location.origin === "https://master.d1wtcs5zwf1647.amplifyapp.com/"
if (window.location.origin === "https://www.shipper.ollytrucks.com") {
    console.log("Running in Production Environment.");
    API_URL = "https://api.olly.scrapp.app/";
    // API_URL = "https://api.olly.scrapp.app/";
} else if (window.location.origin === "https://staging.shipper.ollytrucks.com") {
  // API_URL = "https://api.olly.scrapp.app/";
  console.log("Running in staging Environment.");
  API_URL = "https://staging-api.olly.scrapp.app";
} else {
  console.log("Running in Development Environment.");
  API_URL = "https://dev-api.olly.scrapp.app";
}

console.log(`Web URL: `, window.location.origin);
console.log(`API Base URL: `, API_URL);

/*
    Used to configure the base API URL for Axios.
    Import this module wherever API call is necessary.
*/

export default axios.create({
  baseURL: API_URL,
});
