import React from "react";
import "./MobileHeader.css";
import Images from "../../assets";
import imageSelector from "../../utils/LangImages";

export default function MobileHeader() {
  return (
    <div className="mobile-header">
      {/* <img src={Images.olly_logo} className="olly_logo" /> */}
      <img src={imageSelector("logo")} className="olly_text" />
    </div>
  );
}
