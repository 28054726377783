/* eslint-disable jsx-a11y/alt-text */
// This file contains functions which display different type of alerts
import React from "react";
import Images from "../assets";
import { confirmAlert } from "react-confirm-alert";
import "../constants/ConfirmAlert.css";
import "../constants/CustomConfirmAlert.css";
import languageSelector from "./LanguagesForNetworkError";

// function to display success alerts
function SuccessAlert(
  title = "Success",
  afterClose = () => {},
  message = "",
  closeOnClickOutside = false
) {
  confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <div className="react-confirm-alert-body">
          <div className="img-container">
            <img src={Images.greenTick} className="alert-img" />
          </div>
          <h1>{title}</h1>
          <div className>{message}</div>
          <div className="btn-group">
            <button onClick={onClose}>{languageSelector("Okay")}</button>
          </div>
        </div>
      );
    },
    afterClose: afterClose,
    closeOnClickOutside: closeOnClickOutside,
  });
}

// function to display failure alerts
function FailureAlert(
  title = "Action Failed",
  message = "Something went wrong. Please try again later.",
  afterClose = () => {}
) {
  confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <div className="react-confirm-alert-body">
          <div className="img-container">
            <img src={Images.redCross} className="alert-img" />
          </div>
          <h1>{title}</h1>
          <div className>{message}</div>
          <div className="btn-group">
            <button onClick={onClose}>{languageSelector("Okay")}</button>
          </div>
        </div>
      );
    },
    afterClose: afterClose,
  });
}

// function to display payment failure alerts
function PaymentFailedAlert(
  title = "Action Failed",
  message = "Something went wrong. Please try again later.",
  afterClose = () => {}
) {
  confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <div className="react-confirm-alert-body">
          <i
            class="fa fa-exclamation-circle fa-5x"
            aria-hidden="true"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "#a3a3a3",
            }}
          ></i>
          <h1>{title}</h1>
          <div className>{message}</div>
          <div className="btn-group">
            <button onClick={onClose}>{languageSelector("Okay")}</button>
          </div>
        </div>
      );
    },
    afterClose: afterClose,
  });
}

function SimpleAlert(
  title = "Action Failed",
  message = "Something went wrong. Please try again later.",
  afterClose = () => {}
) {
  confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <div className="react-confirm-alert-body">
          <h1>{title}</h1>
          <div className>{message}</div>
          <div className="btn-group">
            <button onClick={onClose}>{languageSelector("Okay")}</button>
          </div>
        </div>
      );
    },
    afterClose: afterClose,
  });
}
function ComplexAlert(
  title = "Action failed",
  message = "",
  SuccessMessage = "",
  onSuccess = () => {},
  FailureMessage = "",
  onFailure = () => {}
) {
  confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <div className="react-confirm-alert-body">
          <h1>{title}</h1>
          <div className>{message}</div>
          <div className="btn-group">
            <button
              onClick={() => {
                onFailure();
                onClose();
              }}
            >
              {FailureMessage}
            </button>
            <button
              onClick={() => {
                onSuccess();
                onClose();
              }}
            >
              {SuccessMessage}
            </button>
          </div>
        </div>
      );
    },
  });
}
export {
  SuccessAlert,
  FailureAlert,
  SimpleAlert,
  ComplexAlert,
  PaymentFailedAlert,
};
