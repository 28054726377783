import React from "react";
import "./PrivacyPolicy.css";

export default function TermsAndConditions() {
  return (
    <div className="container">
      <h1 className="policy-title">Terms and Conditions</h1>
      <div>Updated at: December 3, 2020</div>

      <h1>General Terms</h1>
      <div className="paragraph">
        By accessing and placing an order with Olly Logistics , you confirm that
        you are in agreement with and bound by the terms of service contained in
        the Terms &amp; Conditions outlined below. These terms apply to the
        entire website and any email or other type of communication between you
        and Olly Logistics .
      </div>
      <div className="paragraph">
        Under no circumstances shall Olly Logistics team be liable for any
        direct, indirect, special, incidental or consequential damages,
        including, but not limited to, loss of data or profit, arising out of
        the use, or the inability to use, the materials on this site, even if
        Olly Logistics team or an authorized representative has been advised of
        the possibility of such damages. If your use of materials from this site
        results in the need for servicing, repair or correction of equipment or
        data, you assume any costs thereof.
      </div>
      <div className="paragraph">
        Olly Logistics will not be responsible for any outcome that may occur
        during the course of usage of our resources. We reserve the rights to
        change prices and revise the resources usage policy in any moment.
      </div>

      <h1>License</h1>
      <div className="paragraph">
        Olly Logistics grants you a revocable, non-exclusive, non-transferable,
        limited license to download, install and use the app strictly in
        accordance with the terms of this Agreement.
      </div>
      <div className="paragraph">
        These Terms &amp; Conditions are a contract between you and Olly
        Logistics (referred to in these Terms &amp; Conditions as &quot;Olly
        Logistics &quot;, &quot;us&quot;, &quot;we&quot; or &quot;our&quot;),
        the provider of the Olly Logistics website and the services accessible
        from the Olly Logistics website (which are collectively referred to in
        these Terms &amp; Conditions as the &quot;Olly Logistics Service&quot;).
      </div>
      <div className="paragraph">
        You are agreeing to be bound by these Terms &amp; Conditions. If you do
        not agree to these Terms &amp; Conditions, please do not use the Olly
        Logistics Service. In these Terms &amp; Conditions, &quot;you&quot;
        refers both to you as an individual and to the entity you represent. If
        you violate any of these Terms &amp; Conditions, we reserve the right to
        cancel your account or block access to your account without notice.
      </div>

      <h1>Definitions and key terms</h1>
      <div className="paragraph">
        To help explain things as clearly as possible in this Terms &amp;
        Conditions, every time any of these terms are referenced, are strictly
        defined as:
      </div>
      <ul>
        <li>
          Cookie:Â small amount of data generated by a website and saved by your
          web browser. It is used to identify your browser, provide analytics,
          remember information about you such as your language preference or
          login information.
        </li>
        <li>
          Company: when this policy mentions a Company, "us", "we" or "our",it
          refers to Plastline, P. O. Box No: 6361, Dammam 31442 that is
          responsible for your information under this Terms &amp; Conditions.
        </li>
        <li>
          Country: where Olly Logistics or the owners/founders of Olly Logistics
          are based, in this case is IN
        </li>
        <li>
          Device:Â any internet connected device such as a phone, tablet,
          computer or any other device that can be used to visit Olly Logistics
          and use the services.
        </li>
        <li>
          Service: refers to the service provided by Olly Logistics as described
          in the relative terms (if available) and on this platform.
        </li>
        <li>
          Third-party service:Â refers to advertisers, contest sponsors,
          promotional and marketing partners, and others who provide our content
          or whose products or services we think may interest you.
        </li>
        <li>
          Website: Olly Logistics &#39;s site, which can be accessed via this
          URL:{" "}
        </li>
        <li>
          You: a person or entity that is registered with Olly Logistics to use
          the Services.
        </li>
      </ul>

      <h1>Restrictions</h1>
      <div className="paragraph">
        {" "}
        You agree not to, and you will not permit others to:
      </div>
      <ul>
        <li>
          License, sell, rent, lease, assign, distribute, transmit, host,
          outsource, disclose or otherwise commercially exploit the app or make
          the platform available to any third party.
        </li>
        <li>
          Modify, make derivative works of, disassemble, decrypt, reverse
          compile or reverse engineer any part of the app.
        </li>
        <li>
          Remove, alter or obscure any proprietary notice (including any notice
          of copyright or trademark) of Olly Logistics or its affiliates,
          partners, suppliers or the licensors of the app.
        </li>
      </ul>
      <h1>Return and Refund Policy</h1>
      <div className="paragraph">
        Thanks for shopping at Olly Logistics . We appreciate the fact that you
        like to buy the stuff we build. We also want to make sure you have a
        rewarding experience while youâre exploring, evaluating, and purchasing
        our products.
      </div>
      <div className="paragraph">
        As with any shopping experience, there are terms and conditions that
        apply to transactions at Olly Logistics . Weâll be as brief as our
        attorneys will allow. The main thing to remember is that by placing an
        order or making a purchase at Olly Logistics , you agree to the terms
        along with Olly Logistics &#39;sÂ Privacy Policy.
      </div>
      <div className="paragraph">
        If, for any reason, You are not completely satisfied with any good or
        service that we provide, don&#39;t hesitate to contact us and we will
        discuss any of the issues you are going through with our product.
      </div>

      <h1>Your Suggestions</h1>
      <div className="paragraph">
        Any feedback, comments, ideas, improvements or suggestions
        (collectively, &quot;Suggestions&quot;) provided by you to Olly
        Logistics with respect to the app shall remain the sole and exclusive
        property of Olly Logistics .
      </div>
      <div className="paragraph">
        Olly Logistics shall be free to use, copy, modify, publish, or
        redistribute the Suggestions for any purpose and in any way without any
        credit or any compensation to you.
      </div>

      <h1>Your Consent</h1>
      <div className="paragraph">
        We&#39;ve updated ourÂ Terms &amp; ConditionsÂ to provide you with
        complete transparency into what is being set when you visit our site and
        how it&#39;s being used. By using our app, registering an account, or
        making a purchase, you hereby consent to our Terms &amp; Conditions.
      </div>

      <h1>Links to Other Websites</h1>
      <div className="paragraph">
        This Terms &amp; Conditions applies only to the Services. The Services
        may contain links to other websites not operated or controlled by Olly
        Logistics . We are not responsible for the content, accuracy or opinions
        expressed in such websites, and such websites are not investigated,
        monitored or checked for accuracy or completeness by us. Please remember
        that when you use a link to go from the Services to another website, our
        Terms &amp; Conditions are no longer in effect. Your browsing and
        interaction on any other website, including those that have a link on
        our platform, is subject to that websiteâs own rules and policies. Such
        third parties may use their own cookies or other methods to collect
        information about you.
      </div>

      <h1>Cookies</h1>
      <div className="paragraph">
        Olly Logistics uses &quot;Cookies&quot; to identify the areas of our
        website that you have visited. A Cookie is a small piece of data stored
        on your computer or mobile device by your web browser. We use Cookies to
        enhance the performance and functionality of our app but are
        non-essential to their use. However, without these cookies, certain
        functionality like videos may become unavailable or you would be
        required to enter your login details every time you visit the app as we
        would not be able to remember that you had logged in previously. Most
        web browsers can be set to disable the use of Cookies. However, if you
        disable Cookies, you may not be able to access functionality on our
        website correctly or at all. We never place Personally Identifiable
        Information in Cookies.
      </div>

      <h1>Changes To Our Terms &amp; Conditions</h1>
      <div className="paragraph">
        You acknowledge and agree that may stop (permanently or temporarily)
        providing the Service (or any features within the Service) to you or to
        users generally at âs sole discretion, without prior notice to you. You
        may stop using the Service at any time. You do not need to specifically
        inform when you stop using the Service. You acknowledge and agree that
        if disables access to your account, you may be prevented from accessing
        the Service, your account details or any files or other materials which
        is contained in your account.
      </div>
      <div className="paragraph">
        If we decide to change our Terms &amp; Conditions, we will post those
        changes on this page, and/or update the Terms &amp; Conditions
        modification date below.
      </div>

      <h1>Modifications to Our app</h1>
      <div className="paragraph">
        Olly Logistics reserves the right to modify, suspend or discontinue,
        temporarily or permanently, the app or any service to which it connects,
        with or without notice and without liability to you.
      </div>

      <h1>Updates to Our app</h1>
      <div className="paragraph">
        Olly Logistics may from time to time provide enhancements or
        improvements to the features/ functionality of the app, which may
        include patches, bug fixes, updates, upgrades and other modifications
        (&quot;Updates&quot;).
      </div>
      <div className="paragraph">
        Updates may modify or delete certain features and/or functionalities of
        the app. You agree that Olly Logistics has no obligation to (i) provide
        any Updates, or (ii) continue to provide or enable any particular
        features and/or functionalities of the app to you.
      </div>
      <div className="paragraph">
        You further agree that all Updates will be (i) deemed to constitute an
        integral part of the app, and (ii) subject to the terms and conditions
        of this Agreement.
      </div>

      <h1>Third-Party Services</h1>
      <div className="paragraph">
        We may display, include or make available third-party content (including
        data, information, applications and other products services) or provide
        links to third-party websites or services (&quot;Third- Party
        Services&quot;).
      </div>
      <div className="paragraph">
        You acknowledge and agree that Olly Logistics shall not be responsible
        for any Third-Party Services, including their accuracy, completeness,
        timeliness, validity, copyright compliance, legality, decency, quality
        or any other aspect thereof. Olly Logistics does not assume and shall
        not have any liability or responsibility to you or any other person or
        entity for any Third-Party Services.
      </div>
      <div className="paragraph">
        Third-Party Services and links thereto are provided solely as a
        convenience to you and you access and use them entirely at your own risk
        and subject to such third parties&#39; terms and conditions.
      </div>

      <h1>Term and Termination</h1>
      <div className="paragraph">
        This Agreement shall remain in effect until terminated by you or Olly
        Logistics .
      </div>
      <div className="paragraph">
        Olly Logistics may, in its sole discretion, at any time and for any or
        no reason, suspend or terminate this Agreement with or without prior
        notice.
      </div>
      <div className="paragraph">
        This Agreement will terminate immediately, without prior notice from
        Olly Logistics , in the event that you fail to comply with any provision
        of this Agreement. You may also terminate this Agreement by deleting the
        app and all copies thereof from your computer.
      </div>
      <div className="paragraph">
        Upon termination of this Agreement, you shall cease all use of the app
        and delete all copies of the app from your computer.
      </div>
      <div className="paragraph">
        Termination of this Agreement will not limit any of Olly Logistics
        &#39;s rights or remedies at law or in equity in case of breach by you
        (during the term of this Agreement) of any of your obligations under the
        present Agreement.
      </div>

      <h1>Copyright Infringement Notice</h1>
      <div className="paragraph">
        If you are a copyright owner or such ownerâs agent and believe any
        material on our app constitutes an infringement on your copyright,
        please contact us setting forth the following information: (a) a
        physical or electronic signature of the copyright owner or a person
        authorized to act on his behalf; (b) identification of the material that
        is claimed to be infringing; (c) your contact information, including
        your address, telephone number, and an email; (d) a statement by you
        that you have a good faith belief that use of the material is not
        authorized by the copyright owners; and (e) the a statement that the
        information in the notification is accurate, and, under penalty of
        perjury you are authorized to act on behalf of the owner.
      </div>

      <h1>Indemnification</h1>
      <div className="paragraph">
        You agree to indemnify and hold Olly Logistics and its parents,
        subsidiaries, affiliates, officers, employees, agents, partners and
        licensors (if any) harmless from any claim or demand, including
        reasonable attorneys&#39; fees, due to or arising out of your: (a) use
        of the app; (b) violation of this Agreement or any law or regulation; or
        (c) violation of any right of a third party.
      </div>

      <h1>No Warranties</h1>
      <div className="paragraph">
        The app is provided to you &quot;AS IS&quot; and &quot;AS
        AVAILABLE&quot; and with all faults and defects without warranty of any
        kind. To the maximum extent permitted under applicable law, Olly
        Logistics , on its own behalf and on behalf of its affiliates and its
        and their respective licensors and service providers, expressly
        disclaims all warranties, whether express, implied, statutory or
        otherwise, with respect to the app, including all implied warranties of
        merchantability, fitness for a particular purpose, title and
        non-infringement, and warranties that may arise out of course of
        dealing, course of performance, usage or trade practice. Without
        limitation to the foregoing, Olly Logistics provides no warranty or
        undertaking, and makes no representation of any kind that the app will
        meet your requirements, achieve any intended results, be compatible or
        work with any other software, , systems or services, operate without
        interruption, meet any performance or reliability standards or be error
        free or that any errors or defects can or will be corrected.
      </div>
      <div className="paragraph">
        Without limiting the foregoing, neither Olly Logistics nor any Olly
        Logistics &#39;s provider makes any representation or warranty of any
        kind, express or implied: (i) as to the operation or availability of the
        app, or the information, content, and materials or products included
        thereon; (ii) that the app will be uninterrupted or error-free; (iii) as
        to the accuracy, reliability, or currency of any information or content
        provided through the app; or (iv) that the app, its servers, the
        content, or e-mails sent from or on behalf of Olly Logistics are free of
        viruses, scripts, trojan horses, worms, malware, timebombs or other
        harmful components.
      </div>
      <div className="paragraph">
        Some jurisdictions do not allow the exclusion of or limitations on
        implied warranties or the limitations on the applicable statutory rights
        of a consumer, so some or all of the above exclusions and limitations
        may not apply to you.
      </div>

      <h1>Limitation of Liability</h1>
      <div className="paragraph">
        Notwithstanding any damages that you might incur, the entire liability
        of Olly Logistics and any of its suppliers under any provision of this
        Agreement and your exclusive remedy for all of the foregoing shall be
        limited to the amount actually paid by you for the app.
      </div>
      <div className="paragraph">
        To the maximum extent permitted by applicable law, in no event shall
        Olly Logistics or its suppliers be liable for any special, incidental,
        indirect, or consequential damages whatsoever (including, but not
        limited to, damages for loss of profits, for loss of data or other
        information, for business interruption, for personal injury, for loss of
        privacy arising out of or in any way related to the use of or inability
        to use the app, third-party software and/or third-party hardware used
        with the app, or otherwise in connection with any provision of this
        Agreement), even if Olly Logistics or any supplier has been advised of
        the possibility of such damages and even if the remedy fails of its
        essential purpose.
      </div>
      <div className="paragraph">
        Some states/jurisdictions do not allow the exclusion or limitation of
        incidental or consequential damages, so the above limitation or
        exclusion may not apply to you.
      </div>

      <h1>Severability</h1>
      <div className="paragraph">
        If any provision of this Agreement is held to be unenforceable or
        invalid, such provision will be changed and interpreted to accomplish
        the objectives of such provision to the greatest extent possible under
        applicable law and the remaining provisions will continue in full force
        and effect.
      </div>
      <div className="paragraph">
        This Agreement, together with the Privacy Policy and any other legal
        notices published by Olly Logistics on the Services, shall constitute
        the entire agreement between you and Olly Logistics concerning the
        Services. If any provision of this Agreement is deemed invalid by a
        court of competent jurisdiction, the invalidity of such provision shall
        not affect the validity of the remaining provisions of this Agreement,
        which shall remain in full force and effect. No waiver of any term of
        this Agreement shall be deemed a further or continuing waiver of such
        term or any other term, and Olly Logistics &#39;s failure to assert any
        right or provision under this Agreement shall not constitute a waiver of
        such right or provision. YOU AND Olly Logistics AGREE THAT ANY CAUSE OF
        ACTION ARISING OUT OF OR RELATED TO THE SERVICES MUST COMMENCE WITHIN
        ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES. OTHERWISE, SUCH CAUSE OF
        ACTION IS PERMANENTLY BARRED.
      </div>

      <h1>Waiver</h1>
      <div className="paragraph">
        Except as provided herein, the failure to exercise a right or to require
        performance of an obligation under this Agreement shall not effect a
        party&#39;s ability to exercise such right or require such performance
        at any time thereafter nor shall be the waiver of a breach constitute
        waiver of any subsequent breach.
      </div>
      <div className="paragraph">
        o failure to exercise, and no delay in exercising, on the part of either
        party, any right or any power under this Agreement shall operate as a
        waiver of that right or power. Nor shall any single or partial exercise
        of any right or power under this Agreement preclude further exercise of
        that or any other right granted herein. In the event of a conflict
        between this Agreement and any applicable purchase or other terms, the
        terms of this Agreement shall govern.
      </div>

      <h1>Amendments to this Agreement</h1>
      <div className="paragraph">
        Olly Logistics reserves the right, at its sole discretion, to modify or
        replace this Agreement at any time. If a revision is material we will
        provide at least 30 days&#39; notice prior to any new terms taking
        effect. What constitutes a material change will be determined at our
        sole discretion.
      </div>
      <div className="paragraph">
        By continuing to access or use our app after any revisions become
        effective, you agree to be bound by the revised terms. If you do not
        agree to the new terms, you are no longer authorized to use Olly
        Logistics .
      </div>

      <h1>Entire Agreement</h1>
      <div className="paragraph">
        The Agreement constitutes the entire agreement between you and Olly
        Logistics regarding your use of the app and supersedes all prior and
        contemporaneous written or oral agreements between you and Olly
        Logistics .
      </div>
      <div className="paragraph">
        You may be subject to additional terms and conditions that apply when
        you use or purchase other Olly Logistics &#39;s services, which Olly
        Logistics will provide to you at the time of such use or purchase.
      </div>

      <h1>Updates to Our Terms</h1>
      <div className="paragraph">
        We may change our Service and policies, and we may need to make changes
        to these Terms so that they accurately reflect our Service and policies.
        Unless otherwise required by law, we will notify you (for example,
        through our Service) before we make changes to these Terms and give you
        an opportunity to review them before they go into effect. Then, if you
        continue to use the Service, you will be bound by the updated Terms. If
        you do not want to agree to these or any updated Terms, you can delete
        your account.
      </div>

      <h1>Intellectual Property</h1>
      <div className="paragraph">
        The app and its entire contents, features and functionality (including
        but not limited to all information, software, text, displays, images,
        video and audio, and the design, selection and arrangement thereof), are
        owned by Olly Logistics , its licensors or other providers of such
        material and are protected by IN and international copyright, trademark,
        patent, trade secret and other intellectual property or proprietary
        rights laws. The material may not be copied, modified, reproduced,
        downloaded or distributed in any way, in whole or in part, without the
        express prior written permission of Olly Logistics , unless and except
        as is expressly provided in these Terms &amp; Conditions. Any
        unauthorized use of the material is prohibited.
      </div>

      <h1>Agreement to Arbitrate</h1>
      <div className="paragraph">
        This section applies to any dispute EXCEPT IT DOESNâT INCLUDE A DISPUTE
        RELATING TO CLAIMS FOR INJUNCTIVE OR EQUITABLE RELIEF REGARDING THE
        ENFORCEMENT OR VALIDITY OF YOUR OR Olly Logistics &#39;s INTELLECTUAL
        PROPERTY RIGHTS. The term âdisputeâ means any dispute, action, or other
        controversy between you and Olly Logistics concerning the Services or
        this agreement, whether in contract, warranty, tort, statute,
        regulation, ordinance, or any other legal or equitable basis. âDisputeâ
        will be given the broadest possible meaning allowable under law.
      </div>

      <h1>Notice of Dispute</h1>
      <div className="paragraph">
        In the event of a dispute, you or Olly Logistics must give the other a
        Notice of Dispute, which is a written statement that sets forth the
        name, address, and contact information of the party giving it, the facts
        giving rise to the dispute, and the relief requested. You must send any
        Notice of Dispute via email to:Â 
        <a href="http:///cdn-cgi/l/email-protection" target="_blank">
          [email protected]
        </a>{" "}
        Olly Logistics will send any Notice of Dispute to you by mail to your
        address if we have it, or otherwise to your email address. You and Olly
        Logistics will attempt to resolve any dispute through informal
        negotiation within sixty (60) days from the date the Notice of Dispute
        is sent. After sixty (60) days, you or Olly Logistics may commence
        arbitration.
      </div>

      <h1>Binding Arbitration</h1>
      <div className="paragraph">
        If you and Olly Logistics donât resolve any dispute by informal
        negotiation, any other effort to resolve the dispute will be conducted
        exclusively by binding arbitration as described in this section. You are
        giving up the right to litigate (or participate in as a party or class
        member) all disputes in court before a judge or jury. The dispute shall
        be settled by binding arbitration in accordance with the commercial
        arbitration rules of the American Arbitration Association. Either party
        may seek any interim or preliminary injunctive relief from any court of
        competent jurisdiction, as necessary to protect the partyâs rights or
        property pending the completion of arbitration. Any and all legal,
        accounting, and other costs, fees, and expenses incurred by the
        prevailing party shall be borne by the non-prevailing party.
      </div>

      <h1>Submissions and Privacy</h1>
      <div className="paragraph">
        In the event that you submit or post any ideas, creative suggestions,
        designs, photographs, information, advertisements, data or proposals,
        including ideas for new or improved products, services, features,
        technologies or promotions, you expressly agree that such submissions
        will automatically be treated as non-confidential and non-proprietary
        and will become the sole property of Olly Logistics without any
        compensation or credit to you whatsoever. Olly Logistics and its
        affiliates shall have no obligations with respect to such submissions or
        posts and may use the ideas contained in such submissions or posts for
        any purposes in any medium in perpetuity, including, but not limited to,
        developing, manufacturing, and marketing products and services using
        such ideas.
      </div>

      <h1>Promotions</h1>
      <div className="paragraph">
        Olly Logistics may, from time to time, include contests, promotions,
        sweepstakes, or other activities (âPromotionsâ) that require you to
        submit material or information concerning yourself. Please note that all
        Promotions may be governed by separate rules that may contain certain
        eligibility requirements, such as restrictions as to age and geographic
        location. You are responsible to read all Promotions rules to determine
        whether or not you are eligible to participate. If you enter any
        Promotion, you agree to abide by and to comply with all Promotions
        Rules.
      </div>
      <div className="paragraph">
        Additional terms and conditions may apply to purchases of goods or
        services on or through the Services, which terms and conditions are made
        a part of this Agreement by this reference.
      </div>

      <h1>Typographical Errors</h1>
      <div className="paragraph">
        In the event a product and/or service is listed at an incorrect price or
        with incorrect information due to typographical error, we shall have the
        right to refuse or cancel any orders placed for the product and/or
        service listed at the incorrect price. We shall have the right to refuse
        or cancel any such order whether or not the order has been confirmed and
        your credit card charged. If your credit card has already been charged
        for the purchase and your order is canceled, we shall immediately issue
        a credit to your credit card account or other payment account in the
        amount of the charge.
      </div>

      <h1>Miscellaneous</h1>
      <div className="paragraph">
        If for any reason a court of competent jurisdiction finds any provision
        or portion of these Terms &amp; Conditions to be unenforceable, the
        remainder of these Terms &amp; Conditions will continue in full force
        and effect. Any waiver of any provision of these Terms &amp; Conditions
        will be effective only if in writing and signed by an authorized
        representative of Olly Logistics . Olly Logistics will be entitled to
        injunctive or other equitable relief (without the obligations of posting
        any bond or surety) in the event of any breach or anticipatory breach by
        you. Olly Logistics operates and controls the Olly Logistics Service
        from its offices in IN. The Service is not intended for distribution to
        or use by any person or entity in any jurisdiction or country where such
        distribution or use would be contrary to law or regulation. Accordingly,
        those persons who choose to access the Olly Logistics Service from other
        locations do so on their own initiative and are solely responsible for
        compliance with local laws, if and to the extent local laws are
        applicable. These Terms &amp; Conditions (which include and incorporate
        the Olly Logistics Privacy Policy) contains the entire understanding,
        and supersedes all prior understandings, between you and Olly Logistics
        concerning its subject matter, and cannot be changed or modified by you.
        The section headings used in this Agreement are for convenience only and
        will not be given any legal import.
      </div>

      <h1>Disclaimer</h1>
      <div className="paragraph">
        Olly Logistics is not responsible for any content, code or any other
        imprecision.
      </div>
      <div className="paragraph">
        {" "}
        Olly Logistics does not provide warranties or guarantees.
      </div>
      <div className="paragraph">
        In no event shall Olly Logistics be liable for any special, direct,
        indirect, consequential, or incidental damages or any damages
        whatsoever, whether in an action of contract, negligence or other tort,
        arising out of or in connection with the use of the Service or the
        contents of the Service. The Company reserves the right to make
        additions, deletions, or modifications to the contents on the Service at
        any time without prior notice.
      </div>
      <div className="paragraph">
        The Olly Logistics Service and its contents are provided &quot;as
        is&quot; and &quot;as available&quot; without any warranty or
        representations of any kind, whether express or implied. Olly Logistics
        is a distributor and not a publisher of the content supplied by third
        parties; as such, Olly Logistics exercises no editorial control over
        such content and makes no warranty or representation as to the accuracy,
        reliability or currency of any information, content, service or
        merchandise provided through or accessible via the Olly Logistics
        Service. Without limiting the foregoing, Olly Logistics specifically
        disclaims all warranties and representations in any content transmitted
        on or in connection with the Olly Logistics Service or on sites that may
        appear as links on the Olly Logistics Service, or in the products
        provided as a part of, or otherwise in connection with, the Olly
        Logistics Service, including without limitation any warranties of
        merchantability, fitness for a particular purpose or non-infringement of
        third party rights. No oral advice or written information given by Olly
        Logistics or any of its affiliates, employees, officers, directors,
        agents, or the like will create a warranty. Price and availability
        information is subject to change without notice. Without limiting the
        foregoing, Olly Logistics does not warrant that the Olly Logistics
        Service will be uninterrupted, uncorrupted, timely, or error-free.
      </div>

      <h1>Contact Us</h1>
      <div className="paragraph">
        {" "}
        Don&#39;t hesitate to contact us if you have any questions.
      </div>
      <ul>
        <li>
          Via Email:{" "}
          <a href="mailto: ollytrailerlogistics@gmail.com">
            ollytrailerlogistics@gmail.com
          </a>
        </li>
        <li>
          Via Phone Number:
          <a href="tel:+919562437375">{"+91 9562437375"}</a>
        </li>
        {/* <li>
          Via this Link:{" "}
          <a href="http://www.ollylogistics.com" target="_blank">
            www.ollylogistics.com
          </a>
        </li> */}
      </ul>
    </div>
  );
}
