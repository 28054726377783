import React from "react";
import "./Breadcrumb.css";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Breadcrumb({ text, route, islast = false }) {
  const history = useHistory();
  const { t } = useTranslation();
  // navigate to route from props
  const connectTO = () => {
    if (!islast) {
      history.push(route);
    }
  };
  return (
    <div className="breadcrumb">
      <span
        className={`text ${islast ? "bold" : "normal"}`}
        onClick={() => connectTO()}
      >
        {t(text)}
      </span>
      {!islast && <i className="fa fa-angle-right" />}
    </div>
  );
}
export default Breadcrumb;
