import React from "react";
import { Route, Switch } from "react-router-dom";
import Profile from "../pages/Profile";
import Transactions from "../pages/Transactions";
import Samplepage from "../pages/sample";
import PaymentDetails from "../pages/PaymentDetails";
import Notifications from "../pages/Notifications";
import History from "../pages/History";
import JourneyDetails from "../pages/JourneyDetails";
import Payment from "../pages/Payment";
import CancelBooking from "../pages/CancelBooking";
import PaymentSuccessful from "../pages/PaymentSuccessful";
import CancelledBooking from "../pages/CancelledBooking";
import ContactUs from "../pages/ContactUs";
import TrackTrailer from "../pages/TrackTrailer";
import Booking from "../pages/Booking";
import NoMatch from "../pages/NoMatch";
import ChangePassword from "../pages/ChangePassword";
import ChangeLanguage from "../pages/ChangeLanguage";
import PaymentFailed from "../pages/PaymentFailed";
import TermsAndConditions from "../PrivacyPolicy/TermsAndConditions";
import PrivacyPolicy from "../PrivacyPolicy/PrivacyPolicy";
import BookingSuccessful from "../pages/BookingSuccessful";

// routes for autherised user
const Routes = () => {
  return (
    <Switch>
      {/* <Route exact path="/sample" component={Samplepage} /> */}

      <Route
        exact
        path="/:type/payment-successful"
        component={PaymentSuccessful}
      />
      <Route exact path="/:type/payment-failed" component={PaymentFailed} />
      <Route exact path="/" component={Booking} />
      <Route exact path="/booking-successful/:id" component={BookingSuccessful} />
      <Route exact path="/booking/payment/:id" component={Payment} />

      <Route exact path="/history" component={History} />
      <Route
        exact
        path="/history/:id/journey-details"
        component={JourneyDetails}
      />
      <Route
        exact
        path="/history/:id/cancel-booking"
        component={CancelBooking}
      />
      <Route exact path="/history/:id/track-trailer" component={TrackTrailer} />
      <Route
        exact
        path="/history/cancelled-booking"
        component={CancelledBooking}
      />

      <Route exact path="/transactions" component={Transactions} />
      <Route
        exact
        path="/transactions/:id/:type/payment-details"
        component={PaymentDetails}
      />

      <Route exact path="/notifications" component={Notifications} />

      <Route exact path="/profile" component={Profile} />
      <Route exact path="/contact-us" component={ContactUs} />

      <Route exact path="/change-password" component={ChangePassword} />
      <Route exact path="/change-language" component={ChangeLanguage} />
      <Route
        exact
        path="/terms-and-conditions"
        component={TermsAndConditions}
      />
      <Route exact path="/privacy-policy" component={PrivacyPolicy} />
      <Route component={NoMatch} />
    </Switch>
  );
};
export default Routes;
