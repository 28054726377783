import React, { useEffect, useState, useRef, useContext } from "react";
import "./HeaderDropdown.css";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../../../App";
import { logout } from "../../../api";
import { useTranslation } from "react-i18next";
import { ComplexAlert } from "../../../utils/alertTemplate";
import firebase from "../../../firebase";
function HeaderDropdown() {
  const history = useHistory();
  const [open, setopen] = useState(false);
  const ref = useRef(null);

  const { t } = useTranslation();

  const { dispatch } = useContext(AuthContext);

  const signout = () => {
    // ComplexAlert(
    //   t("Are you sure you want to log out?"),
    //   "",
    //   t("Yes"),
    //   () => {
    logout();
    dispatch({
      type: "LOGOUT",
    });
    try {
      firebase.messaging().deleteToken();
    } catch (e) {
      console.log(e);
    }
    history.push("/");
    //   },
    //   t("No")
    // );
  };

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setopen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);
  
  // navigate to route from props
  const connectTO = (route) => {
    history.push(route);
    setopen(false);
  };

  return (
    <div className="header-dropdown" ref={ref}>
      <i className="fa fa-angle-down" onClick={() => setopen(!open)} />
      {open && (
        <div className="list">
          <div className="item" onClick={() => connectTO("/profile")}>
            {t("Profile")}
          </div>
          <div className="item" onClick={() => connectTO("/contact-us")}>
            {t("Contact Olly")}
          </div>
          <div className="item" onClick={() => signout()}>
            {t("Log out")}
          </div>
        </div>
      )}
    </div>
  );
}
export default HeaderDropdown;
