import React, { useEffect } from "react";
import bookingStatusColor from "../../utils/bookingStatusColor";
import "./Cards.css";
import { useTranslation } from "react-i18next";

function Cards({ date, id, from, to, status }) {
  const { t } = useTranslation();
  let color;
  let status_text;
  [color, status_text] = bookingStatusColor(status);

  return (
    <div className="cards">
      <div className="title" style={{ backgroundColor: `${color}12` }}>
        <div className={`status`} style={{ color: color }}>
          {t(status_text)}
        </div>
        <div className="id">{id}</div>
      </div>
      <div className="travel-info">
        <div className="date">
          {t("Booked on")} {date}
        </div>
        <div className="from-to">
          <div className="dots">
            <div className="ovel" />
            <div className="line" />
            <div className="ovel" />
          </div>
          <div className="locations">
            <div className="from">{from}</div>
            <div className="to">{to}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Cards;
