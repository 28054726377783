import React, { useEffect, useState, useRef } from "react";
import "./Transactions.css";
import Cards from "../../components/Cards";
import Header from "../../components/Header";
import { getData, postData } from "../../api";
import SearchBar from "../../components/SearchBar";
import Spinner from "../../components/Spinner";
import moment from "moment";
import formatDateTime from "../../utils/formatDateTime";
import { useHistory } from "react-router-dom";
import Breadcrumb from "../../components/Breadcrumb";
import NoResult from "../../components/NoResult";
import SquareButton from "../../components/SquareButton";
import { useTranslation } from "react-i18next";
import Images from "../../assets";
import { PDFDownloadLink } from "@react-pdf/renderer";
import ListPDF from "../../components/ListPDF";
import { SuccessAlert, FailureAlert } from "../../utils/alertTemplate";
import UseCounter from "../../hooks/useCounter";

function Transactions() {
  const history = useHistory();
  const ref = useRef(null);
  const { t } = useTranslation();
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filterList, setfilterList] = useState(transactions);
  const [isEmpty, setIsEmpty] = useState(false);
  const [dueAmount, setDueAmount] = useState(0);
  const [showPDFDownloader, setShowPDFDownloader] = useState(false);
  const [seconds] = UseCounter(10000);

  const searchHandler = (search) => {
    setShowPDFDownloader(false);
    setfilterList(
      transactions.filter((item) => {
        return (
          item.from.toLowerCase().includes(search.toLowerCase().trim()) ||
          item.to.toLowerCase().includes(search.toLowerCase().trim()) ||
          item.bookingId.toLowerCase().includes(search.toLowerCase().trim())
        );
      })
    );
  };

  // to get transaction data when page loads and alse for every 10 seconds
  useEffect(() => {
    getTransactionData();
  }, [seconds]);

  // function that makes api call to get payment details and due details
  const getTransactionData = async () => {
    let response = await postData(`/payments/records`);
    const dues = await postData(`/dues`);
    setLoading(false);

    if (response && response.data) {
      console.log(response.data);
      const dataArray = response.data.map((transaction) => {
        if (transaction.type === "booking") {
          return {
            id: transaction.paymentDetails.bookingRequest.id
              ? transaction.paymentDetails.bookingRequest.id
              : "",
            date: transaction.paymentDetails.updatedAt
              ? transaction.paymentDetails.updatedAt
              : "-",
            amount: transaction.paymentDetails.amount
              ? t("SAR") + " " + transaction.paymentDetails.amount.toFixed(2)
              : "-",
            from: transaction.paymentDetails.bookingRequest.pickup_location_name
              ? transaction.paymentDetails.bookingRequest.pickup_location_name
              : "-",
            to: transaction.paymentDetails.bookingRequest.drop_location_name
              ? transaction.paymentDetails.bookingRequest.drop_location_name
              : "-",
            status: transaction.paymentDetails.status
              ? transaction.paymentDetails.status
              : "-",
            type: transaction.type ? transaction.type : "-",
            bookingId: transaction.paymentDetails.bookingRequest.bookingId
              ? transaction.paymentDetails.bookingRequest.bookingId
              : "-",
          };
        } else if (transaction.type === "credit") {
          return {
            id: transaction.booking_request ? transaction.booking_request : "-",
            date: transaction.booking.booking_date
              ? transaction.booking.booking_date
              : "-",
            amount: transaction.amount
              ? t("SAR") + " " + transaction.amount.toFixed(2)
              : "-",
            from: transaction.booking.pickup_location_name
              ? transaction.booking.pickup_location_name
              : "-",
            to: transaction.booking.drop_location_name
              ? transaction.booking.drop_location_name
              : "-",
            status: transaction.cleared
              ? "payment_complete"
              : "pending_payment",
            type: transaction.type ? transaction.type : "-",
            bookingId: transaction.booking.bookingId
              ? transaction.booking.bookingId
              : "-",
          };
        }
      });
      setTransactions(dataArray);
      setfilterList(dataArray);
    }

    if (dues && dues.data) {
      let amount = 0;
      dues.data.map((due) => {
        amount = amount + due.amount;
      });
      setDueAmount(amount.toFixed(2));
    }
  };

  useEffect(() => {
    if (filterList.length !== 0) {
      console.log("inside if");
      setIsEmpty(false);
    } else {
      setIsEmpty(true);
    }
  }, [filterList]);

  const onClick = async () => {
    setLoading(true);

    const paymentResponse = await getData(
      `/dues/settle?redirect_url=${window.location.origin}/transactions/payment-successful&failure_redirect_url=${window.location.origin}/transactions/payment-failed`
    );

    if (
      paymentResponse &&
      paymentResponse.data &&
      paymentResponse.data.payment_url
    ) {
      const paymentURL = paymentResponse.data.payment_url;
      window.location.assign(paymentURL);
    } else if (
      paymentResponse &&
      paymentResponse.status &&
      paymentResponse.status === 400
    ) {
      setLoading(false);
      FailureAlert(
        t("Failed"),
        `${paymentResponse.message?.error ?? t("Something went wrong")}`
      );
    } else {
      setLoading(false);
      FailureAlert(t("Action failed"));
    }
  };
  
  return (
    <div className="transactions" ref={ref}>
      {loading && <Spinner />}
      <Breadcrumb
        text="Transactions"
        islast={true}
        style={{ display: "none" }}
      />
      {transactions.length === 0 ? (
        <div className="no-bookings">No transactions</div>
      ) : (
        <>
          <div className="header-container">
            {dueAmount != 0 ? (
              <SquareButton
                isdisabled={true}
                text={t("Settle due") + ` ${"SAR"} ${dueAmount}`}
                onClickBtn={onClick}
              />
            ) : (
              <div></div>
            )}
            {!showPDFDownloader && (
              <div className="pdf-download">
                <button
                  className="download-btn"
                  onClick={() => {
                    setShowPDFDownloader(true);
                  }}
                >
                  <img src={Images.pdf} className="pdf-img" />
                  <span className="pdf-text">{t("Prepare PDF")}</span>
                </button>
              </div>
            )}

            {showPDFDownloader && (
              <PDFDownloadLink
                document={
                  <ListPDF heading="Transaction details" data={filterList} />
                }
                fileName={`OllyTransactions_${moment().unix()}.pdf`}
              >
                {({ blob, url, loading, error }) => (
                  <div className="pdf-download">
                    <button
                      className="download-btn"
                      onClick={() => {
                        setShowPDFDownloader(true);
                      }}
                      style={{ cursor: "auto" }}
                    >
                      <img src={Images.pdf} className="pdf-img" />
                      {loading ? (
                        <span className="pdf-text" style={{ cursor: "auto" }}>
                          {t("Processing") + " "}
                          <i
                            class="fa fa-spinner  fa-pulse"
                            aria-hidden="true"
                          />
                        </span>
                      ) : (
                        <span
                          className="pdf-text"
                          style={{ cursor: "pointer" }}
                        >
                          {t("Download PDF")}
                        </span>
                      )}
                    </button>
                  </div>
                )}
              </PDFDownloadLink>
            )}
          </div>
          <SearchBar
            placeholder={"Search booking ID or places"}
            searchHandler={searchHandler}
          />
          <div className="list">
            {filterList.map((item, i) => (
              <div
                className="card"
                onClick={() => {
                  history.push(
                    `/transactions/${item.id}/${
                      item.type === "booking" ? "payment" : item.type
                    }/payment-details`
                  );
                }}
              >
                <Cards
                  date={formatDateTime(item.date, "ddd DD MMM YYYY")}
                  id={item.amount}
                  from={item.from}
                  to={item.to}
                  status={item.status}
                />
              </div>
            ))}
          </div>
          <div className="no-results">{isEmpty && <NoResult />}</div>
        </>
      )}
    </div>
  );
}
export default Transactions;
