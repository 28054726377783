import React, { useState, useEffect } from "react";
import Button from "../../components/SquareButton";
import { useTranslation } from "react-i18next";
import Breadcrumb from "../../components/Breadcrumb";
import "./ChangeLanguage.css";
import { useHistory } from "react-router-dom";
import rtlLanguages from "../../utils/rtlLanguages";

export default function Sample() {
  const history = useHistory();

  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState();

  // executes before page render                                                 
  // checks a language is set locally if not, select english
  useEffect(() => {
    setLanguage(
      localStorage.getItem("Olly_lang")
        ? localStorage.getItem("Olly_lang")
        : "en"
    );
  }, []);

  // execute on click save button, save selected language
  const ln = async () => {
    await i18n.changeLanguage(language);
    localStorage.setItem("Olly_lang", language);
    window.location.assign("/profile");
    if (rtlLanguages.languages.includes(language)) {
      document.body.classList.add("rtl");
    } else {
      document.body.classList.remove("rtl");
    }
    // history.push("/profile");
    
  };

  return (
    <div className="change-language">
      <Breadcrumb text="Change language" islast={true} />
      <div className="language-list">
        <div
          className="language"
          onClick={() => {
            setLanguage("en");
          }}
        >
          <input
            type="radio"
            id="english"
            name="language"
            value="english"
            // defaultChecked={true}
            checked={language === "en" ? true : false}
          />
          <label htmlFor="english">{t("English")}</label>
        </div>
        <div
          className="language"
          onClick={() => {
            setLanguage("ar");
          }}
        >
          <input
            type="radio"
            id="arabic"
            name="language"
            value="arabic"
            checked={language === "ar" ? true : false}
          />
          <label htmlFor="arabic">{t("Arabic")}</label>
        </div>
        {/* <div
          className="language"
          onClick={() => {
            setLanguage("ur");
          }}
        >
          <input
            type="radio"
            id="urdu"
            name="language"
            value="urdu"
            checked={language === "ur" ? true : false}
          />
          <label htmlFor="urdu">{t("Urdu")}</label>
        </div> */}
        <Button text="Save" onClickBtn={ln} />
      </div>
    </div>
  );
}
