import React from "react";
import "./LandingSectionNoAuth.css";
import NoAuthRoutes from "../../routes/NoAuthRoutes";
import Footer from "../../components/Footer";

// loads when the user is not authenicated
// this component is direct the page to  no authentication route
export default function LandingSectionNoAuth() {
  return (
    <div className="landing-section-no-auth">
      <div className="no-auth-main">
        <NoAuthRoutes />
        {/* dsjflksjfslf
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        flksjfsdklfj */}
      </div>
      <Footer />
    </div>
  );
}
