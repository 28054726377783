import React from "react";
import "./PaymentFailed.css";
import ActionStatus from "../../components/ActionStatus";
import Images from "../../assets";
import { useLocation } from "react-router-dom";

// this component is renderded if payment made is failed
const PaymentFailed = (props) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  let failed_status = params.get("status");
  let type = props.match.params.type;
  console.log(type, "type");
  return (
    <div className="payment-failed">
      <ActionStatus
        heading="Payment Failed"
        image={Images.cross}
        text={
          <div style={{ fontFamily: "Roboto" }}>
            <p>{failed_status}</p>
            <p style={{ color: "#F95138" }}>
              Any amount deducted will be refunded back to your account.
            </p>
          </div>
        }
      />
    </div>
  );
};

export default PaymentFailed;
