import React from "react";
import formatDateTime from "../../utils/formatDateTime";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";
import Images from "../../assets";
import moment from "moment";
import bookingStatusColor from "../../utils/bookingStatusColor";
import Roboto from "../../assets/Fonts/Roboto-Regular.ttf";

const ListPDF = ({ heading, data }) => {
  let color;
  let status_text;
  moment.locale("en");
  Font.register({ family: "Roboto", src: Roboto });
  const styles = StyleSheet.create({
    page: {
      backgroundColor: "#f9f9fb",
      fontFamily: "Roboto",
      fontSize: 13,
      fontWeight: 400,
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1,
    },
    imageView: {
      paddingTop: 50,
      paddingBottom: 20,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
    },
    header: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      margin: 10,
      fontSize: 18,
    },
    heading: {
      margin: 10,
      fontWeight: 600,
      fontSize: 18,
    },
    time: { fontSize: 12 },
    logo: {
      height: 50,
      padding: 5,
    },
    cards: {
      width: 480,
      height: 150,
      borderRadius: 6,
      display: "flex",
      flexDirection: "column",
      padding: 20,
      margin: 2,
    },
    travelInfo: {
      backgroundColor: "#ffffff",
      borderBottomLeftRadius: 6,
      borderBottomRightRadius: 6,
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      padding: "10px 20px",
      backgroundColor: "#ffffff",
    },
    date: {
      fontSize: 10,
      paddingBottom: "10px",
    },
    from: {
      fontSize: 14,
      paddingBottom: "5px",
      whiteSpace: "nowrap",
      overflow: "hidden",
      width: "400px",
      textOverflow: "ellipsis",
    },
    to: {
      fontSize: 14,
      paddingTop: "5px",
      whiteSpace: "nowrap",
      overflow: "hidden",
      width: "400px",
      textOverflow: "ellipsis",
    },
    fromTo: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    dots: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    ovel: {
      height: 5,
      width: 5,
      borderRadius: 50,
      backgroundColor: "#f95138",
    },
    line: {
      height: 25,
      width: 1,
      backgroundColor: "#efcac6",
    },
    locations: {
      fontSize: 16,
      padding: "0px 8px",
    },
  });
  return (
    <Document>
      <Page style={styles.page}>
        <View style={styles.imageView}>
          {/* <Image source={Images.olly_logo} style={styles.logo} /> */}
          <Image source={Images.olly_eng} style={styles.logo} />
        </View>
        <View style={styles.header}>
          <Text style={styles.heading}>{heading}</Text>
          <Text style={styles.time}>
            {moment().format("DD MMM YYYY")} at {moment().format("hh:mm A")}
          </Text>
        </View>
        <View style={styles.header}>
          {data.map((item) => {
            [color, status_text] = bookingStatusColor(item.status);
            return (
              <View style={styles.cards} wrap={false}>
                <View
                  style={{
                    fontSize: 14,
                    backgroundColor: color,
                    opacity: 0.2,
                    display: "flex",
                    flexDirection: "row",
                    borderTopLeftRadius: 6,
                    borderTopRightRadius: 6,
                    justifyContent: "space-between",
                    padding: "10px 20px",
                    boxShadow: "",
                  }}
                >
                  <Text style={{ color: color, opacity: 1.0 }}>
                    {status_text}
                  </Text>
                  <Text style={{ opacity: 1.0, fontSize: 12 }}>
                    {item.amount}
                  </Text>
                </View>
                <View style={styles.travelInfo}>
                  <Text style={styles.date}>
                    {moment(item.date).format("MMMM DD, YYYY")}
                  </Text>
                  <View style={styles.fromTo}>
                    <View style={styles.dots}>
                      <View style={styles.ovel} />
                      <View style={styles.line} />
                      <View style={styles.ovel} />
                    </View>
                    <View style={styles.locations}>
                      <Text style={styles.from}>
                        {item.from.length < 40
                          ? item.from
                          : item.from.substring(0, 40) + "..."}
                      </Text>
                      <Text style={styles.to}>
                        {item.to.lemgth < 40
                          ? item.to
                          : item.to.substring(0, 40) + "..."}
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
            );
          })}
        </View>
      </Page>
    </Document>
  );
};

export default ListPDF;
