import React, { useState, useEffect } from "react";
import "./Notifications.css";
import Images from "../../assets";
import NotificationCard from "../../components/NotificationCard";
import ActionStatus from "../../components/ActionStatus";
import { getData } from "../../api";
import Spinner from "../../components/Spinner";
import Breadcrumb from "../../components/Breadcrumb";
import { useTranslation } from "react-i18next";

const Notifications = () => {
  const { t } = useTranslation();
  const [notifications, setnotifications] = useState([]);
  const [loading, setloading] = useState(false);

  // executes before page render
 // call the function which make api call to get all notifications
  useEffect(() => {
    const getallNotification = async () => {
      setloading(true);
      let response = await getData(`/notification/history/100`);
      setloading(false);
      if (response && response.data) {
        console.log(response);
        if (response.data.length > 0) {
          localStorage.setItem(
            "notification_timeStamp",
            response.data[0].createdAt
          );
        }
        setnotifications(response.data);
      }
    };
    getallNotification();
  }, []);
  
  return (
    <div className="notifications">
      {loading && <Spinner />}
      <Breadcrumb text="Notifications" islast={true} />
      {notifications.length === 0 && (
        <div className="empty-notifications">{t("No notifications")}</div>
      )}

      {notifications.length > 0 && (
        <div className="list">
          {notifications.map((item, i) => (
            <div className="card" key={i}>
              <NotificationCard
                date={item.createdAt}
                id={item.id}
                text={item.payload.notification.body}
                title={item.payload.notification.title}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Notifications;
