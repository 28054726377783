import instance from "./instance";
import { confirmAlert } from "react-confirm-alert";
import "../constants/ConfirmAlert.css";
import { SimpleAlert } from "../utils/alertTemplate";
import languageSelector from "../utils/LanguagesForNetworkError";

/*
    This file is to handle api requests if user is not authenticated
*/ 

// funtion which set header for api requests
const REQUEST_HEADER = (lang, idToken = null) => {
  return {
    headers: {
      "accept-language": lang, // "ar","en"
        "id-token": idToken,
    },
  };
};

// const getAccessToken = () => {
//   return localStorage.getItem("access_token");
// };

// get access token from local storage
const getLanguage = () => {
  return localStorage.getItem("Olly_lang")
    ? localStorage.getItem("Olly_lang")
    : "en";
};

// function to handle errors, handle various http response status
const handleError = (error) => {
  if (error && error.response && error.response.status) {
    console.error(error);

    switch (error.response.status) {
      case 400:
        let err = {
          status: error.response.status ?? "",
          message: error.response.data ?? {},
        };
        return err;
      case 401:
        alerter(languageSelector("Unauthorized"), error.response.data.error);
        return null;
      case 403:
        let err_msg = {
          status: error.response.status ?? "",
          message: error.response.data ?? {},
        };
        return err_msg;
      case 404:
        alerter(
          languageSelector("Not Found"),
          languageSelector("The resource was not found.")
        );
        return null;

      case 500:
        let errr = {
          status: error.response.status ?? "",
          message: error.response.data ?? {},
        };
        return errr;

      case 502:
        alerter(
          languageSelector("Server Error"),
          languageSelector("The server couldn't complete the request.")
        );
        return null;

      default:
        return null;
    }
  } else {
    alerter(
      languageSelector("Network Error"),
      languageSelector("Couldn't reach the server or no response received.")
    );
  }
  return null;
};

// clear access tocken and phone number from local storage
const logout = () => {
  localStorage.removeItem("access_token");
  localStorage.removeItem("login_phone");
  localStorage.removeItem("login_email");
};

// Alert popup box for API related errors
const alerter = (
  title = "Message",
  message = "Hi, I am the default alerter.",
  refresh = false
) => {
  // confirmAlert({
  //   title: languageSelector(title),
  //   message: languageSelector(message),
  //   buttons: [
  //     {
  //       label: "Close",
  //       onClick: () => {
  //         if (refresh) window.location.reload();
  //       },
  //     },
  //   ],
  // });
  SimpleAlert(title, message, () => {
    if (refresh) window.location.reload();
  });
};

/*
--------------------------------------------------------------
*/

// GET method API function
const getData = async (path) => {
  let header = REQUEST_HEADER(getLanguage());
  let response = await instance.get(path, header).catch(handleError);
  if (response && response.data && response.status && response.status === 200) {
    return response.data;
  } else if (response && response.status && response.status === 400) {
    return response;
  } else if (response && response.status && response.status === 500) {
    return response;
  } else {
    return null;
  }
};

// PUT method API function
const putData = async (path, data) => {
  let header = REQUEST_HEADER(getLanguage());
  let response = await instance.put(path, data, header).catch(handleError);
  if (response && response.data && response.status === 200) {
    return response.data;
  } else if (response && response.status && response.status === 400) {
    return response;
  } else if (response && response.status && response.status === 500) {
    return response;
  } else {
    return null;
  }
};

// POST method API function
const postData = async (path, data, idToken = null) => {
  let header = REQUEST_HEADER(getLanguage(), idToken);
  let response = await instance.post(path, data, header).catch(handleError);

  if (response && response.data && response.status === 200) {
    return response.data;
  } else if (response && response.status && response.status === 400) {
    return response;
  } else if (response && response.status && response.status === 403) {
    return response;
  } else if (response && response.status && response.status === 500) {
    return response;
  } else {
    return null;
  }
};

// DELETE method API function
const deleteData = async (path, data) => {
  let header = REQUEST_HEADER(getLanguage());
  let response = await instance
    .delete(path, header, { data: data })
    .catch(handleError);
  if (response && response.data && response.status === 200) {
    return response.data;
  } else if (response && response.status && response.status === 400) {
    return response;
  } else if (response && response.status && response.status === 500) {
    return response;
  } else {
    return null;
  }
};

export {
  getData,
  putData,
  postData,
  deleteData,
  logout,
  // getAccessToken,
  //   getIDToken,
  //   getRefreshToken,
  //   checkTokenExpiry,
};
