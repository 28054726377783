import React, { useEffect, useState } from "react";
import "./JourneyDetails.css";
import Breadcrumb from "../../components/Breadcrumb";
import SquareButton from "../../components/SquareButton";
import { useHistory } from "react-router-dom";
import { getData, postData } from "../../api";
import Spinner from "../../components/Spinner";
import bookingStatusColor from "../../utils/bookingStatusColor";
import formatDateTime from "../../utils/formatDateTime";
import { useTranslation } from "react-i18next";
import Images from "../../assets";
import { PDFDownloadLink } from "@react-pdf/renderer";
import DetailsPDF from "../../components/DetailsPDF";
import moment from "moment";
import UseCounter from "../../hooks/useCounter";
import {
  SuccessAlert,
  FailureAlert,
  PaymentFailedAlert,
} from "../../utils/alertTemplate";

export default function JourneyDetails(props) {
  const ID = props.match.params.id;
  const history = useHistory();
  moment.locale("en");

  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);
  const [bookingDetails, setbookingDetails] = useState({});
  const [showPDFDownloader, setShowPDFDownloader] = useState(false);
  const [seconds] = UseCounter(10000);
  const [toggle, settoggle] = useState(true);
  const [creditDetails, setcreditDetails] = useState({});
  const [disableButton, setDisableButton] = useState(false);

  let color, status_text;
  let status = bookingDetails.status ?? "";
  let paymentURL = "";

  [color, status_text] = bookingStatusColor(status);

  // to get booking details before page render and update booking date in every 10 seconds 
  useEffect(() => {
    getBooking();
  }, [seconds]);

  useEffect(() => {
    setShowPDFDownloader(false);
  }, []);

  // call the function which makes api call to get a booking's details, then it store to bookingDetails state
  const getBooking = async () => {
    let response = await getData(`/bookings/${ID}`);
    setLoading(false);
    if (response && response.data) {
      console.log(response.data);
      console.log(response);
      setbookingDetails(response.data);
    }
  };

  // get credit details when page loads for first time
  useEffect(() => {
    // call the function which makes api call to get a credit details, then it store to creditDetails state
    const getCreditDetails = async () => {
      setLoading(true);
      const response = await getData(`/credit/status`);
      setLoading(false);

      if (response && response.data) {
        console.log(response);
        setcreditDetails({
          total_amount: response.data.max,
          current: response.data.current,
          active: response.data.active,
        });
      }
    };
    getCreditDetails();
  }, []);

  // useEffect(() => {
  //   document.addEventListener("DOMContentLoaded", function () {
  //     var checkbox = document.querySelector('input[type="checkbox"]');

  //     checkbox.addEventListener("change", function () {
  //       if (checkbox.checked) {
  //         // do this
  //         console.log("Checked");
  //       } else {
  //         // do that
  //         console.log("Not checked");
  //       }
  //     });
  //   });
  // }, []);

  // call the function which makes api call to make payment
  const makePaymentBtn = async () => {
    setDisableButton(true);
    setLoading(true);
    // redirect to success or failure page according to api response
    const paymentResponse = await postData(
      `/bookings/payment/get_payment_page/${ID}?redirect_url=${window.location.origin}/booking/payment-successful&failure_redirect_url=${window.location.origin}/booking/payment-failed`
    );

    if (
      paymentResponse &&
      paymentResponse.data &&
      paymentResponse.data.payment_url
    ) {
      console.log(paymentResponse);
      paymentURL = paymentResponse.data.payment_url;
      window.location.assign(paymentURL);
    } else if (
      paymentResponse &&
      paymentResponse.status &&
      paymentResponse.status === 400
    ) {
      setLoading(false);
      PaymentFailedAlert(
        t("Failed"),
        `${paymentResponse.message?.error ?? t("Something went wrong")}`
      );
    } else {
      setLoading(false);
      FailureAlert(t("Failed"));
    }
  };

  // call the function which makes api call to make payment with credit
  const makeCreditBtn = async () => {
    setDisableButton(true);
    setLoading(true);
    let creditResponse = await postData(
      `/bookings/payment/pay_with_credit/${ID}`
    );
    setLoading(false);
    if (creditResponse && creditResponse.status === 200) {
      console.log(creditResponse);
      // SuccessAlert("Cancellation Success", () =>
      history.push("/booking/payment-successful");
      // );
    } else if (
      creditResponse &&
      creditResponse.status &&
      creditResponse.status === 400
    ) {
      FailureAlert(
        t("Failed"),
        `${creditResponse.message?.error ?? t("Something went wrong")}`
      );
    } else {
      FailureAlert(t("Action failed"));
    }

    setLoading(false);

    setDisableButton(false);
    setLoading(false);
  };

  console.log(showPDFDownloader, "showpdfdownloader");

  // direct to cancel booking confirmation page
  const cancelBooking = () => {
    history.push(`/history/${ID}/cancel-booking`);
  };

  // direct to track trailer page
  const trackTrailer = () => {
    history.push(`/history/${ID}/track-trailer`);
  };

  console.log(bookingDetails, "bookingdetails");
  console.log(status_text, "statustext");
  return (
    <div className="journey-details">
      {loading && <Spinner />}
      <div className="breadcrumb-title">
        <Breadcrumb text="History" route="/history" />
        <Breadcrumb text="Journey details" islast={true} />
      </div>
      <div className="top-section">
        <div className="payment">
          <div
            className="status"
            style={{ backgroundColor: `${color}26`, color: `${color}` }}
          >
            {t(status_text)}
          </div>
          <div
            className="amount"
            style={
              status === "in_transit" || status === "picked_up"
                ? {}
                : { display: "none" }
            }
            onClick={() => {
              trackTrailer();
            }}
          >
            <span className="track-trailer-title">{t("Track trailer")}</span>
            <i className="fa fa-angle-right" />
          </div>
        </div>
        {!showPDFDownloader && (
          <div className="pdf-download">
            <button
              className="download-btn"
              onClick={() => {
                setShowPDFDownloader(true);
              }}
            >
              <img src={Images.pdf} className="pdf-img" />
              <span className="pdf-text">{t("Prepare PDF")}</span>
            </button>
          </div>
        )}

        {showPDFDownloader && (
          <PDFDownloadLink
            document={
              <DetailsPDF
                status={status_text}
                color={color}
                id={bookingDetails.bookingId}
                trailer={
                  bookingDetails.journey?.trailer
                    ? bookingDetails.journey.trailer.reg_no
                      ? bookingDetails.journey.trailer.reg_no
                      : "Not available"
                    : "Not available"
                }
                date={moment(bookingDetails.pickup_datetime).format(
                  "DD MMM YYYY, hh:mm a"
                )}
                pickup={bookingDetails.pickup_location_name}
                pickupContact={bookingDetails.pickup_contact}
                dropOff={bookingDetails.drop_location_name}
                dropContact={bookingDetails.drop_contact}
                amount={bookingDetails.total_cost.toFixed(2)}
                paidOn={
                  bookingDetails.paymentDetails?.updatedAt
                    ? moment(bookingDetails.paymentDetails.updatedAt).format(
                        "DD MMM YYYY, hh:mm a"
                      )
                    : bookingDetails.dueSettlementRecord?.updatedAt
                    ? moment(
                        bookingDetails.dueSettlementRecord.updatedAt
                      ).format("DD MMM YYYY, hh:mm a")
                    : "Not paid"
                }
              />
            }
            fileName={`OllyTripDetails_${moment().unix()}.pdf`}
          >
            {({ blob, url, loading, error }) => (
              <div className="pdf-download">
                <button
                  className="download-btn"
                  onClick={() => {
                    setShowPDFDownloader(true);
                  }}
                  style={{ cursor: "auto" }}
                >
                  <img src={Images.pdf} className="pdf-img" />
                  {loading ? (
                    <span className="pdf-text" style={{ cursor: "auto" }}>
                      {t("Processing") + " "}
                      <i class="fa fa-spinner  fa-pulse" aria-hidden="true" />
                    </span>
                  ) : (
                    <span className="pdf-text" style={{ cursor: "pointer" }}>
                      {t("Download PDF")}
                    </span>
                  )}
                </button>
              </div>
            )}
          </PDFDownloadLink>
        )}
        {/* <img src={Images.pdfDownloadBtn} className="pdf-btn" /> */}
      </div>
      <div className="booking-details">
        <div className="title-info">
          <div className="id">
            <span className="id-title">{`${t("Booking ID")}`}</span>
            <span className="line">{"-"}</span>
            <span className="id-text">{bookingDetails.bookingId}</span>
          </div>
          {bookingDetails.journey?.trailer.reg_no &&
            <div className="date-info">
              <span className="date-title">{t("Trailer number")} </span>
              <span className="date">
                {/* {bookingDetails.journey?.trailer
                  ? bookingDetails.journey.trailer.reg_no
                    ? bookingDetails.journey.trailer.reg_no
                    : t("Not available")
                  : t("Not available")} */}
                  {bookingDetails.journey.trailer.reg_no}
              </span>
            </div>
          }
        </div>
      </div>

      {/* <div className="details-container"> */}
      <div className="booking-details">
        <div className="booking-info">
          <div className="title">
            <span className="sub-title">{t("Pickup location")}</span>
            <span className="text">{bookingDetails.pickup_location_name}</span>
          </div>
          <div className="title">
            <span className="sub-title">{t("Drop-off location")}</span>
            <span className="text">{bookingDetails.drop_location_name}</span>
          </div>
          <div className="title">
            <span className="sub-title">{t("Pickup date and time")}</span>
            <span className="text">
              {formatDateTime(
                bookingDetails.pickup_datetime,
                "ddd, DD MMM YYYY, hh:mm a"
              ) ?? "Wed, 23 Sep 2020, 10:00 pm"}
            </span>
          </div>
          <div className="title">
            <span className="sub-title">{t("Pickup contact")}</span>
            <span className="text contact">
              {`+${bookingDetails.pickup_contact}`}{" "}
            </span>
          </div>
          <div className="title">
            <span className="sub-title">{t("Drop-off contact")}</span>
            <span className="text contact">{`+${bookingDetails.drop_contact}`}</span>
          </div>
          <div className="title">
            <span className="sub-title">{t("Payment details")}</span>
            <span className="text">
              {/* {t("SAR") +
                " " +
                bookingDetails.total_cost +
                ", " +
                (bookingDetails.paymentDetails?.updatedAt
                  ? " on " +
                    formatDateTime(
                      bookingDetails.paymentDetails.updatedAt,
                      "DD MMM YYYY,  h:mm a"
                    )
                  : bookingDetails.dueSettlementRecord?.updatedAt
                  ? " on " +
                    formatDateTime(
                      bookingDetails.dueSettlementRecord.updatedAt,
                      "DD MMM YYYY, hh:mm a"
                    )
                  : t("Not paid"))} */}
              { bookingDetails.paymentDetails?.updatedAt ? 
                `${t("SAR")} ${bookingDetails.total_cost}, ${formatDateTime(
                  bookingDetails.paymentDetails.updatedAt,
                  "DD MMM YYYY,  h:mm a"
                )}` : bookingDetails.dueSettlementRecord?.updatedAt ?
                `${t("SAR")} ${bookingDetails.total_cost}, ${formatDateTime(
                  bookingDetails.dueSettlementRecord?.updatedAt,
                  "DD MMM YYYY,  h:mm a"
                )}` : t("Pending") 
              }
            </span>
          </div>
        </div>
      </div>
      {creditDetails.active && bookingDetails.awaiting_payment && (
        <div className="credit">
          <div className="text">
            <div className="credit-title">{t("Pay with Olly credits")}</div>
            <div className="toggle-btn">
              <label className="switch">
                <input
                  type="checkbox"
                  className="checkbox"
                  checked={toggle}
                  disabled={true}
                  onClick={(e) => {
                    settoggle(!toggle);
                  }}
                />
                <span className="slider round"></span>
              </label>
            </div>
          </div>
          <div className="balance">
            {t("Balance")} : {t("SAR")}{" "}
            {(creditDetails.total_amount - creditDetails.current).toFixed(2)}
          </div>
        </div>
      )}
      {bookingDetails.awaiting_payment && (
        <div className="amount-info">
          <div className="text">{t("Amount payable")}</div>
          <div className="amount">
            {t("SAR") + " "}
            {bookingDetails.total_cost}
          </div>
        </div>
      )}
      {bookingDetails.awaiting_payment && (
        <SquareButton
          isdisabled={disableButton}
          text={"Make payment"}
          onClickBtn={() => (toggle ? makeCreditBtn() : makePaymentBtn())}
        />
      )}
      {!(bookingDetails.picked_up || bookingDetails.cancelled) && (
        <SquareButton
          text={"Cancel booking"}
          onClickBtn={cancelBooking}
          type={"secondary-btn"}
          isdisabled={!bookingDetails.is_cancellable}
        />
      )}
      {/* </div> */}
    </div>
  );
}
