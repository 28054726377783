import React, { useState, useEffect } from "react";
import "./CancelBooking.css";
import { useHistory } from "react-router-dom";
import Breadcrumb from "../../components/Breadcrumb";
import SquareButton from "../../components/SquareButton";
import { getData } from "../../api";
import Spinner from "../../components/Spinner";
import { SuccessAlert, FailureAlert } from "../../utils/alertTemplate";
import { useTranslation } from "react-i18next";
import formatDateTime from "../../utils/formatDateTime";

//cancel booking confirmation page componet
export default function CancelBooking(props) {
  let ID = props.match.params.id;

  const { t } = useTranslation();

  const [loading, setloading] = useState(false);
  const [bookingDetails, setbookingDetails] = useState({});

  const [cancelEstimate, setcancelEstimate] = useState({
    refund_amount: 0,
    cancellation_amount: 0,
    penalty_amount: 0,
  });

  const history = useHistory();

  // to get booking details before page render
  useEffect(() => {
    const getBooking = async () => {
      setloading(true);
      let response = await getData(`/bookings/${ID}`);
      setloading(false);
      if (response && response.data) {
        console.log(response);
        setbookingDetails(response.data);
      }
    };
    getBooking();
  }, []);

  // to get refund amound details before page render
  useEffect(() => {
    const getRefundData = async () => {
      setloading(true);
      let response = await getData(`/bookings/cancellation/estimate/${ID}`);
      setloading(false);
      if (response && response.data) {
        console.log(response);
        setcancelEstimate({
          refund_amount: response.data.refund_amount,
          cancellation_amount: response.data.cancellation_amount,
          penalty_amount: response.data.penalty_amount,
        });
      }
    };
    getRefundData();
  }, []);

  // make api call to confirm booking cancellation
  const cancelBooking = async () => {
    let response = await getData(`/bookings/cancellation/confirm/${ID}`);
    if (response && response.data) {
      console.log(response);
      // SuccessAlert("Cancellation Success", () =>
      history.push("/history/cancelled-booking");
      // );
    } else if (response && response.status && response.status === 400) {
      FailureAlert(
        t("Cancellation failed"),
        `${response.message?.error ?? t("Something went wrong")}`
      );
    } else {
      FailureAlert(t("Cancellation failed"));
    }
  };

  return (
    <div className="cancel-booking">
      {loading && <Spinner />}
      <div className="breadcrumb-title">
        <Breadcrumb text="History" route="/history" />
        <Breadcrumb
          text="Journey details"
          route={`/history/${ID}/journey-details`}
        />
        <Breadcrumb text="Cancel booking" islast={true} />
      </div>
      <div className="container">
        <div className="text-mobile-view">
          {t(
            "Upon cancellation, a refund will be initiated to your original payment method after deducting processing charges"
          ) + "."}
        </div>
        <div className="payment">
          <div className="text-info">
            {t(
              "Upon cancellation, a refund will be initiated to your original payment method after deducting processing charges"
            ) + "."}
          </div>
          <div className="payment-info">
            <div className="original-payment">
              <span className="text">{t("Original payment")}</span>
              <span className="amount">
                {t("SAR")} {bookingDetails.total_cost}
              </span>
            </div>

            <div className="cancellation-penalty">
              <span className="text">{t("Cancellation penalty")}</span>
              <span className="amount">
                {t("SAR")} {cancelEstimate.penalty_amount}
              </span>
            </div>

            <div className="processing-charges">
              <span className="text">{t("Processing charges")}</span>
              <span className="amount">
                {t("SAR")} {cancelEstimate.cancellation_amount}
              </span>
            </div>
          </div>
        </div>
        <div className="refundable-amount">
          <span className="text">{t("Refundable amount")}</span>
          <span className="amount">
            {t("SAR")} {cancelEstimate.refund_amount}
          </span>
        </div>
        <div className="title-info">
          <div className="id">
            {t("Booking ID")} - {bookingDetails.bookingId}
          </div>
          {bookingDetails.journey?.trailer.reg_no &&
            <div className="date-info">
              {t("Trailer number")}
              <span className="date">
                {/* {bookingDetails.journey?.trailer.reg_no
                  ? bookingDetails.journey.trailer.reg_no
                  : t("Not available")} */}
                  {bookingDetails.journey.trailer.reg_no}
              </span>
            </div>
          }
        </div>
        <div className="booking-details">
          <div className="title">
            <span className="sub-title">{t("Pickup location")}</span>
            <span className="text">{bookingDetails.pickup_location_name}</span>
          </div>
          <div className="title">
            <span className="sub-title">{t("Drop-off location")}</span>
            <span className="text">{bookingDetails.drop_location_name}</span>
          </div>
          <div className="title">
            <span className="sub-title">{t("Pickup date and time")}</span>
            <span className="text">
              {formatDateTime(
                bookingDetails.pickup_datetime,
                "ddd, DD MMM YYYY, hh:mm a"
              )}
            </span>
          </div>
          <div className="title">
            <span className="sub-title">{t("Pickup contact")}</span>
            <span className="text contact">
              {`+${bookingDetails.pickup_contact}`}{" "}
            </span>
          </div>
          <div className="title">
            <span className="sub-title">{t("Drop-off contact")}</span>
            <span className="text contact">{`+${bookingDetails.drop_contact}`}</span>
          </div>
          <div className="title">
            <span className="sub-title">{t("Payment details")}</span>
            <span className="text">
              {/* {t("SAR")+" " +
                bookingDetails.total_cost +
                " " +
                (bookingDetails.PaymentDetails?.updatedAt
                  ? " on " + bookingDetails.PaymentDetails.updatedAt
                  : ", " + t("Payment not done"))} */}
              {/* {bookingDetails.PaymentDetails?.updatedAt ? `${t("SAR")} ${bookingDetails.total_cost}, ${bookingDetails.PaymentDetails.updatedAt}` : t("Pending") } */}
              { bookingDetails.paymentDetails?.updatedAt ? 
                `${t("SAR")} ${bookingDetails.total_cost}, ${formatDateTime(
                  bookingDetails.paymentDetails.updatedAt,
                  "DD MMM YYYY,  h:mm a"
                )}` : bookingDetails.dueSettlementRecord?.updatedAt ?
                `${t("SAR")} ${bookingDetails.total_cost}, ${formatDateTime(
                  bookingDetails.dueSettlementRecord?.updatedAt,
                  "DD MMM YYYY,  h:mm a"
                )}` : t("Pending") 
              }
            </span>
          </div>
        </div>
        <div className="cancel-warning">
          {t("Cancellations cannot be undone") + "."}
        </div>
        <SquareButton
          text={"Cancel booking"}
          isdisabled={!bookingDetails.is_cancellable}
          onClickBtn={cancelBooking}
        />
      </div>
    </div>
  );
}
