import React from "react";
import "./ActionStatus.css";
import { useTranslation } from "react-i18next";

const ActionStatus = ({ heading, image, text }) => {
  const { t } = useTranslation();
  return (
    <div className="action-status">
      <img src={image} />
      <p className="heading">{t(heading)}</p>
      <p className="text">{text}</p>
    </div>
  );
};

export default ActionStatus;
