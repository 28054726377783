import en from "./en.json";
import ar from "./ar.json";
import ur from "./ur.json";

const languageSelector = (string) => {
  let languageArray = { en: en, ar: ar, ur: ur };
  let languageSelector = localStorage.getItem("Olly_lang")
    ? localStorage.getItem("Olly_lang")
    : "en";
  let changedLanguage = languageArray[languageSelector].hasOwnProperty(string)
    ? languageArray[languageSelector][string]
    : string;
  return changedLanguage;
};
export default languageSelector;
