import React, { useRef, useEffect, useState, useMemo } from 'react'
import './fileUpload.css'
import { useTranslation } from 'react-i18next';
import {ReactComponent as UploadIcon} from '../../assets/file-upload-icon.svg'
import {ReactComponent as JpegIcon} from '../../assets/file-jpeg.svg'
import {ReactComponent as PngIcon} from '../../assets/file-png.svg'
import {ReactComponent as PdfIcon} from '../../assets/file-pdf.svg'

const FileUpload = ({
  id,
  name,
  label,
  accept,
  errorField,
  placeholder,
  validate,
  maxSize=10,
  defaultValue = null,
}) => {
  const [defaultFileName, setDefaultFileName] = useState()
  const [defaultFileType, setDefaultFileType] = useState()
  const [uploadedFile, setUploadedFile] = useState();
  const [uploadedFileType, setUploadedFileType] = useState();
  const [showLabel, setshowLabel] = useState(0);
  const fileInput = useRef();
  const { t } = useTranslation();

  
  const handleInputClick = () => {
    fileInput.current.click()
  }
  
  const handleFileUpload = (e) => {
    setshowLabel(1);
    const file = e.target.files[0];
    const [fileType, fileExtension] = file?.type.split("/");
    setUploadedFile(file);
    setUploadedFileType(fileExtension);
    validate({
        id: id,
        value: file,
        name: name,
        maxSize: maxSize,
        fileName:file.name,
        fileType: fileExtension,
        fileSize: file.size
    }, true);
  };

  // memorise defaultValue object and prevent infinite looping
  const defaultValueMemo = useMemo (() => {
    return defaultValue
  }, [])

  // to setup field with default value
  useEffect(() => {
    console.log("default from file upload", defaultValue)
    if (defaultValue) {
      setDefaultFileName(defaultValue.fileName)
      setDefaultFileType(defaultValue.fileType)
      validate({
        id: id,
        name: name,
        maxSize: maxSize,
        fileName: defaultValue.fileName,
        fileType: defaultValue.fileType,
        value: {
          size: defaultValue.fileSize
        }
      }, false);
      setshowLabel(1);
    }
  }, [defaultValueMemo])
  
  return (
    <div className='file-upload__container input-field'>
        <label
          className={`label ${
            showLabel === 0
              ? "invisible"
              : showLabel === 1
              ? "fadeIn"
              : "fadeOut"
          }`}
        >{label ? t(label) : t(placeholder)}</label>
        <div  className={`file-upload__wpr input-box ${uploadedFile && "file-upload__wpr--uploaded"}`} onClick={handleInputClick} >
          { uploadedFile ? 
            <>
              {uploadedFileType === "jpeg" && <JpegIcon className='file-upload__file-icon'/>}
              {uploadedFileType === "png" && <PngIcon className='file-upload__file-icon'/>}
              {uploadedFileType === "pdf" && <PdfIcon className='file-upload__file-icon'/>}
              <span>{uploadedFile?.name?.length > 28 ? uploadedFile.name.substring(0, 24)+"..." : uploadedFile.name}</span>
              {/* <span>{uploadedFile.size}</span> */}
            </> :
            defaultValue ?
            <>
              {defaultFileType === "jpeg" && <JpegIcon className='file-upload__file-icon'/>}
              {defaultFileType === "png" && <PngIcon className='file-upload__file-icon'/>}
              {defaultFileType === "pdf" && <PdfIcon className='file-upload__file-icon'/>}
              <span>{defaultFileName?.length > 28 ? defaultFileName.substring(0, 24)+"..." : defaultFileName}</span>
              {/* <span>{uploadedFile.size}</span> */}
            </> :
            <>
              <UploadIcon className='file-upload__cloud-icon' />
              <span className='placeholder'>{label ? t(label) : t(placeholder)}</span>
            </>
          }
          <input name={name} ref={fileInput} type="file" className='file-upload__input' onChange={handleFileUpload} accept={accept}/>
        </div>
        {errorField && <span className="error-field">{t(errorField) + "."}</span>}
    </div>
  )
}

export default React.memo(FileUpload);