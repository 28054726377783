export default function (status) {
  let color = "#000000";
  let status_text = "";
  if (status === "cancelled") {
    color = "#d0021b"; //red
    status_text = "Cancelled";
  } else if (status === "confirmed") {
    color = "#7ed321"; //green
    status_text = "Confirmed";
  } else if (status === "delivered") {
    color = "#6673A1";
    status_text = "Delivered";
  } else if (status === "in_transit") {
    color = "#FF9E00";
    status_text = "In transit";
  } else if (status === "awaiting_trailer_assignment") {
    color = "#8BC900";
    status_text = "Assigning trailer";
  } else if (status === "journey_scheduled") {
    color = "#4FAD4D";
    status_text = "Upcoming journey";
  } else if (status === "picked_up") {
    color = "#FC5F47";
    status_text = "Picked Up";
  } else if (status === "emergency_cancellation") {
    status_text = "Emergency Cancellation";
  } else if (status === "payment_complete") {
    color = "#7ED321";
    status_text = "Paid"; //green
  } else if (status === "pending_payment") {
    color = "#F5A623"; //yellow
    status_text = "Pending";
  } else if (status === "refund_initiated") {
    color = "#6673A1"; //blue-grey
    status_text = "Refunded";
  } else if(status === "awaiting_payment") {
    status_text = "Awaiting Payment"
  } else if(status === "awaiting_price_marking") {
    status_text = "Awaiting Price Marking"
  } else {
    status_text = status.replace(/_/g, " ");
  }
  return [color, status_text];
}
