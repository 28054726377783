import React from "react";
import formatDateTime from "../../utils/formatDateTime";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";
import Images from "../../assets";
import moment from "moment";
import bookingStatusColor from "../../utils/bookingStatusColor";
import Roboto from "../../assets/Fonts/Roboto-Regular.ttf";

const DetailsPDF = ({
  status,
  color,
  id,
  trailer,
  date,
  pickup,
  pickupContact,
  dropOff,
  dropContact,
  amount,
  paidOn,
}) => {
  // configurations for react-pdf-renderer library
  Font.register({ family: "Roboto", src: Roboto });
moment.locale("en");
  const styles = StyleSheet.create({
    page: {
      //   backgroundColor: "#f9f9fb",
      fontFamily: "Roboto",
      fontSize: 15,
      fontWeight: 400,
      color: "#323745",
    },
    section: {
      margin: 70,
      padding: 10,
      flexGrow: 1,
    },
    imageView: {
      paddingBottom: 60,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
    },
    logo: {
      height: 50,
      padding: 5,
    },
    header: { paddingTop: 25, paddingBottom: 20 },
    status: {
      color: color,
      fontSize: 20,
      fontWeight: 600,
      marginBottom: "20px",
      fontWeight: "bold",
    },
    booking: { marginBottom: "5px", fontSize: 16 },
    line: {
      width: "100%",
      height: "3px",
      backgroundColor: "#f9f9fb",
      borderRadius: 50,
    },
    date: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      marginTop: 30,
    },
    details: { display: "flex", flexDirection: "row", marginTop: 30 },
    dots: { display: "flex", flexDirection: "column" },
    circle: {
      height: 14,
      width: 14,
      borderRadius: 50,
      backgroundColor: "#f95138",
    },
    area: {
      height: 100,
      width: 4,
      backgroundColor: "#efcac6",
      marginLeft: 5,
    },
    locations: {
      display: "flex",
      flexDirection: "column",
      marginLeft: "10px",
    },
    locationDetails: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      height: 115,
    },
    fromTo: { fontSize: 13, marginTop: 10, marginBottom: 10 },
    contact: { fontSize: 13, marginBottom: 40 },
    amount: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      fontSize: 20,
      fontWeight: 600,
      marginTop: 30,
    },
  });

  return (
    <Document>
      <Page style={styles.page}>
        <View style={styles.section}>
          <View style={styles.imageView}>
            {/* <Image source={Images.olly_logo} style={styles.logo} /> */}
            <Image source={Images.olly_eng} style={styles.logo} />
          </View>
          <View>
            <View style={styles.header}>
              <Text style={styles.status}>{status}</Text>
              <Text style={styles.booking}>{"Booking ID: " + id}</Text>
              <Text style={styles.booking}>{"Trailer number: " + trailer}</Text>
            </View>
            <View style={styles.line}></View>
            <View style={styles.date}>
              <Text>Date and time</Text>
              <Text>{date}</Text>
            </View>
            <View style={styles.details}>
              <View style={styles.dots}>
                <View style={styles.circle} />
                <View style={styles.area} />
                <View style={styles.circle} />
              </View>
              <View style={styles.locations}>
                <View style={styles.locationDetails}>
                  <Text>Pickup location</Text>
                  <Text style={styles.fromTo}>{pickup}</Text>
                  <Text style={styles.contact}>
                    {"Contact: " + pickupContact}
                  </Text>
                </View>
                <View style={styles.locationDetails}>
                  <Text>Drop-off location</Text>
                  <Text style={styles.fromTo}>{dropOff}</Text>
                  <Text style={styles.contact}>
                    {"Contact: " + dropContact}
                  </Text>
                </View>
              </View>
            </View>
            <View style={styles.amount}>
              <Text>Total amount</Text>
              <Text>{"SAR " + amount}</Text>
            </View>
            <Text style={styles.fromTo}>
              {paidOn === "Payment not done"
                ? paidOn
                : "Paid on: " + paidOn}
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default DetailsPDF;
