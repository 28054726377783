import React, { useState, useEffect, useContext } from "react";
import "./Header.css";
import Images from "../../assets";
import { NavLink, useLocation, useHistory } from "react-router-dom";
import HeaderDropdown from "./HeaderDropdown";
import { getData } from "../../api";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../App";
import firebase from "../../firebase";
// import { ToastContainer, toast } from "react-toastify";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
// import "react-toastify/dist/ReactToastify.css";
import notificationCount from "../../utils/notificationCount";
import imageSelector from "../../utils/LangImages";

function Header() {
  const [profileImage, setProfileImage] = useState({});
  const history = useHistory();
  const location = useLocation();

  const [active, setActive] = useState({});
  const [number_of_notification, setnumber_of_notification] = useState(0);
  // parseInt(localStorage.getItem("notifications")) || 0
  const { state } = useContext(AuthContext);
  const { t } = useTranslation();
  let messaging;

    // executes before component render
  useEffect(() => {
    let timer = null;
    // call function which makes api call to get notification count
    (async () => {
      setnumber_of_notification(await notificationCount());
      console.log("timer");
    })();

    // executes in every 10 seconds
    timer = setInterval(() => {
      (async () => {
        setnumber_of_notification(await notificationCount());
      })();
      console.log("timer");
    }, 10000);

    return () => clearInterval(timer);
  }, []);
  
  // firebase push notification setup
  try {
    messaging = firebase.messaging();

    messaging.onMessage((payload) => {
      console.log("onMessage", payload);
      let type = "success";
      if (!location.pathname.includes("/notifications")) {
        switch (type) {
          case "info":
            NotificationManager.info(
              payload.notification.body,
              payload.notification.title,
              3000,
              () => {
                history.push("/notifications");
              }
            );
            break;
          case "success":
            NotificationManager.success(
              payload.notification.body,
              payload.notification.title,
              3000,
              () => {
                history.push("/notifications");
              }
            );
            break;
          case "warning":
            NotificationManager.warning(
              payload.notification.body,
              payload.notification.title,
              3000,
              () => {
                history.push("/notifications");
              }
            );
            break;
          case "error":
            NotificationManager.error(
              payload.notification.body,
              payload.notification.title,
              3000,
              () => {
                history.push("/notifications");
              }
            );
            break;
        }
      }
    });
  } catch (e) {
    console.log(e);
  }
  // useEffect(() => {
  //   messaging.onMessage((payload) => {
  //     console.log("onMessage", payload);
  //     if (location && location.pathname.includes("/notifications")) {
  //     } else {
  //       setnumber_of_notification(
  //         parseInt(localStorage.getItem("notifications"))
  //       );
  //       localStorage.setItem("notifications", number_of_notification + 1);
  //     }
  //     console.log(parseInt(number_of_notification));
  //   });
  // }, []);
  // console.log(parseInt(number_of_notification));

  // executes before component render and when ever location value changes
  // check path and make correspoding menu active
  useEffect(() => {
    let _active = {
      booking: false,
      history: false,
      transaction: false,
      notification: false,
    };
    if (
      (location && location.pathname.includes("/booking")) ||
      location.pathname == "/"
    )
      _active.booking = true;
    else if (location && location.pathname.includes("/history"))
      _active.history = true;
    else if (location && location.pathname.includes("/transactions"))
      _active.transaction = true;
    else if (location && location.pathname.includes("/notifications")) {
      _active.notification = true;
      setnumber_of_notification(0);
    }
    // else _active.booking = true;
    setActive(_active);
  }, [location]);

  // executes before component render
  // call the function which make api call to get user profile details
  useEffect(() => {
    const getProfileDetails = async () => {
      let response = await getData("/users/profile");
      if (response && response.data) {
        console.log(response);
        setProfileImage(
          response.data.profile_pic ? response.data.profile_pic : Images.avatar
        );
      }
    };
    getProfileDetails();
  }, []);

  return (
    <div className="header">
      {/* <ToastContainer /> */}
      <div
        className="logo"
        onClick={() => {
          history.push("/");
        }}
      >
        {/* <img src={Images.olly_logo} className="olly_logo" /> */}
        <img src={imageSelector("logo")} className="olly_text" />
      </div>
      <div className="titles">
        <NavLink
          exact
          to="/"
          className="nav-link"
          activeClassName="nav-link--active"
        >
          <div className={`heading ${active.booking ? "active" : ""}`}>
            <span className={`title ${active.booking ? "active" : ""}`}>
              {t("Book a trailer")}
            </span>
          </div>
        </NavLink>
        <NavLink
          exact
          to="/history"
          className="nav-link"
          activeClassName="nav-link--active"
        >
          <div className={`heading ${active.history ? "active" : ""}`}>
            <span className={`title ${active.history ? "active" : ""}`}>
              {t("History")}
            </span>
          </div>
        </NavLink>
        <NavLink
          exact
          to="/transactions"
          className="nav-link"
          activeClassName="nav-link--active"
        >
          <div className={`heading ${active.transaction ? "active" : ""}`}>
            <span className={`title ${active.transaction ? "active" : ""}`}>
              {t("Transactions")}
            </span>
          </div>
        </NavLink>
        <NavLink
          exact
          to="/notifications"
          className="nav-link"
          activeClassName="nav-link--active"
        >
          <div className={`heading ${active.notification ? "active" : ""}`}>
            <span className={`title ${active.notification ? "active" : ""}`}>
              {t("Notifications")}
              {number_of_notification > 0 &&
                !location.pathname.includes("/notifications") && (
                  <img src={Images.ovel} className="ovel" />
                )}
            </span>
          </div>
        </NavLink>
        <div className="contact">
          <img
            src={state.profilePic ? state.profilePic : profileImage}
            className={`profile_pic`}
          ></img>
          <HeaderDropdown />
        </div>
      </div>
      <NotificationContainer />
    </div>
  );
}
export default Header;
