import React from "react";
import "./ContactUs.css";
import Images from "../../assets";
import Breadcrumb from "../../components/Breadcrumb";
import { useTranslation } from "react-i18next";
import Footer from "../../components/Footer";

// this component will render on click Contact Olly button in profie section 
const ContactUs = () => {
  const { t } = useTranslation();
  return (
    <div className="contact-us">
      <Breadcrumb text="Contact Olly" islast={true} />
      {/* <ActionStatus
        image={Images.contact_us}
        text={
          <p
            style={{
              fontSize: "18px",
              lineHeight: "25px",
              letterSpacing: "-0.43px",
            }}
          > */}
      <div className="content">
        <img src={Images.contact_us} />
        <p>
          {t("If you have any queries, write to us at")}
          <br />
          <a
            style={{
              fontSize: "21px",
              fontWeight: "500",
              textDecoration: "none",
              color: "inherit",
            }}
            href="mailto: support@olly.com"
          >
            support@olly.com
          </a>
        </p>
        <p className="phone-no">
          {t("Call us on")} <br />
          <a
            style={{
              fontSize: "21px",
              fontWeight: "500",
              textDecoration: "none",
              color: "inherit",
            }}
            href="tel:+919562437375"
          >
            {"+91 9562437375"}
          </a>
        </p>
        {/* </p>
        }
      /> */}
      </div>
    </div>
  );
};

export default ContactUs;
