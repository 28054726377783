import React, { useState } from "react";
import "./Dropdown.css";
import { useTranslation } from "react-i18next";
import Select from "react-select";

const Dropdown = ({
  placeholder,
  validate = false,
  name,
  id,
  errorField,
  options,
  label = "",
  setSelected,
  selected,
  searchable=false,
  tabIndex
}) => {
  const { t } = useTranslation();
  const [isMenuOpen, setIsMenuOpen] = useState(0);

  let SelectStyle = {
    valueContainer: (base, styles) => {
      return {
        ...base,
        backgroundColor: "#fff",
        color: "#323745",
        boxShadow: "inset 0 2px 4px -1px #e9e9e9",
        border: errorField ? "1px solid #fa0303" : 0,
        borderRight: 0,
        borderRadius: "6px 0 0px 6px",
      };
    },
    dropdownIndicator: (base, styles) => {
      return {
        ...base,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "#323745",
        boxShadow: "inset 0 2px 4px -1px #e9e9e9",
        borderRadius: "0 6px 6px 0",
        border: errorField ? "1px solid #fa0303" : 0,
        borderLeft: 0,
      };
    },
    container: (base, styles) => {
      return {
        ...base,
        position: "relative",
      };
    },
    control: (base, styles) => {
      return {
        ...base,
        borderRadius: "0px",
        backgroundColor: "#fff",
        border: 0,
        boxShadow: "none"
      };
    },
    menu: (base, styles) => {
      return {
        ...base,
        backgroundColor: "#fff",
        color: "#323745",
        margin: "0"
      };
    },
    option: (base, styles) => {
      return {
        ...base,
        color: "#323745",
      };
    },
    singleValue: (base, styles) => {
      return {
        ...base,
        color: "#323745"
      };
    },
    placeholder: (base, styles) => {
      return {
        ...base,
        color: "#9aa0b5",
      };
    },
    input: (base, state) => {
      return {
        ...base,
        color: 'red',
      }
    }
  };

  const handleSelect = (option) => {
    validate && validate({
      id: id,
      value: option.value,
      name: name
    })
    setSelected(option)
  }
  const handleMenuOpen = () => {
    setIsMenuOpen(1)
  }
  const handleMenuClose = () => {
    setIsMenuOpen(-1)
  }

  return (
    <div className="custom-droopdown-component">
      <label
        className={`label ${
          isMenuOpen === 0 || Object.keys(selected).length === 0
          ? "invisible"
          : Object.keys(selected).length > 0 && "fadeIn"
        }`}
      >
        {label ? t(label) : t(placeholder)}
      </label>
      <Select
        tabIndex={tabIndex}
        className={"dropdown-component"} 
        classNamePrefix="dropdown-component"
        placeholder={placeholder}
        options={options}
        isSearchable={searchable}
        styles={SelectStyle}
        components={{
          IndicatorSeparator: () => null
        }}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary25: '#f2f6f9',
            primary: '#eceff1',
          },
        })}
        onChange={handleSelect}
        onMenuOpen={handleMenuOpen}
        onMenuClose={handleMenuClose}
        // value={selected}
      />
      {errorField && <span className="error-field">{t(errorField) + "."}</span>}
    </div>
  );
};

export default Dropdown;
