import React, { useState } from "react";
import Images from "../../assets";
import "./Password.css";
import { useTranslation } from "react-i18next";

const Password = ({
  placeholder,
  text,
  validate,
  errorField,
  id = 0,
  name = "",
  isConfirmed = false,
  label = "",
}) => {
  const { t } = useTranslation();

  const [type, setType] = useState("password");
  const [image, setImage] = useState(Images.closed_eye);
  const [inputText, setinputText] = useState("");
  const [showLabel, setshowLabel] = useState(0);

  const changeState = () => {
    if (type === "password") {
      setType("text");
      setImage(Images.open_eye);
    } else {
      setType("password");
      setImage(Images.closed_eye);
    }
  };
  return (
    <div className="password">
      <label
        className={`label ${
          showLabel === 0
            ? "invisible"
            : inputText || showLabel === 1
            ? "fadeIn"
            : "fadeOut"
        }`}
      >
        {label ? t(label) : t(placeholder)}
      </label>
      <input
        autocomplete="new-password"
        className={`password-input-box ${errorField ? "error" : ""}`}
        type={type}
        name={name}
        placeholder={t(placeholder)}
        value={text}
        maxLength={15}
        onChange={(e) => {
          validate({
            id: id,
            value: e.target.value,
            name: e.target.name,
          });
          setinputText(e.target.value);
        }}
        onFocus={() => {
          setshowLabel(1);
        }}
        onBlur={() => {
          setshowLabel(-1);
        }}
      />
      <img src={image} onClick={() => changeState()} />
      {errorField && <span className="error-field">{errorField}</span>}
      {/* {errorField == "" && isConfirmed && (
        <span className="error-field" style={{ color: "green" }}>
          {"Confirmed"}
        </span>
      )} */}
    </div>
  );
};

export default Password;
