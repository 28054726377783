import React from "react";
import "./IconButton.css";
import { useTranslation } from "react-i18next";

const IconButton = ({ style, borderColor, text, onClickBtn, isdisabled, type, iconSrc, iconAlt}) => {
  const { t } = useTranslation();
  return (
    <button
      className={`icon-button ${type ?? ""}`}
      style={{...style, borderColor: borderColor}}
      onClick={onClickBtn}
      disabled={isdisabled}
    >
      <img className="icon-button__icon" src={iconSrc} alt={iconAlt}/>
      {t(text)}
    </button>
  );
};
export default IconButton;
