import React from "react";
import "./NoMatch.css";
import Image from "../../assets";
import { Link } from "react-router-dom";
import Password from "../../components/Password";
import Images from "../../assets";
import { useTranslation } from "react-i18next";

const NoMatch = () => {
  // This component is shown when no route exists

  const { t } = useTranslation();
  return (
    <div className="no-match">
      <img src={Images.page_not_found}></img>
      <p className="page-not-found">{t("Sorry! Page not found")}</p>
    </div>
  );
};

export default NoMatch;
