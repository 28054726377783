import moment from "moment";
import "moment/locale/ar";
import "moment/locale/ur";

// format date using moment library
const formatDateTime = (date, format) => {
  moment.locale(
    localStorage.getItem("Olly_lang") ? localStorage.getItem("Olly_lang") : "en"
  );
  return moment(date).format(format);
};

// return seconds, minutes, hours from seconds
export const secondsToHms = (d) => {
  d = Number(d);
  var h = Math.floor(d / 3600);
  var m = Math.floor(d % 3600 / 60);
  var s = Math.floor(d % 3600 % 60);

  var hours = h > 0 ? h : '0';
  var minutes = m > 0 ? m : '0';
  var seconds = s > 0 ? (s < 10 ? `0${s}` : s) : '00';

  return {
    seconds,
    minutes,
    hours
  }
}

export default formatDateTime;
