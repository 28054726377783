import React, { useEffect, useReducer, useState } from "react";
import "./History.css";
import Cards from "../../components/Cards";
import SearchBar from "../../components/SearchBar";
import Spinner from "../../components/Spinner";
import { useHistory } from "react-router-dom";
import { getData } from "../../api";
import formatDateTime from "../../utils/formatDateTime";
import NoResult from "../../components/NoResult";
import Breadcrumb from "../../components/Breadcrumb";
import Images from "../../assets";
import { PDFDownloadLink } from "@react-pdf/renderer";
import ListPDF from "../../components/ListPDF";
import { useTranslation } from "react-i18next";
import moment from "moment";
import UseCounter from "../../hooks/useCounter";

export default function History() {
  moment.locale("en");
  const history = useHistory();
  const { t } = useTranslation();
  const [loading, setloading] = useState(true);
  const [transactions, settransactions] = useState([]);
  const [filterList, setfilterList] = useState([]);
  const [isEmpty, setIsEmpty] = useState(false);
  const [showPDFDownloader, setShowPDFDownloader] = useState(false);
  const [seconds] = UseCounter(10000);

  // to get booking data before page render and update booking date in every 10 seconds
  useEffect(() => {
    getMyBookings();
  }, [seconds]);
  console.log(seconds, "seconds");

  // function which handle get booking data api response
  const getMyBookings = async () => {
    let response = await getData(`/bookings`);
    setloading(false);
    if (response && response.data) {
      console.log(response);
      settransactions(response.data);
      setfilterList(response.data);
    }
  };

  // check booking data is empty or not 
  // calls when the page render for first time and when ever the filterList (search field data) value changes
  useEffect(() => {
    if (filterList.length !== 0) {
      setIsEmpty(false);
    } else {
      setIsEmpty(true);
    }
  }, [filterList]);

  // function to handle search field input
  const searchHandler = (search) => {
    setShowPDFDownloader(false);
    if (search === "") {
      setfilterList(transactions);
    } else {
      setfilterList(
        transactions.filter((item) => {
          return (
            item.pickup_short_name
              .toLowerCase()
              .includes(search.toLowerCase().trim()) ||
            item.drop_short_name
              .toLowerCase()
              .includes(search.toLowerCase().trim()) ||
            item.bookingId.toLowerCase().includes(search.toLowerCase().trim())
          );
        })
      );
    }
  };
  console.log(filterList);

  // direct to booking details page
  const BookingInfo = (id) => {
    history.push(`/history/${id}/journey-details`);
  };

  // function to transform filterList (search input) data to a format which accepted by react-pdf-renderer library
  const transformData = (filterList) => {
    return filterList.map((item) => {
      return {
        status: item.status,
        amount: "Booking ID - " + item.bookingId,
        date: moment(item.booking_date).format("DD MMM YYYY, hh:mm a"),
        from: item.pickup_location_name,
        to: item.drop_location_name,
      };
    });
  };
  
  return (
    <div className="history">
      {loading && <Spinner />}
      <Breadcrumb text="History" islast={true} />

      {transactions.length === 0 ? (
        <div className="no-bookings">No history</div>
      ) : (
        <>
          {!showPDFDownloader && (
            <div className="pdf-download">
              <button
                className="download-btn"
                onClick={() => {
                  setShowPDFDownloader(true);
                }}
              >
                <img src={Images.pdf} className="pdf-img" />
                <span className="pdf-text">{t("Prepare PDF")}</span>
              </button>
            </div>
          )}
          {showPDFDownloader && (
            <PDFDownloadLink
              className="pdf-download"
              document={
                <ListPDF
                  heading="Booking history details"
                  data={transformData(filterList)}
                />
              }
              fileName={`OllyTrips_${moment().unix()}.pdf`}
            >
              {({ blob, url, loading, error }) => (
                <button
                  className="download-btn"
                  onClick={() => {
                    setShowPDFDownloader(true);
                  }}
                >
                  <img src={Images.pdf} className="pdf-img" />
                  {loading ? (
                    <span className="pdf-text" style={{ cursor: "auto" }}>
                      {t("Processing") + " "}
                      <i class="fa fa-spinner  fa-pulse" aria-hidden="true" />
                    </span>
                  ) : (
                    <span className="pdf-text" style={{ cursor: "pointer" }}>
                      {t("Download PDF")}
                    </span>
                  )}
                </button>
              )}
            </PDFDownloadLink>
          )}
          <SearchBar
            placeholder={"Search booking ID or places"}
            searchHandler={searchHandler}
          />
          <div className="list">
            {filterList.map((item, i) => (
              <div
                className="card"
                key={i}
                onClick={() => BookingInfo(item.id)}
              >
                <Cards
                  date={formatDateTime(item.booking_date, "ddd, DD MMM YYYY")}
                  id={`${t("Booking ID")} - ${item.bookingId}`}
                  from={item.pickup_location_name}
                  to={item.drop_location_name}
                  status={item.status}
                />
              </div>
            ))}
          </div>
          <div className="no-results">{isEmpty && <NoResult />}</div>
        </>
      )}
    </div>
  );
}
