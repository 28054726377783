import { getData } from "../api";
export default async function notificationCount() {
  let response = await getData(`/notification/history/100`);

  //   let initial_time_stamp = localStorage.getItem("notification_timeStamp")
  //     ? localStorage.getItem("notification_timeStamp")
  //     : response.data[response.data.length - 1].createdAt;
  if (response && response.data) {
    if (localStorage.getItem("notification_timeStamp")) {
      for (let i = 0; i < response.data.length; i++) {
        if (
          response.data[i].createdAt ===
          localStorage.getItem("notification_timeStamp")
        ) {
          return i;
        }
      }
    }
    return response.data.length;
  }
  return 0;
}
