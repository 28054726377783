import React, { useState, useContext } from "react";
import "./ChangePassword.css";
import PasswordField from "../../components/Password";
import Button from "../../components/SquareButton";
import { useHistory } from "react-router-dom";
import {
  SimpleAlert,
  FailureAlert,
  SuccessAlert,
} from "../../utils/alertTemplate";
import { putData } from "../../api";
import { AuthContext } from "../../App";
import { logout } from "../../api";
import Breadcrumb from "../../components/Breadcrumb";
import { MandatoryFieldCheck, ValidatePassword } from "../../utils/Validation";
import { useTranslation } from "react-i18next";

// this component will get renderd on click change password button in profile 
export default function ChangePassword() {
  const history = useHistory();
  const [errors, seterrors] = useState({
    oldpassword: "",
    password: "",
    confirmPassword: "",
  });
  const [passwords, setpasswords] = useState({
    oldpassword: "",
    password: "",
    confirmPassword: "",
  });
  const { t } = useTranslation();
  const { dispatch } = useContext(AuthContext);

  // to signout when reset password is successful
  const signout = () => {
    logout();
    dispatch({
      type: "LOGOUT",
    });
    history.push("/");
  };

  // called on click reset button
  // check new password and confirm password are same or not. make update password api call if both are same
  const ChangePasswordBtn = async () => {
    if (passwords.password !== passwords.confirmPassword) {
      FailureAlert(t("Error"), t("Passwords are not matching"));
    } else {
      let response = await putData(`/auth/users/update_password`, {
        oldPassword: passwords.oldpassword,
        newPassword: passwords.confirmPassword,
      });
      console.log(response);
      if (response && response.data) {
        signout();
      } else if (response && response.status && response.status === 400) {
        FailureAlert(
          t("Action Failed"),
          `${response.message?.error ?? t("Something went wrong")}`
        );
      } else {
        FailureAlert(t("Action Failed"));
      }
    }
  };

  // to validate input fields
  const validate = (inputText) => {
    let error = "";
    switch (inputText.id) {
      // validate old password field
      case 0: {
        error = MandatoryFieldCheck(inputText.value);
        setpasswords({ ...passwords, oldpassword: inputText.value });
        seterrors({ ...errors, oldpassword: error });
        break;
      }
      // validate new password field
      case 1: {
        error = ValidatePassword(inputText.value);
        setpasswords({ ...passwords, password: inputText.value });
        seterrors({ ...errors, password: error });
        break;
      }
      // validate confirm password field
      case 2: {
        error = ValidatePassword(inputText.value);
        setpasswords({ ...passwords, confirmPassword: inputText.value });
        seterrors({ ...errors, confirmPassword: error });
      }
    }
  };

  // const keyPressHandler = (key) => {
  //   if (key.charCode === 13 || key.keyCode === 13) {
  //     ChangePasswordBtn();
  //   }
  // };
  // useEffect(() => {
  //   document.addEventListener("keypress", keyPressHandler);
  //   // Remove event listeners on cleanup
  //   return () => {
  //     document.removeEventListener("keypress", keyPressHandler);
  //   };
  // }, [passwords]);

  return (
    <div className="change-password">
      <Breadcrumb text="Create a new password" islast={true} />
      <div className="input-container">
        <PasswordField
          id={0}
          placeholder="Old password"
          errorField={errors.oldpassword}
          validate={validate}
        />
        <PasswordField
          id={1}
          placeholder="New password"
          errorField={errors.password}
          validate={validate}
        />
        <PasswordField
          id={2}
          placeholder="Confirm password"
          validate={validate}
          errorField={errors.confirmPassword}
        />
        <Button text="Reset password" onClickBtn={ChangePasswordBtn} />
      </div>
    </div>
  );
}
