import React, { useState, useRef, useEffect } from "react";
import "./Booking.css";
import { useHistory } from "react-router-dom";
import InputField from "../../components/InputField";
import Dropdown from "../../components/Dropdown";
import Button from "../../components/SquareButton";
import {
  GoogleMap,
  Marker,
  DirectionsService,
  DirectionsRenderer,
} from "@react-google-maps/api";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Geosuggest from "react-geosuggest";
import { getData, postData } from "../../api";
import mapStyle from "../../constants/mapStyle";
import Breadcrumb from "../../components/Breadcrumb";
import { useTranslation } from "react-i18next";
import Images from "../../assets";
import { FailureAlert } from "../../utils/alertTemplate";
import formatDateTime from "../../utils/formatDateTime";
import { MandatoryFieldCheck, ValidateEmail, ValidateLoadWeight, ValidatePhoneNo } from "../../utils/Validation";
import { GEOCODE_API_URL } from "../../api/config";
import { weightOptions } from "../../constants";

/**
 * Booking page component.
 *
 * @component
 */
const Booking = () => {
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(null);
  const [Contacts, setContacts] = useState({
    pickupContact: "",
    dropContact: "",
  });
  const [showPickupSuggests, setShowPickupSuggests] = useState(false);
  const [showDropOffSuggests, setShowDropOffSuggests] = useState(false);
  const [pickupLat, setPickupLat] = useState("");
  const [pickupLng, setPickupLng] = useState("");
  const [dropOffLat, setDropOffLat] = useState("");
  const [dropOffLng, setDropOffLng] = useState("");
  const [pickupLocation, setPickupLocation] = useState({});
  const [dropOffLocation, setDropOffLocation] = useState({});
  const [pickupError, setPickupError] = useState("");
  const [dropOffError, setDropOffError] = useState("");
  const [dateError, setDateError] = useState("");
  const [weightUnit, setWeightUnit] = useState(weightOptions[0]);
  const [loadWeight, setLoadWeight] = useState();
  const [loadType, setLoadType] = useState({});
  const [receiverEmail, setReceiverEmail] = useState();
  const [loadTypeOptions, setLoadTypeOptions] = useState({});
  const [vehicleTypeOptions, setVehicleTypeOptions] = useState({});
  const [vehicleType, setVehicleType] = useState({});
  const [error, setError] = useState("");
  const [response, setResponse] = useState();
  const [showPickupLabel, setShowPickupLabel] = useState(0);
  const [showDropOffLabel, setShowDropOffLabel] = useState(0);
  const [showDateLabel, setShowDateLabel] = useState(false);
  const [ispickuplocation, setispickuplocation] = useState("");
  const [isdropofflocation, setisdropofflocation] = useState("");
  const [isdate, setisdate] = useState("");
  const [disableButton, setDisableButton] = useState(false);
  const [pickupAddConverted, setPickupAddConverted] = useState();
  const [dropoffAddConverted, setDropoffAddConverted] = useState();
  const [directionZoomAmine, setDirectionZoomAmine] = useState(true);
  const [center, setCenter] = useState({
    lat: 24.7136,
    lng: 46.6753,
  })

  const [errors, seterrors] = useState({
    loadWeightFieldError: "",
    loadTypeFieldError: "",
    receiverEmailFieldError: "",
    pickupFieldError: "",
    dropoffFieldError: "",
  });

  const pickupEl = useRef(null);
  const dropoffEl = useRef(null);
  const isInitialMount = useRef(true);
  const initialMountRef1 = useRef(true);
  const DirectionsRendererEL = useRef();

  /** Function to check whether mandatory fields (pickup and drop-off contacts) are empty for booking. 
   * 
   * @returns {boolean} True if mandatory fields are empty
  */
  const MandatoryFieldValidation = () => {
    let loadWeightError = ValidateLoadWeight(loadWeight);
    let loadTypeError = MandatoryFieldCheck(loadType.value);
    let pickupError = ValidatePhoneNo(Contacts.pickupContact);
    let dropoffError = ValidatePhoneNo(Contacts.dropContact);

    seterrors({
      loadWeightFieldError: loadWeightError,
      loadTypeFieldError: loadTypeError,
      pickupFieldError: pickupError,
      dropoffFieldError: dropoffError,
    });
    return loadWeightError == "" && loadTypeError == "" && pickupError == "" && dropoffError == "" ? true : false;
  };

  /** Function to check whether pickup and drop-off contacts are in valid. 
   * 
   * @param {string} inputText - pickup or drop-off contact phone number
  */
  const validate = (inputText) => {
    deactivateDirectionZoomAmine()
    let error = "";
    switch (inputText.id) {
      /** case 0 - Input from pickup contact field */
      case "booking__load-weight": {
        // console.log("input ", inputText.value);
        error = ValidateLoadWeight(inputText.value, weightUnit?.value);
        setLoadWeight(inputText.value);
        seterrors({ ...errors, loadWeightFieldError: error });
        break;
      }
      case "booking__load-type": {
        // console.log("input ", inputText.value);
        error = MandatoryFieldCheck(inputText.value);
        seterrors({ ...errors, loadTypeFieldError: error });
        break;
      }
      case "booking__receiver-email": {
        error = ValidateEmail(inputText.value, false);
        setReceiverEmail(inputText.value)
        seterrors({ ...errors, receiverEmailFieldError: error });
        break;
      }
      /** Input from pickup contact field */
      case "booking__pickup-contact": {
        error = ValidatePhoneNo(inputText.value);
        setContacts({ ...Contacts, pickupContact: inputText.value });
        seterrors({ ...errors, pickupFieldError: error });
        break;
      }
      /** Input from drop-off contact field */
      case "booking__dropoff-contact": {
        error = ValidatePhoneNo(inputText.value);
        setContacts({ ...Contacts, dropContact: inputText.value });
        seterrors({ ...errors, dropoffFieldError: error });
        break;
      }
    }
  };

  /* fetch load types and create load type options */
  const getLoadTypes = async() => {
    try {
      const response = await getData(`/load_types`)
      if (response && response.data) {
        const options = createDropdownOptions(response.data);
        setLoadTypeOptions(options)
      }
    } catch (error) {
      console.log("error calling getLoadTypes", error);
    }
  }
  /* fetch vehicle types and create vehicle type options */
  const getVehicleTypes = async() => {
    try {
      const response = await getData(`/vehicle_types`)
      if (response && response.data) {
        const options = createDropdownOptions(response.data);
        setVehicleTypeOptions(options)
      }
    } catch (error) {
      console.log("error calling getVehicleTypes", error);
    }
  }

  /* convert to react-select options format */
  const createDropdownOptions = (data, labelKey="title", valueKey="id") => {
    const dropdownOptions = data.map(item => {
      return ({
        label: item[labelKey],
        value: item[valueKey]
      })
    })
    return dropdownOptions;
  }

  const { t } = useTranslation();

  /** convert coordinates to address */
  const reverseGeoCode = async (lat, lng, type) => {
    try {
      const response = await getData(`/drivers/reverse_geo_code?lat=${lat}&long=${lng}`)
      console.log("response log:", response);

      if (response.status === 200) {
        let location = response.data.location;
        type === "pickup" ? setPickupAddConverted(location) : setDropoffAddConverted(location)
      }
    } catch {
      console.log("error");
    }
  };

  /** Styling for google maps container */
  const containerStyle = {
    height: "100%",
    minHeight: "200px",
    boxShadow: "0 2px 4px 0 #E9E9E9",
  };

  // const center = {
  //   lat: 24.7136,
  //   lng: 46.6753,
  // };

  const bound = {
    lat: 22.9052,
    lng: 39.9125,
  };

  const [map, setMap] = React.useState(null);
  /** map network error handling */
  const Style = () => {
    try {
      return mapStyle;
    } catch {
      return null;
    }
  };
  // const onLoad = React.useCallback(function callback(map) {
  //   let conterPos =  Object.keys(pickupLocation).length > 0 ? pickupLocation : center
  //   const bounds = new window.google.maps.LatLngBounds(bound, conterPos);
  //   map.fitBounds(bounds);
  //   setMap(map);
  // }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  const markerOnLoad = (marker) => {
    console.log("marker: ", marker);
  };

  const directionsCallback = (response) => {
    if (response !== null) {
      if (response.status === "OK") {
        setResponse(response);
      }
    }
  };

  /**
   * Funtion runs on clicking 'Book' button. it calls validate functions and function which makes api call for posting booking data
   */
  const onClickBtn = async () => {
    setDisableButton(true);
    setLoading(true);
    let payLoad = {
      pickup_datetime: date,
      pickup_location: {
        type: "Point",
        coordinates: [pickupLat, pickupLng],
      },
      drop_location: {
        type: "Point",
        coordinates: [dropOffLat, dropOffLng],
      },
      pickup_contact: " ",
      drop_contact: " ",
      // pickup_location_name: ispickuplocation,
      // drop_location_name: isdropofflocation,
      weight_of_load: null,
      unit_of_load: weightUnit.value,
      load_type: null,
      vehicle_type: Object.keys(vehicleType).length > 0 ? vehicleType.value : null,
      receiver_email: receiverEmail,
    };
    if (MandatoryFieldValidation()) {
      payLoad.weight_of_load = loadWeight;
      payLoad.load_type = loadType.value;
      payLoad.pickup_contact = "966" + Contacts.pickupContact;
      payLoad.drop_contact = "966" + Contacts.dropContact;
      }
    else {
      setDisableButton(false);
      return;
    }

    console.log("booking payload:", payLoad);
    const response = await postData("/bookings/new", payLoad);

    setLoading(false);
    
    // if payment is successfull, render booking successfull page else display failure alert
    if (response && response.data) { 
      const id = response.data.id;
      history.push(`/booking-successful/${id}`);
    } else if (response && response.status && response.status === 400) {
      setDisableButton(false);
      FailureAlert(
        t("Failed"),
        `${response.message?.error ?? t("Something went wrong")}`
      );
    } else {
      setDisableButton(false);
      FailureAlert(t("Cancellation failed"));
    }
    // setDisableButton(false)
  };
  /**
   * function to validate from, to and date of pickup fields
   * @param {string} value - value from input field
   * @param {string} type - type of input field
   */
  const changeValue = (value, type) => {
    if (type === "pickup") {
      if (value.trim().length === 0) {
        if (value === "") {
          setPickupError("Cannot be empty");
        } else {
          setPickupError("Cannot be whitespace alone");
        }
        setPickupLocation({});
        setPickupLat("");
        setPickupLng("");
      } else if (!pickupLat && !pickupLng) {
        setPickupError("Select a pickup location from dropdown");
      } else if (value === dropOffLocation) {
        setPickupError("Pickup location cannot be same as drop-off location");
      } else {
        setPickupError("");
      }
    } else if (type === "dropoff") {
      if (value.trim().length === 0) {
        if (value === "") {
          setDropOffError("Cannot be empty");
        } else {
          setDropOffError("Cannot be whitespace alone");
        }
        setDropOffLocation({});
        setDropOffLat("");
        setDropOffLng("");
      } else if (!dropOffLat && !dropOffLng) {
        setDropOffError("Select a drop-off location from dropdown");
      } else if (value === pickupLocation) {
        setDropOffError("Drop-off location cannot be same as pickup location");
      } else {
        setDropOffError("");
      }
    } else if (type === "date") {
      setDate(value);
      if (!value) {
        setDateError("Cannot be empty");
      } else {
        setDateError("");
      }
    }
  };
  // const keyPressHandler = (key) => {
  //   if (key.charCode === 13 || key.keyCode === 13) {
  //     console.log(pickupError, dropOffError, dateError, disableButton);
  //     if (!(pickupError || dropOffError || dateError || disableButton)) {
  //       onClickBtn();
  //     }
  //   }
  // };
  // useEffect(() => {
  //   document.addEventListener("keypress", keyPressHandler);
  //   // Remove event listeners on cleanup
  //   return () => {
  //     document.removeEventListener("keypress", keyPressHandler);
  //   };
  // }, [pickupLat, dropOffLat, date]);

  const deactivateDirectionZoomAmine = () => {
    directionZoomAmine && Object.keys(pickupLocation).length > 0 && Object.keys(dropOffLocation).length > 0 && setDirectionZoomAmine(false)
    // new window.google.maps.DirectionsRenderer().setOptions({
    //   preserveViewport: true
    // })
  }

  const handleMarkerPositionChange = (event, type) => {
    let newLat = event?.latLng.lat();
    let newLng = event?.latLng.lng();
    deactivateDirectionZoomAmine()
    // Object.keys(pickupLocation).length > 0 && Object.keys(dropOffLocation).length > 0 && setDirectionZoomAmine(false)
    if (event) {
      switch (type) {
        case "pickup":
          reverseGeoCode(newLat, newLng, type);
          setPickupLocation({
            lat: newLat,
            lng: newLng,
          });
          setPickupLat(newLat)
          setPickupLng(newLng)
        break;
  
        case "dropoff":
          reverseGeoCode(newLat, newLng, type);
          setDropOffLocation({
            lat: newLat,
            lng: newLng,
          });
          setDropOffLat(newLat)
          setDropOffLng(newLng)
        break;
      }
    }
  }
  /** fetch load and vehicle types */
  useEffect(() => {
    getLoadTypes();
    getVehicleTypes();
  }, [])

  /** handle pickup address change */
  useEffect(() => {
    console.log("converted Pickup Addresss: ", pickupAddConverted);
    console.log("pickup coordinates: ", pickupLat, pickupLng);
    if (pickupAddConverted) {
      pickupEl.current.update(pickupAddConverted);
      pickupEl.current.focus()
      pickupEl.current.blur()
    }
  }, [pickupAddConverted])
  /** handle pickup address change */
  useEffect(() => {
    console.log("converted Dropoff Addresss: ", dropoffAddConverted);
    console.log("dropoff coordinates: ", dropOffLat, dropOffLng);
    if (dropoffAddConverted) {
      dropoffEl.current.update(dropoffAddConverted);
      dropoffEl.current.focus()
      dropoffEl.current.blur()
    }
  }, [dropoffAddConverted])

  useEffect(() => {
    if (isInitialMount.current) {
       isInitialMount.current = false;
    } else {
      Object.keys(pickupLocation).length > 0 && setCenter(pickupLocation) 

    }
  }, [pickupLocation]);
  useEffect(() => {
    if (isInitialMount.current) {
       isInitialMount.current = false;
    } else {
      Object.keys(dropOffLocation).length > 0 && setCenter(dropOffLocation)
    }
  }, [dropOffLocation]);
  // validate load-weight field on unit change
  useEffect(() => {
    if (initialMountRef1.current) {
      initialMountRef1.current = false;
    } else {
      validate({
        id: "booking__load-weight",
        value: loadWeight,
        name: "loadWeight"
      })
    }
  }, [weightUnit]);
  
  return (
    <div className="booking-container">
      <Breadcrumb text="Book a trailer" islast={true} />
      <div className="booking">
        <div className="pickup">
          {/* <label className="label">{t("Pickup location")}</label> */}
          <label
            className={`label ${
              showPickupLabel === 0
                ? "invisible"
                : ispickuplocation || showPickupLabel === 1
                ? "fadeIn"
                : "fadeOut"
            }`}
          >
            {t("Pickup location")}
          </label>
          {/* Suggest locations while typing  */}
          <Geosuggest
            tabIndex={1}
            ref={pickupEl}
            placeholder={t("Pickup location (From)")}
            highlightMatch={true}
            style={{
              input: {
                // height: "15px",
                // width: "400px",
                // fontFamily: "Roboto",
                // borderRadius: "6px",
                // backgroundColor: "#ffffff",
                // border: "none",
                // outline: "none",
                // color: "#323745",
                // fontSize: "16px",
                // padding: "15px",
                border: pickupError ? "1px solid #fa0303" : "1px solid #ffffff",
              },
              suggests: {
                listStyleType: "none",
                color: "#323745",
                backgroundColor: "#ffffff",
                display: showPickupSuggests ? "block" : "none",
                borderRadius: "6px",
                marginTop: "0px",
                marginBottom: "0px",
                paddingLeft: "0px",
              },
              suggestItem: {
                padding: "10px 10px",
                border: "3px",
                cursor: "pointer",
              },
            }}
            onBlur={(e) => {
              setShowPickupSuggests(false);
              setShowPickupLabel(-1);
            }}
            onFocus={() => {
              response && deactivateDirectionZoomAmine()
              setShowPickupSuggests(true);
              setShowPickupLabel(1);
            }}
            onChange={(e) => {
              setShowPickupSuggests(true);
              changeValue(e, "pickup");
              setispickuplocation(e);
            }}
            onSuggestSelect={(suggest) => {
              if (suggest && suggest.location) {
                setShowPickupSuggests(false);
                setPickupLocation({
                  lat: suggest.location.lat,
                  lng: suggest.location.lng,
                });
                setPickupLat(suggest.location.lat);
                setPickupLng(suggest.location.lng);
                if (
                  suggest.location.lat === dropOffLat &&
                  suggest.location.lng === dropOffLng
                ) {
                  setPickupError(
                    "Pickup location and drop-off location has to be different"
                  );
                } else {
                  dropOffError ===
                    "Pickup location and drop-off location has to be different" &&
                    setDropOffError("");
                  setPickupError("");
                }
              }
            }}
          />
          {pickupError && (
            <p className="error-msg">{t(pickupError) + t(".")}</p>
          )}
        </div>
        <div className="map">
          <GoogleMap
            tabIndex={-1}
            mapContainerStyle={containerStyle}
            // options={Style()}
            // lat="9.9854"
            // lng="76.579"
            zoom={6}
            options={{
              center: center
            }}
            // onLoad={onLoad}
            onUnmount={onUnmount}
          >
            <Marker onLoad={markerOnLoad} position={pickupLocation} draggable={true} onDragEnd={(event) => handleMarkerPositionChange(event, "pickup")} />
            <Marker onLoad={markerOnLoad} position={dropOffLocation} draggable={true} onDragEnd={(event) => handleMarkerPositionChange(event, "dropoff")} />
            {pickupLocation !== "" && dropOffLocation !== "" && (
              <DirectionsService
                options={{
                  destination: dropOffLocation,
                  origin: pickupLocation,
                  travelMode: "DRIVING",
                }}
                callback={directionsCallback}
                onUnmount={(directionsService) => {
                  console.log(
                    "DirectionsService onUnmount directionsService: ",
                    directionsService
                  );
                }}
              />
            )}
            {response && (
              <DirectionsRenderer
                ref={DirectionsRendererEL}
                options={{
                  directions: response,
                  suppressMarkers: true,
                  preserveViewport: !directionZoomAmine
                }}
              />
            )}
          </GoogleMap>
        </div>
        <div className="dropoff">
          {/* <label className="label">{t("Drop-off location")}</label> */}
          <label
            className={`label ${
              showDropOffLabel === 0
                ? "invisible"
                : isdropofflocation || showDropOffLabel === 1
                ? "fadeIn"
                : "fadeOut"
            }`}
          >
            {t("Drop-off location")}
          </label>
          <Geosuggest
            tabIndex={2}
            ref={dropoffEl}
            placeholder={t("Drop-off location (To)")}
            highlightMatch={true}
            style={{
              input: {
                // height: "15px",
                // width: "400px",
                // fontFamily: "Roboto",
                // borderRadius: "6px",
                // backgroundColor: "#ffffff",
                // border: "none",
                // outline: "none",
                // color: "#323745",
                // fontSize: "16px",
                // padding: "15px",
                border: dropOffError
                  ? "1px solid #fa0303"
                  : "1px solid #ffffff",
              },
              suggests: {
                listStyleType: "none",
                color: "#323745",
                backgroundColor: "#ffffff",
                display: showDropOffSuggests ? "block" : "none",
                marginTop: "0px",
                marginBottom: "0px",
                paddingLeft: "5px",
              },
              suggestItem: {
                backgroundColor: "#ffffff",
                padding: "10px 10px",
                cursor: "pointer",
              },
            }}
            onBlur={(e) => {
              setShowDropOffSuggests(false);
              setShowDropOffLabel(-1);
            }}
            onFocus={() => {
              response && deactivateDirectionZoomAmine()
              setShowDropOffSuggests(true);
              setShowDropOffLabel(1);
            }}
            onChange={(e) => {
              setShowDropOffSuggests(true);
              changeValue(e, "dropoff");
              setisdropofflocation(e);
            }}
            onSuggestSelect={(suggest) => {
              if (suggest && suggest.location) {
                setShowDropOffSuggests(false);
                setDropOffLocation({
                  lat: suggest.location.lat,
                  lng: suggest.location.lng,
                });
                setDropOffLat(suggest.location.lat);
                setDropOffLng(suggest.location.lng);
                if (
                  suggest.location.lat === pickupLat &&
                  suggest.location.lng === pickupLng
                ) {
                  setDropOffError(
                    "Pickup location and drop-off location has to be different"
                  );
                } else {
                  pickupError ===
                    "Pickup location and drop-off location has to be different" &&
                    setPickupError("");
                  setDropOffError("");
                }
              }
            }}
          />
          {dropOffError && <p className="error-msg">{t(dropOffError) + "."}</p>}
        </div>
        <div
          className="datetime-picker"
          style={{ display: "flex", flexDirection: "column" }}
        >
          {/* <label className="label">{t("Date of pickup")}</label> */}
          <label
            className={`label ${
              showDateLabel === 0
                ? "invisible"
                : isdate || showDateLabel === 1
                ? "fadeIn"
                : "fadeOut"
            }`}
          >
            {t("Date of pickup")}
          </label>
          <DatePicker
            tabIndex={3}
            // customInput = {<InputField placeholder = "Date of pickup" text = {date} errorField = {dateError}/>}
            placeholderText={t("Date of pickup")}
            selected={date}
            onChange={(value) => {
              console.log("on date change");
              changeValue(value, "date");
              setisdate(formatDateTime(value, "DD MMM YYYY, hh:mm a"));
            }}
            onBlur={(e) => {
              setShowDateLabel(-1);
            }}
            onFocus={() => {
              console.log("on focus");
              deactivateDirectionZoomAmine()
              setShowDateLabel(1);
            }}
            showTimeSelect
            timeFormat="HH:mm"
            timeIntervals={15}
            timeCaption="Time"
            dateFormat="MMM dd yyyy, hh:mm a"
            className="react-datepicker-ignore-onclickoutside"
          />
          <img src={Images.calendar_icon} className="calender_icon" />
          {dateError && <p className="error-msg">{t(dateError) + "."}</p>}
          {error && <p className="error-msg">{error}</p>}
        </div>
        <div className="load-weight">
          <InputField
            tabIndex={4}
            id={"booking__load-weight"}
            inputData="dropdown"
            label={t("Weight of the load")+ "*"}
            placeholder={t("Weight of the load")}
            name={"load-weight"}
            validate={validate}
            errorField={errors.loadWeightFieldError}
            data={weightOptions}
            setSelected={setWeightUnit}
            type="number"
            min={"0"}
            spin={false}
          />
        </div>
        <div className="load-type dropdown-input">
          <Dropdown 
            tabIndex={5}
            id={"booking__load-type"}
            options={loadTypeOptions}
            searchable={false}
            placeholder={t("Type of load")}
            label={t("Type of load")+ "*"}
            errorField={errors.loadTypeFieldError}
            name={"load-type"}
            validate={validate}
            setSelected={setLoadType}
            selected={loadType}
          />
        </div>
        <div className="vehicle-type dropdown-input">
          <Dropdown 
            tabIndex={7}
            id={"booking__vehicle-type"}
            options={vehicleTypeOptions}
            searchable={false}
            placeholder={t("Type of vehicle")}
            label={t("Type of vehicle")}
            name={"vehicle-type"}
            // validate={validate}
            setSelected={setVehicleType}
            selected={vehicleType}
          />
        </div>
        <div className="receiver-email">
          <InputField
            tabIndex={8}
            id={"booking__receiver-email"}
            label={t("Receiver's email ID")}
            placeholder={t("Receiver's email ID")}
            validate={validate}
            errorField={errors.receiverEmailFieldError}
            inputData="email"
          />
        </div>
        <div className="pickup-contact">
          <InputField
            tabIndex={9}
            id={"booking__pickup-contact"}
            label={t("Pickup contact")+ "*"}
            placeholder={t("Pickup contact")}
            validate={validate}
            errorField={errors.pickupFieldError}
            inputData="contact"
            maxLength={9}
          />
        </div>
        <div className="dropoff-contact">
          <InputField
            tabIndex={10}
            id={"booking__dropoff-contact"}
            label={t("Drop-off contact")+"*"}
            placeholder={t("Drop-off contact")}
            validate={validate}
            errorField={errors.dropoffFieldError}
            inputData="contact"
            maxLength={9}
          />
        </div>

        <div className="button">
          <Button
            text="Book"
            isdisabled={
              pickupError || dropOffError || dateError || disableButton || errors.pickupFieldError || errors.dropoffFieldError || errors.loadTypeFieldError || errors.loadWeightFieldError || errors.receiverEmailFieldError
            }
            onClickBtn={() => {
              deactivateDirectionZoomAmine()
              onClickBtn()
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Booking;
