import React from 'react'
import './CheckTC.css'
import { useTranslation } from 'react-i18next';

const CheckTC = ({setChecked}) => {
  const { t } = useTranslation();

  const handleChange = (e) => {
    setChecked(e.target.checked)
  }
  
  return (
    <label class="check-tc__container">
      {t(`I have read and agree to `)}
      <a href="/terms-and-conditions" target="_blank" className="link">
        {t("Terms and conditions")}
      </a>
      {t(` and `)}
      <a href="/privacy-policy" target="_blank" className="link">
        {t("Privacy policy")}
      </a>
      <input type="checkbox" onChange={handleChange}/>
      <span class="checkmark"></span>
    </label>
  )
}

export default CheckTC