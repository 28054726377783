import React from "react";
import "./SquareButton.css";
import { useTranslation } from "react-i18next";

const SquareButton = ({ text, onClickBtn, isdisabled, type }) => {
  const { t } = useTranslation();
  return (
    <button
      className={`square-button ${type ?? ""}`}
      onClick={onClickBtn}
      disabled={isdisabled}
    >
      {t(text)}
    </button>
  );
};
export default SquareButton;
