const {useEffect, useState} = require('react');
// counter custom hook
function UseCounter(sec) {
  const [seconds, setSeconds] = useState(1000);

  useEffect(() => {
    if (seconds > 0) {
      setTimeout(() => setSeconds(seconds - 1), sec);
    } else {
      setSeconds(0);
    }
  });

  return [seconds];
}

export default UseCounter;