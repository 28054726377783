import React, { useState, useEffect, useRef } from "react";
import "./InputField.css";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import Images from "../../assets";

const SelectStyle = {
  menuList: (base, styles) => {
    return {
      ...base,
      backgroundColor: "#fff",
      color: "#323745"
    };
  },
  option: (base, styles) => {
    return {
      ...base,
      color: "#323745",
    };
  },
  dropdownIndicator: (base, styles) => {
    return {
      ...base,
      color: "#323745",
      boxShadow: "inset 0 2px 4px -1px #e9e9e9",
      borderRadius: "0 6px 6px 0",

    };
  },
}

const TextField = ({
  placeholder,
  text,
  validate,
  errorField,
  id = 0,
  inputData = "text",
  data,
  name = "",
  type = "text",
  label = "",
  maxLength = 20,
  min,
  max,
  setSelected,
  spin = true,
  regex,
  defaultValue,
  tabIndex
}) => {
  const [inputText, setinputText] = useState("");
  const { t } = useTranslation();
  const [showLabel, setshowLabel] = useState(0);
  const initalRenderRef = useRef(true)
  // console.log(errorField, ": ", t(errorField));

  const handleSelect = (option) => {
    setSelected(option)
  }

  // set the field with default value
  useEffect(() => {
    if (defaultValue) {
      console.log("defaultValue form inp comp >> ", defaultValue);
      regex ? setinputText(defaultValue.replace(regex, "")) : setinputText(defaultValue)
      setshowLabel(1)
      // validate({
      //   id: id,
      //   value: defaultValue,
      //   name: name,
      // });
    }
  }, [defaultValue])

  useEffect(() => {
    if (initalRenderRef.current) {
      initalRenderRef.current = false
    } else {
      validate({
        id: id,
        value: inputText,
        name: name,
      });
    }
  }, [inputText])
  
  return (
    <div className="input-field">
      <label
        className={`label ${
          showLabel === 0
            ? "invisible"
            : inputText || showLabel === 1
            ? "fadeIn"
            : "fadeOut"
        }`}
      >
        {label ? t(label) : t(placeholder)}
      </label>
      <input
        tabIndex={tabIndex}
        className={`${!spin && "hide-spin"} ${
          inputData === "contact"
            ? `contact-box ${errorField ? "error" : ""}`
            : inputData === "dropdown" 
            ? `dropdown-box ${errorField ? "error" : ""}`
            : `input-box ${errorField ? "error" : ""}`
        }`}
        type={type}
        name={name}
        min={min}
        max={max}
        placeholder={t(placeholder)}
        value={inputText}
        maxLength={maxLength}
        onChange={(e) => {
          // validate({
          //   id: id,
          //   value: e.target.value.trim(),
          //   name: e.target.name,
          // });
          regex ? setinputText(e.target.value.replace(regex, "")) : setinputText(e.target.value)
        }}
        onFocus={() => {
          setshowLabel(1);
        }}
        onBlur={() => {
          setshowLabel(-1);
        }}
      />
      {inputData === "contact" && (
        <div className="flag-container">
          <img className="flag-img" src={Images.flag} /> <p className="flag-code">+966</p>
        </div>
      )}
      {inputData === "dropdown" && (
        <Select 
          classNamePrefix={"input-dropdown"}
          className="input-dropdown-container" 
          styles={SelectStyle}
          options={data}
          components={{
            IndicatorSeparator: () => null
          }}
          defaultValue={data[0]} 
          isSearchable={false}
          theme={(theme) => ({
            ...theme,
            borderRadius: 0,
            colors: {
              ...theme.colors,
              primary25: '#f2f6f9',
              primary: '#eceff1',
            },
          })}
          onChange={handleSelect}
        />
      )}
      {errorField && <span className="error-field">{t(errorField) + "."}</span>}
    </div>
  );
};

export default TextField;
