import React, { useState, useEffect } from "react";
import "./TrackTrailer.css";
import BreadCrumb from "../../components/Breadcrumb";
import { getData } from "../../api";
import { useTranslation } from "react-i18next";
import {
  GoogleMap,
  Marker,
  DirectionsService,
  DirectionsRenderer,
  useGoogleMap,
} from "@react-google-maps/api";
import Images from "../../assets";
import formatDateTime from "../../utils/formatDateTime";
import mapStyle from "../../constants/mapStyle";

const TrackTrailer = (props) => {
  const ID = props.match.params.id;

  const { t } = useTranslation();

  const [loading, setloading] = useState(false);
  const [bookingDetails, setbookingDetails] = useState({});

  // executes before page render
  // call the function which make api call to get booking data                                                 
  useEffect(() => {
    const getBooking = async () => {
      setloading(true);
      let response = await getData(`/bookings/${ID}`);
      setloading(false);
      if (response && response.data) {
        setbookingDetails(response.data);
      }
    };
    getBooking();
  }, []);

  const [responseData, setResponseData] = useState();
  const containerStyle = {
    width: "100%",
    height: "600px",
    boxShadow: "0 2px 4px 0 #E9E9E9",
  };

  // set fields of map api with fetched booking details
  const pickupLocation = {
    lat: bookingDetails.pickup_location?.coordinates[0],
    lng: bookingDetails.pickup_location?.coordinates[1],
  };
  const dropOffLocation = {
    lat: bookingDetails.drop_location?.coordinates[0],
    lng: bookingDetails.drop_location?.coordinates[1],
  };

  const lastKnownLocation = {
    lat: bookingDetails.journey?.trailer?.last_known_location?.coordinates[0],
    lng: bookingDetails.journey?.trailer?.last_known_location?.coordinates[1],
  };
  const center = {
    lat: 9.9894,
    lng: 76.579,
  };
  const bound = {
    lat: 9.9312,
    lng: 76.2673,
  };
  const [map, setMap] = React.useState(null);

  // functions to setup google map render
  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds(bound, center);
    map.fitBounds(bounds);
    map.mapTypes.set("styled_map", mapStyle);
    map.setMapTypeId("styled_map");
    setMap(map);
  }, []);

  const directionsCallback = (response) => {
    if (!responseData) {
      if (response !== null) {
        if (response.status === "OK") {
          setResponseData(response);
        } else {
          console.log("response: ", response);
        }
      }
    }
  };

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  return (
    <div className="track-trailer">
      <div className="breadcrumbs">
        <BreadCrumb text="History" route="/history" islast={false} />
        <BreadCrumb
          text="Journey Details"
          route={`/history/${ID}/journey-details`}
        />
        <BreadCrumb text="Track Trailer" islast={true} />
      </div>
      <div className="map-container">
        <p>
          {t("Updated at")}{" "}
          {formatDateTime(bookingDetails.journey?.updatedAt, "hh:mm a")}
        </p>
        <GoogleMap
          mapContainerStyle={containerStyle}
          lat="9.9854"
          lng="76.579"
          zoom={8}
          onLoad={onLoad}
          onUnmount={onUnmount}
          // options={{ mapStyle }}
        >
          {/* <Marker position={pickupLocation} />
          <Marker position={dropOffLocation} /> */}
          {lastKnownLocation.lat && lastKnownLocation.lng && (
            <Marker position={lastKnownLocation} icon={Images.truck_marker} />
          )}
          {pickupLocation !== "" && dropOffLocation !== "" && (
            <DirectionsService
              options={{
                destination: dropOffLocation,
                origin: pickupLocation,
                travelMode: "DRIVING",
              }}
              callback={directionsCallback}
              onUnmount={(directionsService) => {
                console.log(
                  "DirectionsService onUnmount directionsService: ",
                  directionsService
                );
              }}
            />
          )}
          {responseData && (
            <DirectionsRenderer
              options={{
                directions: responseData,
              }}
            />
          )}
        </GoogleMap>
      </div>
    </div>
  );
};

export default TrackTrailer;
