import React, { useState, useEffect } from "react";
import "./Login.css";
import { useHistory } from "react-router-dom";
import Images from "../../assets";
import FrontPages from "../../components/FrontPages";
import InputField from "../../components/InputField";
import PasswordField from "../../components/Password";
import Button from "../../components/SquareButton";
import IconButton from "../../components/IconButton";

import {
  SuccessAlert,
  FailureAlert,
  SimpleAlert,
} from "../../utils/alertTemplate";
import "../../constants/ConfirmAlert.css";
import { postData } from "../../api/NoAuthApi";
import { MandatoryFieldCheck, ValidatePhoneNo } from "../../utils/Validation";
import { useTranslation } from "react-i18next";
import imageSelector from "../../utils/LangImages";
import { signInWithGoogle } from "../../firebase";
import { getSignupStorage, removeSignupStorage } from "../../utils/localStorage";

const Login = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const [signinData, setSigninData] = useState({
    phone: "",
    password: "",
  });
  const [errors, seterrors] = useState({});

  // return true if phone and password fields are empty else false
  const MandatoryFieldValidation = () => {
    let phone = MandatoryFieldCheck(signinData.phone);
    let password = MandatoryFieldCheck(signinData.password);

    seterrors({
      phone: phone,
      password: password,
    });
    return phone == "" && password == "" ? true : false;
  };

  // execute when value of the input field changes
  const updateSigninData = (input_details) => {
    let error = "";
    setSigninData({
      ...signinData,
      [input_details.name]: input_details.value,
    });
    switch (input_details.id) {
      // validation for phone number field
      case 0: {
        error = MandatoryFieldCheck(input_details.value) || ValidatePhoneNo(input_details.value);
        seterrors({ ...errors, phone: error });
        break;
      }
      // validation for password field
      case 1: {
        error = MandatoryFieldCheck(input_details.value);
        seterrors({ ...errors, password: error });
        break;
      }
    }
  };
  // console.log(signinData);

  // executes on click submit button
  // make api call to login if input field validation is success
  const handleSubmit = async (event) => {
    // event.preventDefault();
    if (MandatoryFieldValidation()) {
      setLoading(true);
      let response = await postData("/auth/users/login", {
        phone: "966" + signinData.phone,
        password: signinData.password,
      });

      setLoading(false);

      if (response && response.status && response.status === 200) {
        localStorage.setItem("login_phone", signinData.phone);

        localStorage.setItem("access_token", response.data?.access_token);
        window.location.assign("/");
      } else if (response && response.status && response.status === 400) {
        SimpleAlert(t("Failed"), t("Something went wrong"));
      } else if (response && response.status && response.status === 403) {
        if (response.message.error === "Verification Pending") {
          SimpleAlert(t("Failed"), t(response.message.error));
          // history.push("/verify-otp");
          history.push("/verify-email", {phone: signinData.phone});
        }
      }
    }
  };

  // remove sing up data from local storage if it is available
  useEffect(() => {
    const data = getSignupStorage();
    if (data?.isDataAvailable) { 
      removeSignupStorage();
    }
  }, [])

  return (
    <div
      className="login"
      // style={{
      //   background: `url(${Images.trailer_background})`,
      //   backgroundPosition: "top 0vh right 0vw",
      //   backgroundRepeat: "no-repeat",
      // }}
    >
      <img src={Images.shade} className="shade" />
      <div className="logo">
        {/* <img className="olly_logo" src={Images.olly_logo} /> */}
        <img src={imageSelector("logo")} />
      </div>
      <div className="content-section">
        <div className="content-details">
          <FrontPages
            subHeading={t("If you are new,")}
            linkText="create an account"
            link="/sign-up"
            state={signinData.phone}
          />
          <form className="input-area" onSubmit={(e) => {
            e.preventDefault();
            !errors.phone && !errors.password && handleSubmit(e)
          }}>
            <div className="input-element">
              <InputField
                placeholder="5xxxxxxxx"
                label="Mobile number"
                maxLength={9}
                id={0}
                type="text"
                name="phone"
                inputData="contact"
                text={signinData.phone}
                errorField={errors.phone}
                validate={updateSigninData}
              />
            </div>
            {/* <input type="password" style={{ display: "none" }} /> */}
            <div className="input-element">
              <PasswordField
                placeholder="Password"
                id={1}
                name="password"
                text={signinData.password}
                errorField={errors.password}
                validate={updateSigninData}
              />
            </div>
            <div className="input-element-forgot-password">
              <div
                className="forgot-password-text"
                onClick={() => {
                  history.push("/reset-password-otp");
                }}
              >
                {t("Forgot password?")}
              </div>
            </div>
            <div className="input-element">
              <Button text="Log in" />
            </div>
          </form>
          <div className="input-element">
            <IconButton 
              text={"Sign in with Google"}
              onClickBtn={signInWithGoogle}
              iconSrc={require("../../assets/google-icon.svg")}
            />
          </div>
        </div>
        <div className="truck-image">
          <img src={Images.truck} />
        </div>
      </div>
    </div>
  );
};

export default Login;
