import React, { useState, useEffect } from "react";
import "./ResetPassword.css";
import { useHistory, useLocation } from "react-router-dom";
import Images from "../../assets";
import FrontPages from "../../components/FrontPages";
import InputField from "../../components/InputField";
import Button from "../../components/SquareButton";
import { postData, putData } from "../../api/NoAuthApi";
import {
  SimpleAlert,
  FailureAlert,
  SuccessAlert,
} from "../../utils/alertTemplate";
import { confirmAlert } from "react-confirm-alert";
import "../../constants/ConfirmAlert.css";
import PasswordField from "../../components/Password";
import { ValidatePassword } from "../../utils/Validation";
import { useTranslation } from "react-i18next";
import imageSelector from "../../utils/LangImages";

const Login = () => {
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  // const [isverify, setisverify] = useState(false);
  // const [OTP, setOTP] = useState({
  //   ssn1: "",
  //   ssn2: "",
  //   ssn3: "",
  //   ssn4: "",
  // });
  // let otp, userName;
  let otp = sessionStorage.getItem("resetPassword_OTP");
  let userName = localStorage.getItem("login_phone");

  const [errors, seterrors] = useState({});
  // const [userName, setuserName] = useState();
  const [passwords, setpasswords] = useState({
    password: "",
    confirmPassword: "",
  });
  // const sendOTP = async () => {
  //   let response = await postData(`/users/forgot_password`, {
  //     phone: userName,
  //   });
  //   console.log(response);
  //   if (response && response.data) {
  //     setisverify(true);
  //     SimpleAlert(response.data);
  //   } else if (response && response.status && response.status === 400) {
  //     confirmAlert({
  //       title: "Failed",
  //       message: response.message?.error ?? "Something went wrong",
  //       buttons: [
  //         {
  //           label: "Close",
  //           onClick: () => {
  //             return;
  //           },
  //         },
  //       ],
  //     });
  //   } else {
  //     confirmAlert({
  //       title: "Failed",
  //       message: response.message?.error ?? "Something went wrong",
  //       buttons: [
  //         {
  //           label: "Close",
  //           onClick: () => {
  //             return;
  //           },
  //         },
  //       ],
  //     });
  //   }
  // };
  // const reSend = async () => {
  //   let response = await postData(`/users/forgot_password`, {
  //     phone: userName,
  //   });
  //   if (response && response.data) {
  //     SimpleAlert(response.data);
  //   } else if (response && response.status && response.status === 400) {
  //     confirmAlert({
  //       title: "Failed",
  //       message: response.message?.error ?? "Something went wrong",
  //       buttons: [
  //         {
  //           label: "Close",
  //           onClick: () => {
  //             return;
  //           },
  //         },
  //       ],
  //     });
  //   } else {
  //     confirmAlert({
  //       title: "Failed",
  //       message: response.message?.error ?? "Something went wrong",
  //       buttons: [
  //         {
  //           label: "Close",
  //           onClick: () => {
  //             return;
  //           },
  //         },
  //       ],
  //     });
  //   }
  // };
  const MandatoryFieldValidation = () => {
    let password = ValidatePassword(passwords.password);
    let confirmPassword = ValidatePassword(passwords.confirmPassword);

    seterrors({
      password: password,
      confirmPassword: confirmPassword,
    });
    return password == "" && confirmPassword == "" ? true : false;
  };
  const resetPassword = async () => {
    if (MandatoryFieldValidation()) {
      if (passwords.password !== passwords.confirmPassword) {
        // confirmAlert({
        //   title: "Error",
        //   message: "Passwords not matching",
        //   buttons: [
        //     {
        //       label: "Close",
        //       onClick: () => {
        //         return;
        //       },
        //     },
        //   ],
        // });
        SimpleAlert(t("Error"), t("Passwords are not matching"));
      } else {
        // let otp = OTP.ssn1 + OTP.ssn2 + OTP.ssn3 + OTP.ssn4;
        let response = await postData(`/users/reset_password`, {
          phone: "966" + userName,
          otp: otp,
          password: passwords.confirmPassword,
        });
        console.log(response);
        if (response && response.data) {
          SuccessAlert(t("Password reset successful"), () => {
            history.push("/");
          });
        } else if (response && response.status && response.status === 400) {
          FailureAlert(
            t("Action Failed"),
            `${response.message?.error ?? t("Something went wrong")}`
          );
        } else {
          FailureAlert(t("Action Failed"));
        }
      }
    }
  };
  // const handleChange = (e) => {
  //   const { maxLength, value, name } = e.target;
  //   const [fieldName, fieldIndex] = name.split("-");
  //   if (value.length >= maxLength) {
  //     if (parseInt(fieldIndex, 10) < 4) {
  //       const nextSibling = document.querySelector(
  //         `input[name=ssn-${parseInt(fieldIndex, 10) + 1}]`
  //       );
  //       if (nextSibling !== null) {
  //         nextSibling.focus();
  //       }
  //     }
  //   }
  //   setOTP({
  //     ...OTP,
  //     [`ssn${fieldIndex}`]: value,
  //   });
  // };
  const validate = (inputText) => {
    let error = "";
    switch (inputText.id) {
      // case 0: {
      //   setuserName(inputText.value);
      //   seterrors({ ...errors, userName: error });
      //   break;
      // }
      case 1: {
        error = ValidatePassword(inputText.value);
        setpasswords({ ...passwords, password: inputText.value });
        seterrors({ ...errors, password: error });
        break;
      }
      case 2: {
        error = ValidatePassword(inputText.value);
        setpasswords({ ...passwords, confirmPassword: inputText.value });
        seterrors({ ...errors, confirmPassword: error });
      }
    }
  };
  // const keyPressHandler = (key) => {
  //   if (key.charCode === 13 || key.keyCode === 13) {
  //     resetPassword();
  //   }
  // };
  // useEffect(() => {
  //   document.addEventListener("keypress", keyPressHandler);
  //   // Remove event listeners on cleanup
  //   return () => {
  //     document.removeEventListener("keypress", keyPressHandler);
  //   };
  // }, [passwords]);
  return (
    <div
      className="reset-password"
      // style={{
      //   background: `url(${Images.trailer_background})`,
      //   backgroundPosition: "top 0vh right 0vw",
      //   backgroundRepeat: "no-repeat",
      // }}
    >
      <img src={Images.shade} className="shade" />
      <div className="logo">
        {/* <img className="olly_logo" src={Images.olly_logo} /> */}
        <img src={imageSelector("logo")} />
      </div>
      <div className="content-section">
        <div className="content-details">
          <FrontPages subHeading={t("Create a new password")} />
          <div className="input-area">
            {/* <div className="input-element">
              <InputField
                placeholder="Phone number or Email"
                id={0}
                validate={validate}
                errorField={errors.userName}
              />
            </div> */}

            {/* {isverify && (
              <div> */}
            {/* <div className="input-element">
              <p>
                {"Check your mail for the OTP."}
                <button onClick={reSend}>Resend</button>
              </p>
            </div> */}
            <div className="input-element">
              <PasswordField
                placeholder="Password"
                id={1}
                isConfirmed={true}
                errorField={errors.password}
                validate={validate}
              />
            </div>
            <div className="input-element">
              <PasswordField
                placeholder="Confirm password"
                id={2}
                isConfirmed={true}
                validate={validate}
                errorField={errors.confirmPassword}
              />
            </div>
            {/* <div className="input-element-otp">
              <input
                type="text"
                name="ssn-1"
                maxLength="1"
                onChange={handleChange}
              />
              <input
                type="text"
                name="ssn-2"
                maxLength="1"
                onChange={handleChange}
              />
              <input
                type="text"
                name="ssn-3"
                maxLength="1"
                onChange={handleChange}
              />
              <input
                type="text"
                name="ssn-4"
                maxLength="1"
                onChange={handleChange}
              />
            </div> */}
            <div className="input-element" style={{ marginTop: "40px" }}>
              <Button text="Reset password" onClickBtn={resetPassword} />
            </div>
            {/* </div>
            )} */}
            {/* {!isverify && (
              <div className="input-element" style={{ marginTop: "40px" }}>
                <Button text="Send OTP" onClickBtn={sendOTP} />
              </div>
            )} */}
          </div>
        </div>
        <div className="truck-image">
          <img src={Images.truck} />
        </div>
      </div>
    </div>
  );
};

export default Login;
