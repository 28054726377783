import React, { useEffect, useState } from "react";
import "./LandingSection.css";
import Header from "../../components/Header";
import Routes from "../../routes/Routes";
import MobileHeader from "../../components/MobileHeader";
import SideNav from "../../components/SideNav";
import { useLocation } from "react-router-dom";
import Footer from "../../components/Footer";

// loads when the user is authenicated 
// this component is direct the page to  no authentication route
const LandingSection = () => {
  // const [windowWdith, setwindowWdith] = useState(window.outerWidth);
  const location = useLocation();

  const [ishideHeader, setishideHeader] = useState(false);
  const [ishideFooter, setishideFooter] = useState(true);

  // executes when page load
  // hide the header for terms-and-conditions, privacy-policy pages 
  useEffect(() => {
    switch (location.pathname) {
      case "/terms-and-conditions":
      case "/privacy-policy":
        setishideHeader(true);
        break;
      default:
        setishideHeader(false);
        break;
    }
  }, []);

  // executes when page load
  // hide the footer for all the pages other than contact-us
  useEffect(() => {
    switch (location.pathname) {
      case "/contact-us":
        setishideFooter(false);
        break;
      default:
        setishideFooter(true);
        break;
    }
  }, [location.pathname]);

  // const handleResize = () => {
  //   setwindowWdith(window.outerWidth);
  // };

  // useEffect(() => {
  //   window.addEventListener("resize", handleResize);
  //   return () => window.removeEventListener("resize", handleResize);
  // }, []);
  return (
    <div className="landing-section">
      {!ishideHeader && (
        <>
          {/* {windowWdith > 680 ? <Header /> : <SideNav />} */}
          <Header />
          <SideNav />
          <MobileHeader />
        </>
      )}
      <div className={`main ${!ishideFooter ? "isfooter" : ""}`}>
        <Routes />
      </div>
      {!ishideFooter && <Footer />}
    </div>
  );
};
export default LandingSection;
