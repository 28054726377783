import instance from "./instance";
import "../constants/ConfirmAlert.css";
import { SimpleAlert } from "../utils/alertTemplate";
import languageSelector from "../utils/LanguagesForNetworkError";

// funtion which set header for api requests
const REQUEST_HEADER = (access_token, lang) => {
  return {
    headers: {
      "x-access-token": access_token,
      "accept-language": lang, // "ar","en","ur"
      //   "x-id-token": id_token,
    },
  };
};
// const checkTokenExpiry = async () => {
//   const tokenGeneratedTime = getTokenGeneratedTime();
//   if (tokenGeneratedTime + 20 * 60 * 1000 <= new Date().getTime()) {
//     try {
//       const { access_token, id_token, refresh_token } = await refreshToken();

//       localStorage.setItem("access_token", access_token);
//       localStorage.setItem("id_token", id_token);
//       localStorage.setItem("refresh_token", refresh_token);
//       localStorage.setItem("token_gen_time", new Date().getTime());
//     } catch (err) {
//       console.error(err);
//     }
//   }
// };

// get access token from local storage
const getAccessToken = () => {
  return localStorage.getItem("access_token");
};
// get selected language from local storage
const getLanguage = () => {
  return localStorage.getItem("Olly_lang")
    ? localStorage.getItem("Olly_lang")
    : "en";
};

// const getIDToken = () => {
//   return localStorage.getItem("id_token");
// };

// const getRefreshToken = () => {
//   return localStorage.getItem("refresh_token");
// };

// const getTokenGeneratedTime = () => {
//   return Number.parseInt(localStorage.getItem("token_gen_time"), 10);
// };

// const getLoginEmail = () => {
//   return localStorage.getItem("login_email");
// };

// function to handle errors, handle various http response status
const handleError = (error) => {
  if (error && error.response && error.response.status) {
    console.error(error);

    switch (error.response.status) {
      case 400:
        let err = {
          status: error.response.status ?? "",
          message: error.response.data ?? {},
        };
        return err;
      case 401:
        alerter(
          languageSelector("Unauthorized"),
          error.response.data.error,
          true
        );
        logout();
        return null;
      case 403:
        let err_msg = {
          status: error.response.status ?? "",
          message: error.response.data ?? {},
        };
        return err_msg;
      case 404:
        alerter(
          languageSelector("Not Found"),
          languageSelector("The resource was not found.")
        );
        return null;

      case 500:
        let errr = {
          status: error.response.status ?? "",
          message: error.response.data ?? {},
        };
        return errr;

      case 502:
        alerter(
          languageSelector("Server Error"),
          languageSelector("The server couldn't complete the request.")
        );
        return null;

      default:
        return null;
    }
  } else {
    alerter(
      languageSelector("Network Error"),
      languageSelector("Couldn't reach the server or no response received.")
    );
  }
  return null;
};

// clear access tocken and phone number from local storage
const logout = () => {
  localStorage.removeItem("access_token");
  localStorage.removeItem("login_phone");
  localStorage.removeItem("login_email");
  //   localStorage.removeItem("id_token");
  //   localStorage.removeItem("refresh_token");
  //   localStorage.removeItem("token_gen_time");
};

// Alert popup box for API related errors
const alerter = (
  title = "Message",
  message = "Hi, I am the default alerter.",
  refresh = false
) => {
  // confirmAlert({
  //   title: t(title),
  //   message: t(message),
  //   buttons: [
  //     {
  //       label: t("Close"),
  //       onClick: () => {
  //         if (refresh) window.location.reload();
  //       },
  //     },
  //   ],
  // });
  SimpleAlert(languageSelector(title), languageSelector(message), () => {
    if (refresh) window.location.reload();
  });
};

/*
--------------------------------------------------------------
*/

// GET method API function
const getData = async (path) => {
  //   await checkTokenExpiry();
  let header = REQUEST_HEADER(getAccessToken(), getLanguage());

  let response = await instance.get(path, header).catch(handleError);
  if (response && response.data && response.status && response.status === 200) {
    return response.data;
  } else if (response && response.status && response.status === 400) {
    return response;
  } else if (response && response.status && response.status === 500) {
    return response;
  } else {
    return null;
  }
};

// PUT method API function
const putData = async (path, data) => {
  //   await checkTokenExpiry();
  let header = REQUEST_HEADER(getAccessToken(), getLanguage());

  let response = await instance.put(path, data, header).catch(handleError);
  if (response && response.data && response.status === 200) {
    return response.data;
  } else if (response && response.status && response.status === 400) {
    return response;
  } else if (response && response.status && response.status === 500) {
    return response;
  } else {
    return null;
  }
};

// POST method API function
const postData = async (path, data) => {
  //   await checkTokenExpiry();
  let header = REQUEST_HEADER(getAccessToken(), getLanguage());

  let response = await instance.post(path, data, header).catch(handleError);

  if (response && response.data && response.status === 200) {
    return response.data;
  } else if (response && response.status && response.status === 400) {
    return response;
  } else if (response && response.status && response.status === 500) {
    return response;
  } else {
    return null;
  }
};

// DELETE method API function
const deleteData = async (path, data) => {
  //   await checkTokenExpiry();
  let header = REQUEST_HEADER(getAccessToken(), getLanguage());

  let response = await instance
    .delete(path, header, { data: data })
    .catch(handleError);
  if (response && response.data && response.status === 200) {
    return response.data;
  } else if (response && response.status && response.status === 400) {
    return response;
  } else if (response && response.status && response.status === 500) {
    return response;
  } else {
    return null;
  }
};

// Refresh tokens
// const refreshToken = async () => {
//   const refresh_token = getRefreshToken();

//   const CONFIG = {
//     headers: {
//       "x-refresh-token": refresh_token,
//     },
//   };

//   const EMAIL = {
//     email: getLoginEmail(),
//   };

//   try {
//     let response = await instance.post("/admin-basic/renew", EMAIL, CONFIG);
//     return response.data.data;
//   } catch (err) {
//     console.error(err);
//   }
// };

export {
  getData,
  putData,
  postData,
  deleteData,
  logout,
  REQUEST_HEADER,
  getAccessToken,
  //   getIDToken,
  //   getRefreshToken,
  //   checkTokenExpiry,
};
