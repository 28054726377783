import React from "react";
import { Route, Switch } from "react-router-dom";
import Login from "../pages/Login";
import CreateAccouunt from "../pages/CreateAccount";
// import SendOTP from "../pages/SendOTP";
import VerifyOTP from "../pages/VerifyOTP";
import ResetPassword from "../pages/ResetPassword";
import WelcomePage from "../pages/WelcomePage";
// import Samplepage from "../pages/sample";
import TermsAndConditions from "../PrivacyPolicy/TermsAndConditions";
import PrivacyPolicy from "../PrivacyPolicy/PrivacyPolicy";
import VerifyEmail from "../pages/VerifyEmail";
// routes for non autherised user
const Routes = () => {
  return (
    <Switch>
      {/* <Route exact path="/sample" component={Samplepage} /> */}
      <Route exact path="/" component={Login} />
      <Route exact path="/sign-up" component={CreateAccouunt} />
      <Route exact path="/welcome" component={WelcomePage} />
      <Route exact path="/verify-otp" component={VerifyOTP} />
      <Route exact path="/verify-email" component={VerifyEmail} />
      <Route exact path="/reset-password-otp" component={VerifyOTP} />
      <Route exact path="/reset-password" component={ResetPassword} />

      <Route
        exact
        path="/terms-and-conditions"
        component={TermsAndConditions}
      />
      <Route exact path="/privacy-policy" component={PrivacyPolicy} />
      <Route component={Login} />
    </Switch>
  );
};
export default Routes;
