import React, { useState, useEffect, useRef } from "react";
import "./PaymentDetails.css";
import Breadcrumb from "../../components/Breadcrumb";
import { getData } from "../../api";
import bookingStatusColor from "../../utils/bookingStatusColor";
import Spinner from "../../components/Spinner";
import formatDateTime from "../../utils/formatDateTime";
import { useTranslation } from "react-i18next";
import Images from "../../assets";
import { PDFDownloadLink } from "@react-pdf/renderer";
import DetailsPDF from "../../components/DetailsPDF";
import moment from "moment";
import UseCounter from "../../hooks/useCounter";

export default function PaymentDetails(props) {
  moment.locale("en");
  let id = props.match.params.id;
  let type = props.match.params.type;
  const ref = useRef(null);

  let color;
  let status_text;

  const { t } = useTranslation();

  const [paymentDetails, setPaymentDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const [showPDFDownloader, setShowPDFDownloader] = useState(false);
  const [seconds] = UseCounter(10000);

  // executes when page load
  // hide pdf downloader button
  useEffect(() => {
    setShowPDFDownloader(false);
  }, []);

  // executes when page load and alson in every 10 seconds
  useEffect(() => {
    getPaymentDetails();
  }, [seconds]);

  // function which makes api call to get payment details if type is payment and settle due details if type credit
  const getPaymentDetails = async () => {
    let response = {};
    if (type === "payment") {
      response = await getData(`/bookings/${id}`);
    } else if (type === "credit") {
      response = await getData(`/payments/settled_dues/${id}`);
    }
    setLoading(false);

    if (response && response.data) {
      setPaymentDetails({
        status:
          type === "payment"
            ? response.data.paymentDetails.status
              ? response.data.paymentDetails.status
              : "-"
            : response.data.paymentDetails.cleared
            ? "payment_complete"
            : "pending_payment",
        amount: response.data.total_cost ? response.data.total_cost : "-",
        id: response.data.bookingId ? response.data.bookingId : "-",
        paymentDate: response.data.paymentDetails.updatedAt
          ? response.data.paymentDetails.updatedAt
          : "-",
        pickup: response.data.pickup_location_name
          ? response.data.pickup_location_name
          : "-",
        dropOff: response.data.drop_location_name
          ? response.data.drop_location_name
          : "-",
        pickupDate: response.data.pickup_datetime,
        pickupContact: response.data.pickup_contact
          ? response.data.pickup_contact
          : "-",
        dropOffContact: response.data.drop_contact
          ? response.data.drop_contact
          : "-",
        trailerNo: response.data.journey?.trailer.reg_no
          ? response.data.journey.trailer.reg_no
          : "Not available",
      });
    }
  };

  [color, status_text] = bookingStatusColor(paymentDetails.status ?? "");

  return (
    <>
      {loading && <Spinner />}
      <div className="payment-details" ref={ref}>
        <div className="breadcrumb-title">
          <Breadcrumb text="Transactions" route="/transactions" />
          <Breadcrumb text="Payment details" islast={true} />
        </div>
        <div className="top-section">
          <div className="payment">
            <div
              className="status"
              style={{ backgroundColor: `${color}26`, color: `${color}` }}
            >
              {t(status_text)}
            </div>
            <div className="amount">
              {t("SAR") + " "}
              {paymentDetails.amount}
            </div>
          </div>

          {!showPDFDownloader && (
            <div className="pdf-download">
              <button
                className="download-btn"
                onClick={() => {
                  setShowPDFDownloader(true);
                }}
              >
                <img src={Images.pdf} className="pdf-img" />
                <span className="pdf-text">{t("Prepare PDF")}</span>
              </button>
            </div>
          )}

          {showPDFDownloader && (
            <PDFDownloadLink
              document={
                <DetailsPDF
                  status={status_text}
                  color={color}
                  id={paymentDetails.id}
                  trailer={paymentDetails.trailerNo}
                  date={moment(paymentDetails.pickupDate).format(
                    "DD MMM YYYY, hh:mm a"
                  )}
                  pickup={paymentDetails.pickup}
                  pickupContact={paymentDetails.pickupContact}
                  dropOff={paymentDetails.dropOff}
                  dropContact={paymentDetails.dropOffContact}
                  amount={paymentDetails.amount.toFixed(2)}
                  paidOn={
                    paymentDetails.status === "payment_complete"
                      ? moment(paymentDetails.paymentDate).format(
                          "DD MMM YYYY, hh:mm a"
                        )
                      : "Not paid"
                  }
                />
              }
              fileName={`OllyTransactionDetails_${moment().unix()}.pdf`}
            >
              {({ blob, url, loading, error }) => (
                <div className="pdf-download">
                  <button
                    className="download-btn"
                    onClick={() => {
                      setShowPDFDownloader(true);
                    }}
                    style={{ cursor: "auto" }}
                  >
                    <img src={Images.pdf} className="pdf-img" />
                    {loading ? (
                      <span className="pdf-text" style={{ cursor: "auto" }}>
                        {t("Processing") + " "}
                        <i class="fa fa-spinner  fa-pulse" aria-hidden="true" />
                      </span>
                    ) : (
                      <span className="pdf-text" style={{ cursor: "pointer" }}>
                        {t("Download PDF")}
                      </span>
                    )}
                  </button>
                </div>
              )}
            </PDFDownloadLink>
          )}
        </div>
        <div className="booking-details">
          <div className="title-info">
            <div className="id">
              <span className="id-title">{`${t("Booking ID")}`}</span>
              <span className="line">{"-"}</span>
              <span className="id-text">{paymentDetails.id}</span>
            </div>
            <div className="date-info">
              <span className="date-title">{t("Payment done on")} </span>
              <span className="date">
                {formatDateTime(
                  paymentDetails.paymentDate,
                  "DD MMM YYYY, hh:mm a"
                )}
              </span>
            </div>
          </div>
        </div>
        <div className="booking-details">
          <div className="booking-info">
            <div className="title">
              <span className="sub-title">{t("Pickup location")}</span>
              <span className="text">{paymentDetails.pickup} </span>
            </div>
            <div className="title">
              <span className="sub-title">{t("Drop-off location")}</span>
              <span className="text">{paymentDetails.dropOff} </span>
            </div>
            <div className="title">
              <span className="sub-title">{t("Pickup date and time")}</span>
              <span className="text">
                {formatDateTime(
                  paymentDetails.pickupDate,
                  "ddd, DD MMM YYYY, hh:mm a"
                )}{" "}
              </span>
            </div>
            <div className="title">
              <span className="sub-title">{t("Pickup contact")}</span>
              <span className="text contact">{`+${paymentDetails.pickupContact}`}</span>
            </div>
            <div className="title">
              <span className="sub-title">{t("Drop-off contact")}</span>
              <span className="text contact">{`+${paymentDetails.dropOffContact}`}</span>
            </div>
            <div className="title">
              <span className="sub-title">{t("Trailer number")}</span>
              <span className="text">{paymentDetails.trailerNo}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
