import React, { useState, useEffect, useContext } from "react";
import "./WelcomePage.css";
import Images from "../../assets";
import { useHistory, useLocation } from "react-router-dom";
import FrontPages from "../../components/FrontPages";
import Button from "../../components/SquareButton";
import { postData } from "../../api";
import { AuthContext } from "../../App";
import { useTranslation } from "react-i18next";
import {
  SuccessAlert,
  FailureAlert,
  SimpleAlert,
} from "../../utils/alertTemplate";
import imageSelector from "../../utils/LangImages";

const Login = () => {
  const history = useHistory();
  const location = useLocation();
  const { state } = useContext(AuthContext);
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [phone, setPhone] = useState();
  const [password, setPassword] = useState();

  // executes on click get started button
  // login if reached this page after creating an account or phone number and password is available in local storage
  const signin = async () => {
    // if (
    //   localStorage.getItem("login_phone") &&
    //   localStorage.getItem("password")
    // ) {
    if (phone && password) {
      // console.log(localStorage.getItem("login_phone"));
      setLoading(true);
      let response = await postData("/auth/users/login", {
        phone: "966" + phone,
        password: password,
        // phone: "966" + localStorage.getItem("login_phone"),
        // password: localStorage.getItem("password"),
      });
      setLoading(false);

      if (response && response.status && response.status === 200) {
        localStorage.setItem("access_token", response.data?.access_token);
        window.location.assign("/");
      } else if (response && response.status && response.status === 400) {
        SimpleAlert(
          t("Failed"),
          `${response.message?.error ?? t("Something went wrong")}`
        );
      } else {
        FailureAlert(
          t("Failed"),
          t("Something went wrong. Please try again later.")
        );
      }
    } else {
      history.push("/");
    }
  };

  useEffect (() => {
    console.log("from welcome >>", location.state)
    if (!location.state?.phone || !location.state?.password) {
      history.push("/")
    } else {
      console.log(location.state?.phone, location.state?.password)
      setPhone(location.state?.phone)
      setPassword(location.state?.password)
    }
  }, [])

  return (
    <div
      className="welcome-page"
      // style={{
      //   background: `url(${Images.trailer_background})`,
      //   backgroundPosition: "top 0vh right 0vw",
      //   backgroundRepeat: "no-repeat",
      // }}
    >
      <img src={Images.shade} className="shade" />
      <div className="logo">
        {/* <img className="olly_logo" src={Images.olly_logo} /> */}
        <img src={imageSelector("logo")} />
      </div>
      <div className="content-section">
        <div className="content-details">
          <FrontPages
            subHeading={
              <p>
                <span style={{ fontSize: "23px" }}>
                  {t("Hi")} {state.userName}!
                </span>
                <br />
                <br />
                <span>
                  {t("You have successfully created your account") +
                    t("fullstop") +
                    " " +
                    t("Thank you for signing up with OLLY") +
                    t("fullstop")}
                </span>
              </p>
            }
          />
          <div className="input-element">
            <Button text="Get started" onClickBtn={signin} />
          </div>
        </div>
        <div className="truck-image">
          <img src={Images.truck} />
        </div>
      </div>
    </div>
  );
};

export default Login;
