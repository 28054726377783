import React from "react";
import "./FrontPages.css";
import Images from "../../assets";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

const FrontPages = ({ subHeading, linkText, link, state = "" }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const Link = () => {
    history.push({
      pathname: link,
      state: { detail: state },
    });
  };
  return (
    <div className="front-page">
      <div className="content">
        {/* <img className="logo" src={Images.olly_logo} />
        <img src={Images.olly} /> */}
        <div>{t("Ship your packages with Olly")}</div>
        <p>
          {subHeading}
          {link && (
            <span className="link" onClick={Link}>
              {" " + t(linkText) + "."}
            </span>
          )}
        </p>
      </div>
    </div>
  );
};

export default FrontPages;
