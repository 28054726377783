// weight unit options in booking page
export const weightOptions = [
    { label: "kg", value: "kg" },
    { label: "t", value: "t" },
];
// sign up document name and id constants
export const signupDocConstants = {
    companyRegDoc: {
        documentType: "8dd58d61-21f0-4c72-aec7-620613e0abba",
        title: "Commercial Registration"
    },
    companylicenseDoc: {
        documentType: "d44cdc49-dc43-478a-baf3-00aecd8c7626",
        title: "Company license"
    },
    vatCertificateDoc: {
        documentType: "048e1006-f790-4fed-b50b-57b87aa0030b",
        title: "VAT Registration"
    },
}
// otp count down timer initial value in seconds
export const otpTimerCount = 179