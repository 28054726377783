import ar from "./arabic";
import en from "./english";

const imageSelector = (img) => {
  let languageArray = { en: en, ar: ar };
  let languageSelector = localStorage.getItem("Olly_lang")
    ? localStorage.getItem("Olly_lang")
    : "en";
  return languageArray[languageSelector][img];
};
export default imageSelector;
