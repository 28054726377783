import firebase from "firebase";
import "firebase/auth";
import { postData } from "./api/NoAuthApi";


var firebaseConfig = {
  apiKey: "AIzaSyCR3kPoKFukBejexPGVDPgauLQziVXwxq0",
  authDomain: "olly-294007.firebaseapp.com",
  databaseURL: "https://olly-294007.firebaseio.com",
  projectId: "olly-294007",
  storageBucket: "olly-294007.appspot.com",
  messagingSenderId: "63649325020",
  appId: "1:63649325020:web:778bca70357aa996f5b919",
  measurementId: "G-KWQECX4J5W",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
export const auth = firebase.auth();

const provider = new firebase.auth.GoogleAuthProvider();

// for google sign in
export const signInWithGoogle = () => {
  auth.signInWithPopup(provider)
  .then(async (result) => {
    const idToken = result.credential.idToken;
    let response = await postData("/auth/users/google_signin", {}, idToken);
    if (response && response.status && response.status === 200) {
      // localStorage.setItem("login_phone", signinData.phone);
      localStorage.setItem("access_token", response.data?.access_token);
      window.location.assign("/");
    }
    // ...
  }).catch((error) => {
    console.log("error", error);
  });
}

// firebase.analytics();
export default firebase;
