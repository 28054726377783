// set signup field values to local storage
export const setSignupStorage = (data) => {
  window.localStorage.setItem("signup_isDataAvailable", JSON.stringify(true));
  window.localStorage.setItem("signup_firstName", data.firstName);
  window.localStorage.setItem("signup_lastName", data.lastName);
  window.localStorage.setItem("signup_phone", data.phone);
  window.localStorage.setItem("signup_email", data.email);
  window.localStorage.setItem("signup_docs", JSON.stringify(data.docs));
  window.localStorage.setItem("signup_website", JSON.stringify(data.website));
  window.localStorage.setItem("signup_companyRegDoc", JSON.stringify(data.companyRegDoc) );
  window.localStorage.setItem("signup_companylicenseDoc", JSON.stringify(data.companylicenseDoc));
  window.localStorage.setItem("signup_vatCertificateDoc", JSON.stringify(data.vatCertificateDoc));
  console.log("stored signup data >> ", data);
};
// get signup field values from local storage
export const getSignupStorage = () => {
  const data = {
    firstName: window.localStorage.getItem("signup_firstName"),
    lastName: window.localStorage.getItem("signup_lastName"),
    phone: window.localStorage.getItem("signup_phone"),
    email: window.localStorage.getItem("signup_email"),
    docs: JSON.parse(window.localStorage.getItem("signup_docs")),
    website: JSON.parse(window.localStorage.getItem("signup_website")),
    companyRegDoc: JSON.parse(window.localStorage.getItem("signup_companyRegDoc")),
    companylicenseDoc: JSON.parse(window.localStorage.getItem("signup_companylicenseDoc")),
    vatCertificateDoc: JSON.parse(window.localStorage.getItem("signup_vatCertificateDoc")),
    isDataAvailable: JSON.parse(window.localStorage.getItem("signup_isDataAvailable")),
  };
  console.log("get Signup data >> ", data);
  return data;
};
// remove signup field values from local storage
export const removeSignupStorage = () => {
  window.localStorage.setItem("signup_isDataAvailable", JSON.stringify(false));
  window.localStorage.removeItem("signup_firstName");
  window.localStorage.removeItem("signup_lastName");
  window.localStorage.removeItem("signup_phone");
  window.localStorage.removeItem("signup_email");
  window.localStorage.removeItem("signup_docs");
  window.localStorage.removeItem("signup_website");
  window.localStorage.removeItem("signup_companyRegDoc");
  window.localStorage.removeItem("signup_companylicenseDoc");
  window.localStorage.removeItem("signup_vatCertificateDoc");
  console.log("removed signup data")
};
// store a string to localstorage
export const setLocalStorage = (key, value) => {
    window.localStorage.setItem(key, JSON.stringify(value))
}
// read from localstorage
export const getLocalStorage = (key) => {
    return JSON.parse(window.localStorage.getItem(key))
}
