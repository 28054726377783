import React from "react";
import "./PaymentSuccessful.css";
import Images from "../../assets";
import Header from "../../components/Header";
import ActionStatus from "../../components/ActionStatus";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

// this component is rendered when payment is success
const PaymentSuccessful = (props) => {
  // const location = useLocation();
  // const params = new URLSearchParams(location.search);
  // let type = params.get("type");
  let type = props.match.params.type
  console.log(type, "type");
  const { t } = useTranslation();
  const text =
    type === "transactions"
      ? t("Your dues have been settled") +
        "! " +
        t("Your credits will be restored soon") +
        "."
      : t("Your payment has been received") +
        "! " +
        t("We are working on allotting a trailer for you") +
        ".";
  return (
    <div className="payment-success">
      <ActionStatus
        heading="Payment successful"
        image={Images.success}
        text={text}
      />
    </div>
  );
};

export default PaymentSuccessful;
