import React, { useState, useEffect } from "react";
import "./Payment.css";
import { useHistory } from "react-router-dom";
import Breadcrumb from "../../components/Breadcrumb";
import InputField from "../../components/InputField";
import SquareButton from "../../components/SquareButton";
import Spinner from "../../components/Spinner";
import formatDateTime from "../../utils/formatDateTime";
import { putData, getData } from "../../api";
import { useTranslation } from "react-i18next";
import { MandatoryFieldCheck, ValidatePhoneNo } from "../../utils/Validation";
import { confirmAlert } from "react-confirm-alert";
import "../../constants/ConfirmAlert.css";
function Payment(props) {
  const id = props.match.params.id;
  const history = useHistory();
  // let baseURL = window.location.href.substring(
  //   window.location.href.lastIndexOf("//") + 2,
  //   window.location.href.lastIndexOf("booking/")
  // );
  // console.log(window.location.host);

  const { t } = useTranslation();

  // const [inputText, setinputText] = useState();
  const [loading, setLoading] = useState(false);
  const [Contacts, setContacts] = useState({
    pickupContact: "",
    dropContact: "",
  });
  const [bookingDetails, setBookingDetails] = useState({});
  const [errors, seterrors] = useState({
    pickupFieldError: "",
    dropoffFieldError: "",
  });
  let isEnable = false;
  const [responseError, setResponseError] = useState("");
  const [disableButton, setDisableButton] = useState(false);

 // function to validate mandatory fields, check whether pickup and dropoff fields are empty, return true if both the fields are empty 
  const MandatoryFieldValidation = () => {
    let pickupError = ValidatePhoneNo(Contacts.pickupContact);
    let dropoffError = ValidatePhoneNo(Contacts.dropContact);

    seterrors({
      pickupFieldError: pickupError,
      dropoffFieldError: dropoffError,
    });
    return pickupError == "" && dropoffError == "" ? true : false;
  };

  // Function to check whether pickup and drop-off contacts are in valid. 
  const validate = (inputText) => {
    let error = "";
    switch (inputText.id) {
      /** case 0 - Input from pickup contact field */
      case 0: {
        error = ValidatePhoneNo(inputText.value);
        setContacts({ ...Contacts, pickupContact: inputText.value });
        seterrors({ ...errors, pickupFieldError: error });
        break;
      }
      /** case 1 - Input from drop-off contact field */
      case 1: {
        error = ValidatePhoneNo(inputText.value);
        setContacts({ ...Contacts, dropContact: inputText.value });
        seterrors({ ...errors, dropoffFieldError: error });
        break;
      }
    }
  };

  // executes on click confirm booking botton
  // make api call confirming booking and redirect to history page when validation for mandatory field is success
  const confirmBooking = async() => {
    setDisableButton(true);
    setLoading(true);
    if (MandatoryFieldValidation()) {
      const payLoad = {
        pickup_contact: "966" + Contacts.pickupContact,
        drop_contact: "966" + Contacts.dropContact,
      };

      const response = await putData(`/bookings/${id}/contacts`, payLoad);

  //     if (response && response.data) {
  //       // setLoading(true);
  //       const paymentResponse = await postData(
  //         `/bookings/payment/get_payment_page/${id}?redirect_url=${window.location.origin}/booking/payment-successful&failure_redirect_url=${window.location.origin}/booking/payment-failed`
  //       );

  //       if (
  //         paymentResponse &&
  //         paymentResponse.data &&
  //         paymentResponse.data.payment_url
  //       ) {
  //         paymentURL = paymentResponse.data.payment_url;
  //         window.location.assign(paymentURL);
  //       } else if (
  //         paymentResponse &&
  //         paymentResponse.status &&
  //         paymentResponse.status === 400
  //       ) {
  //         setLoading(false);
  //         PaymentFailedAlert(
  //           t("Failed"),
  //           `${paymentResponse.message?.error ?? t("Something went wrong")}`
  //         );
  //       } else {
  //         setLoading(false);
  //         FailureAlert(t("Failed"));
  //       }
  //     } else if (response && response.status && response.status === 400) {
  //       FailureAlert(
  //         t("Failed"),
  //         `${response.message?.error ?? t("Something went wrong")}`
  //       );
  //     } else {
  //       FailureAlert(t("Action failed"));
  //     }
  //   }
  //   setDisableButton(false);
  //   setLoading(false);
  // };

  // const makeCreditBtn = async () => {
  //   setLoading(true);
  //   setDisableButton(true);
  //   if (MandatoryFieldValidation()) {
  //     const payLoad = {
  //       pickup_contact: "966" + Contacts.pickupContact,
  //       drop_contact: "966" + Contacts.dropContact,
  //     };
  //     setLoading(true);
  //     const response = await putData(`/bookings/${id}/contacts`, payLoad);

  //     if (response && response.data) {
  //       let creditResponse = await postData(
  //         `/bookings/payment/pay_with_credit/${id}`
  //       );
  //       // setLoading(false);
  //       if (creditResponse && creditResponse.status === 200) {
  //         // SuccessAlert("Cancellation Success", () =>
  //         history.push("/booking/payment-successful");
  //         // );
  //       } else if (
  //         creditResponse &&
  //         creditResponse.status &&
  //         creditResponse.status === 400
  //       ) {
  //         setLoading(false);
  //         FailureAlert(
  //           t("Failed"),
  //           `${creditResponse.message?.error ?? t("Something went wrong")}`
  //         );
  //       } else {
  //         setLoading(false);
  //         FailureAlert(t("Action failed"));
  //       }
  //     } else if (response && response.status && response.status === 400) {
  //       setLoading(false);
  //       FailureAlert(
  //         t("Failed"),
  //         `${response.message?.error ?? t("Something went wrong")}`
  //       );
  //     } else {
  //       setLoading(false);
  //       FailureAlert(t("Action failed"));
  //     }
  //     setLoading(false);
  //   }
  //   setDisableButton(false);
  //   setLoading(false);
  // };

  // useEffect(() => {
  //   const getCreditDetails = async () => {
  //     setLoading(true);
  //     const response = await getData(`/credit/status`);
  //     setLoading(false);

  //     if (response && response.data) {
  //       setcreditDetails({
  //         total_amount: response.data.max,
  //         current: response.data.current,
  //         active: response.data.active,
  //       });
  //     }
  //   };
  //   getCreditDetails();
  // }, []);
      if(response && response.data) {
        history.push("/history")
      }
    }
  }

  // executes when page loads
  // call function that makes api call to get booking details
  useEffect(() => {
    const getBookingDetails = async () => {
      setLoading(true);
      const response = await getData(`/bookings/${id}`);
      setLoading(false);

      if (response && response.data) {
        setBookingDetails({
          pickupLocation: response.data.pickup_location_name
            ? response.data.pickup_location_name
            : "-",
          dropLocation: response.data.drop_location_name
            ? response.data.drop_location_name
            : "-",
          date: response.data.pickup_datetime
            ? response.data.pickup_datetime
            : "-",
          amount: response.data.total_cost ? response.data.total_cost : "-",
        });
      }
    };
    getBookingDetails();
  }, []);

  return (
    <>
      {loading && <Spinner />}
      <div className="payment-page">
        <div className="breadcrumb-title">
          <Breadcrumb text="Book a trailer" route="/" />
          <Breadcrumb text="Payment" islast={true} />
        </div>
        <div className="payment-details-section">
          {/* <div className="contacts-location-container">
            <div className="contacts"> */}
          <div className="text-info">
            {t(
              "Add contacts for the driver to reach the right person during pickup and drop-off"
            ) + "."}
          </div>
          <div className="pickup">
            <InputField
              id={0}
              label={t("Pickup contact") + "*"}
              placeholder={t("Pickup contact")}
              validate={validate}
              errorField={errors.pickupFieldError}
              inputData="contact"
            />
          </div>
          <div className="dropoff">
            <InputField
              id={1}
              label={t("Drop-off contact") + "*"}
              placeholder={t("Drop-off contact")}
              validate={validate}
              errorField={errors.dropoffFieldError}
              inputData="contact"
            />
          </div>
          {/* </div> */}
          <div className="location-info">
            <div className="title">
              <span className="sub-title">{t("Pickup location")}</span>
              <span className="text">{bookingDetails.pickupLocation}</span>
            </div>
            <div className="title">
              <span className="sub-title">{t("Drop-off location")}</span>
              <span className="text">{bookingDetails.dropLocation}</span>
            </div>
            <div className="title">
              <span className="sub-title">{t("Date and Time")}</span>
              <span className="text">
                {formatDateTime(
                  bookingDetails.date,
                  "ddd, DD MMM YYYY, hh:mm a"
                )}
              </span>
            </div>
          </div>
          {/* </div> */}
          {/* {creditDetails.active && (
            <div className="credit">
              <div className="text">
                <div className="credit-title">{t("Pay with Olly credits")}</div>
                <div className="toggle-btn">
                  <label className="switch">
                    <input
                      type="checkbox"
                      className="checkbox"
                      checked={toggle}
                      disabled={false}
                      onClick={(e) => {
                        settoggle(!toggle);
                      }}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
              <div className="balance">
                {t("Balance")} : {t("SAR")}{" "}
                {(creditDetails.total_amount - creditDetails.current).toFixed(
                  2
                )}
              </div>
            </div>
          )} */}
          <div className="amount-info">
            <div className="text">{t("Amount payable")}</div>
            <div className="amount">
              {t("SAR") + " "}
              {bookingDetails.amount}
            </div>
          </div>
          
          {responseError && <p className="error-msg">{responseError}</p>}
          <div className="buttons">
            <SquareButton
              isdisabled={disableButton}
              text={"Confirm Booking"}
              onClickBtn={() => (confirmBooking())}
            />
          </div>
        </div>
      </div>
    </>
  );
}
export default Payment;
