export default {
  olly_logo: require("./OLLY_logo.png"),
  olly: require("./logo_english.svg"),
  olly_eng: require("./OLLY_english.png"),
  olly_ar: require("./Olly Logo - Arabic.svg"),
  avatar: require("./avatar.svg"),
  shade: require("./shade.svg"),
  truck: require("./Truck.svg"),
  truck_icon: require("./truck-icon.svg"),
  background: require("./background.svg"),
  empty: require("./empty_notification.svg"),
  success: require("./payment_success.svg"),
  trailer_background: require("./trailer_background.svg"),
  greenTick: require("./green-tick.svg"),
  redCross: require("./red-cross.svg"),
  closed_eye: require("./closed_eye.svg"),
  open_eye: require("./open_eye.svg"),
  circular_edit_btn: require("./circular-edit.svg"),
  edit_btn: require("./edit_icon.svg"),
  no_results: require("./no_results.svg"),
  page_not_found: require("./page_not_found.svg"),
  contact_us: require("./contact_us.svg"),
  truck_marker: require("./truck_marker.svg"),
  handburg_icon: require("./menu.svg"),
  cancelled: require("./cancelled_success.svg"),
  pdfDownloadBtn: require("./PdfDownloadBtn.svg"),
  ovel: require("./Oval.svg"),
  calendar_icon: require("./calendar_icon.svg"),
  cross: require("./cross.svg"),
  payment_failed: require("./payment_failed.svg"),
  pdf: require("./pdf.svg"),
  flag: require("./flag.svg"),
  facebook: require("./Facebook.png"),
  instagram: require("./Instagram.png"),
  twitter: require("./Twitter.png"),
};
