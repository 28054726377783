import React, { useEffect, useState, useRef, useContext } from "react";
import "./Profile.css";
import Images from "../../assets";
import { getData, postData, putData } from "../../api";
import Spinner from "../../components/Spinner";
import { SuccessAlert, FailureAlert } from "../../utils/alertTemplate";
import { useHistory } from "react-router-dom";
import Button from "../../components/SquareButton";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../App";
import imageCompression from "browser-image-compression";
import { ValidateName } from "../../utils/Validation";

function Profile() {
  const { t } = useTranslation();

  const [profileDetails, setProfileDetails] = useState([]);

  const [isNameEditing, setisNameEditing] = useState(false);
  const [Name, setName] = useState(profileDetails.name);
  const [imageLoading, setimageLoading] = useState(false);
  const [reRender, setreRender] = useState(false);
  const [loading, setLoading] = useState(false);

  const ref = useRef(null);
  const history = useHistory();

  const { dispatch } = useContext(AuthContext);

  // to handle click outside the field while editing name
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setisNameEditing(false);
    }
  };

  // add click event listner to document on page load
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  // make api call to get profile details on page load and after editing
  useEffect(() => {
    const getProfileDetails = async () => {
      setLoading(true);
      const response = await getData("/users/profile");
      setLoading(false);

      if (response && response.data) {
        console.log(response);
        setProfileDetails({
          image: response.data.profile_pic,
          name: response.data.first_name
            ? response.data.first_name + " " + response.data.last_name
            : "-",
          email: response.data.email ? response.data.email : "-",
          phoneNo: response.data.phone ? response.data.phone : "-",
        });
        setName(
          response.data.first_name
            ? response.data.first_name + " " + response.data.last_name
            : ""
        );
        dispatch({
          type: "PROFILE_PIC",
          payload: response.data.profile_pic,
        });
      }
    };
    getProfileDetails();
  }, [reRender]);

  // function to make api call to update name of user
  const uploadName = async () => {
    let error = ValidateName(Name);
    if (error) {
      FailureAlert(t("Failed"), t(error));
      setName(profileDetails.name);
    } else {
      let response = await postData(`/users/profile`, {
        first_name: Name.split(" ")[0],
        last_name: Name.split(" ").slice(1).join(" "),
      });
      if (response && response.data) {
        setreRender(!reRender);
        SuccessAlert(t("Name updated"), () => setisNameEditing(false));
      } else {
        FailureAlert(t("Action Failed"), () => setisNameEditing(false));
      }
    }
  };

  // to change the state of name edit button
  const updateNamebtn = () => {
    setisNameEditing(!isNameEditing);
  };

  // function to make api call to update profile picture of user
  const UploadImage = async (compressedImg) => {
    const formData = new FormData();
    formData.append("image", compressedImg);
    setimageLoading(true);
    let response = await putData(`/users/profile/picture`, formData);
    setimageLoading(false);
    console.log(response);
    if (response && response.data) {
      SuccessAlert(t("Image Updated"));
      setreRender(!reRender);
    } else if (response && response.status && response.status === 400) {
      FailureAlert(
        t("Action Failed"),
        `${response.message?.error ?? "Something went wrong"}`
      );
    } else {
      FailureAlert(t("Action Failed"));
    }
  };

  // function to compress image using browser-compress-image library
  const compressImage = async (imageFile) => {
    console.log("originalFile instanceof Blob", imageFile instanceof Blob); // true
    console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);

    let options = {
      maxSizeMB: 2,
      maxWidthOrHeight: 2048,
      useWebWorker: true,
    };

    try {
      const compressedFile = await imageCompression(imageFile, options);
      console.log(
        "compressedFile instanceof Blob",
        compressedFile instanceof Blob
      ); // true
      console.log(
        `compressedFile size ${compressedFile.size / 1024 / 1024} MB`
      ); // smaller than maxSizeMB
      console.log(compressedFile);
      console.log("imageFile : ", imageFile);
      return compressedFile; // write your own logic
    } catch (error) {
      return imageFile;
    }
  };

  // to handle add or change in profile image
  const handleChange = async (e) => {
    const imageFile = e.target.files[0];
    let filesize = imageFile.size / 1024 / 1024; //MB
    console.log("filesize:", filesize);

    if ( // validation for image file type
      e.target.files.length &&
      (imageFile.name.toLowerCase().includes(".png") ||
        imageFile.name.toLowerCase().includes(".jpg") ||
        imageFile.name.toLowerCase().includes(".jpeg"))
    ) {
      var reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = function (e) {
        var img = new Image();
        img.onload = async function () {
          let resolution = (img.width * img.height) / 1000000;
          console.log("resolution : ", resolution);
          if (resolution > 2 && img.width > 100 && img.height > 100) {
            if (filesize < 2) {
              UploadImage(imageFile);
            } else {
              let compressedImg = await compressImage(imageFile);
              filesize = compressedImg.size / 1024 / 1024; //MB;
              console.log("compressed size:", filesize);
              if (filesize < 2) {
                UploadImage(compressedImg);
              } else {
                FailureAlert(
                  t("Failed"),
                  t("Compressed image size is greater than 2mb")
                );
              }
            }
          } else {
            FailureAlert(t("Failed"), t("Image Resolution is low"));
          }
        };
        img.src = reader.result;
      };

      // setImage({
      //   preview: URL.createObjectURL(e.target.files[0]),
      //   raw: e.target.files[0],
      // });
    } else {
      FailureAlert(t("Failed"), t("Please upload an image"));
    }
  };

  return (
    <div className="profile">
      {loading && <Spinner />}
      <div className="profile-details">
        <div className="profile-pic">
          {imageLoading ? (
            <div className="loader-bg">
              <div className="loader"></div>
            </div>
          ) : profileDetails.image ? (
            <div>
              <img src={profileDetails.image} />
              <div className="circular_edit_btn">
                <label for="upload-photo">
                  <img src={Images.circular_edit_btn} />
                </label>
                <input
                  id="upload-photo"
                  type="file"
                  name="image"
                  style={{ display: "none" }}
                  onChange={handleChange}
                />
              </div>
            </div>
          ) : (
            <div className="no-image">
              <label for="upload-photo">
                <span className="plus">+</span>
                <span className="add-photo">{t("Add Photo")}</span>
              </label>
              <input
                id="upload-photo"
                type="file"
                name="image"
                style={{ display: "none" }}
                onChange={handleChange}
              />
            </div>
          )}
        </div>
        {isNameEditing ? (
          <div className="name-input-field" ref={ref}>
            <input
              placeholder="Name"
              autoFocus={true}
              value={Name}
              className="name-input"
              onChange={(e) => {
                setName(e.target.value);
              }}
              onKeyPress={(e) => {
                if ((e.keyCode || e.which) == 13) uploadName();
              }}
            />
            <i class="fa fa-check" onClick={uploadName} />
          </div>
        ) : (
          <div className="name-section">
            <span className="name">{profileDetails.name}</span>
            <img
              src={Images.edit_btn}
              className="fa fa-edit"
              onClick={updateNamebtn}
            />
          </div>
        )}
        <div className="email">{profileDetails.email}</div>
        <div className="phone-no">{`+${profileDetails.phoneNo}`}</div>
      </div>
      <div className="settings">
        <div
          onClick={() => {
            history.push("/change-password");
          }}
        >
          <span>{t("Change password")}</span>
          <i className="fa fa-angle-right" />
        </div>
        <div
          onClick={() => {
            history.push("/change-language");
          }}
        >
          <span>{t("Change language")}</span>
          <i className="fa fa-angle-right" />
        </div>
      </div>
    </div>
  );
}
export default Profile;
