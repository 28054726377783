import React from "react";
import "./BookingSuccessful.css";
import Images from "../../assets";
import Header from "../../components/Header";
import ActionStatus from "../../components/ActionStatus";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

// this page get rendered when a booking made is successfill 
const BookingSuccessful = (props) => {
  // const location = useLocation();
  // const params = new URLSearchParams(location.search);
  // let type = params.get("type");
  const { t } = useTranslation();
  const text =
      t("Booking received") +
        "! " +
        t("Please wait until our admin notifies you of the amount of payment") +
        ".";
  return (
    <div className="booking-success">
      <ActionStatus
        heading="Booking successful"
        image={Images.success}
        text={text}
      />
    </div>
  );
};

export default BookingSuccessful;
