import React, {useState, useEffect, useContext, useMemo} from "react";
import "./CreateAccount.css";
import {useHistory} from "react-router-dom";
import Images from "../../assets";
import FrontPages from "../../components/FrontPages";
import InputField from "../../components/InputField";
import Button from "../../components/SquareButton";
import {
  SimpleAlert,
  FailureAlert,
  SuccessAlert,
} from "../../utils/alertTemplate";
import {postData} from "../../api/NoAuthApi";
import PasswordField from "../../components/Password";
import {AuthContext} from "../../App";
import {
  ValidateName,
  ValidatePhoneNo,
  ValidateEmail,
  ValidatePassword,
  MandatoryFieldCheck,
  ValidateFileUpload,
} from "../../utils/Validation";
import {useTranslation} from "react-i18next";
import imageSelector from "../../utils/LangImages";
import Spinner from "../../components/Spinner";
import FileUpload from "../../components/FileUpload";
import CheckTC from "../../components/CheckTC";
import { signupDocConstants } from "../../constants";
import { getSignupStorage, getLocalStorage, setSignupStorage } from "../../utils/localStorage";

const Login = () => {
  const history = useHistory();
  const {t} = useTranslation();
  const {dispatch} = useContext(AuthContext);
  let isEnable = false;
  const [fieldValues, setfieldValues] = useState({
    firstName: "",
    lastName: "",
    password: "",
    phone: "",
    email: "",
    companyRegDoc: "",
    companylicenseDoc: "",
    vatCertificateDoc: "",
    companyWebsite: "",
  });
  const [errors, seterrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [agreement, setAgreement] = useState(false)
  const [signupDocs, setSignupDocs] = useState([])
  const [filteredsignupDocs, setFilteredSignupDocs] = useState([])
  // const [isEdit, setIsEdit] = useState(false)

  const MandatoryFieldValidation = () => {
    let firstName_error = ValidateName(fieldValues.firstName);
    let lastName_error = ValidateName(fieldValues.lastName);
    let email_error = ValidateEmail(fieldValues.email);
    let phone_error = ValidatePhoneNo(fieldValues.phone);
    // let password = MandatoryFieldCheck(signinData.password);
    let companyRegDoc_error = ValidateFileUpload(fieldValues.companyRegDoc);
    let companylicenseDoc_error = ValidateFileUpload(fieldValues.companylicenseDoc);
    let vatCertificateDoc_error = ValidateFileUpload(fieldValues.vatCertificateDoc);

    seterrors({...errors,
      firstName: firstName_error, 
      lastName: lastName_error, 
      email: email_error, 
      phone: phone_error,
      companyRegDoc: companyRegDoc_error,
      companylicenseDoc: companylicenseDoc_error,
      vatCertificateDoc: vatCertificateDoc_error,
    });
    return (
      firstName_error == "" && 
      lastName_error == "" && 
      email_error == "" && 
      phone_error == "" && 
      companyRegDoc_error == "" && 
      companylicenseDoc_error == "" && 
      vatCertificateDoc_error == "" &&
      agreement
      )  
    ? true : false;
  };

  // execute when value of the input field changes
  const validate = (inputText, isUpload = true) => {
    let error = "";
    switch (inputText.id) {
      // validation for first name field
      case "signup__first-name": {
        error = ValidateName(inputText.value);
        setfieldValues((prevFieldValues) => ({...prevFieldValues, firstName: inputText.value}));
        seterrors((prevErrors) => ({...prevErrors, firstName: error}));
        break;
      }
      // validation for last name field
      case "signup__last-name": {
        error = ValidateName(inputText.value);
        setfieldValues((prevFieldValues) => ({...prevFieldValues,  lastName: inputText.value}));
        seterrors((prevErrors) => ({...prevErrors, lastName: error}));
        break;
      }
      // validation for phone number field
      case "signup__phone-number": {
        error = ValidatePhoneNo(inputText.value);
        setfieldValues((prevFieldValues) => ({...prevFieldValues,  phone: inputText.value}));
        seterrors((prevErrors) => ({...prevErrors, phone: error}));
        break;
      }
      // validation for email field
      case "signup__email": {
        error = ValidateEmail(inputText.value);
        setfieldValues((prevFieldValues) => ({...prevFieldValues, email: inputText.value}));
        seterrors((prevErrors) => ({...prevErrors, email: error}));
        break;
      }
      // validation for password field
      case "signup__password": {
        error = ValidatePassword(inputText.value);
        setfieldValues((prevFieldValues) => ({...prevFieldValues, password: inputText.value}));
        seterrors({...errors, password: error});
        break;
      }
      // validation for Company registration
      case "signup__company-registration": {
        console.log("validatinggg")
        error = ValidateFileUpload(inputText)
        if ( error.length <= 0 ) {
          isUpload && uploadDocument(inputText);
          setfieldValues((prevFieldValues) => ({...prevFieldValues, companyRegDoc: inputText}));
        }
        seterrors((prevErrors) => ({...prevErrors, companyRegDoc: error}));
        break;
      }
      // validation for Company license
      case "signup__company-license": {
        error = ValidateFileUpload(inputText)
        if ( error.length <= 0 ) {
          isUpload && uploadDocument(inputText);
          setfieldValues((prevFieldValues) => ({...prevFieldValues, companylicenseDoc: inputText}));
        }
        seterrors((prevErrors) => ({...prevErrors, companylicenseDoc: error}));
        break;
      }
      // validation for VAT Certificate
      case "signup__vat-certificate": {
        error = ValidateFileUpload(inputText)
        if ( error.length <= 0 ) {
          isUpload && uploadDocument(inputText);
          setfieldValues((prevFieldValues) => ({...prevFieldValues, vatCertificateDoc: inputText}));
        }
        seterrors((prevErrors) => ({...prevErrors, vatCertificateDoc: error}));
        break;
      }
      // set website field Company website
      case "signup__company-website": {
        setfieldValues((prevFieldValues) => ({...prevFieldValues, companyWebsite: inputText.value}))
        break;
      }
    }
  };

  // enable submit button if first name, last name and password are valid
  isEnable = (errors.firstName == "" &&
    errors.lastName == "" &&
    errors.phone == "" &&
    errors.email == "" &&
    errors.password == "" &&
    errors.companyRegDoc == "" &&
    errors.companylicenseDoc == "" &&
    errors.vatCertificateDoc == "" &&
    agreement ) ? 
  true : false;
  // make API call to upload document, create document payload
  const uploadDocument = async (input) => {
    const docName = input.name;
    let formData = new FormData();
    formData.append("document_file", input.value);
    formData.set("title", input.id)

    const response = await postData(`/admin/upload_file`, formData);
    if(response && response.status === 200){
      // filter out the document if it already exists
      let newlyUploadedDoc = {url: response.data.url, documentType: signupDocConstants[input.name].documentType, title: signupDocConstants[input.name].title}
      if (signupDocs.length > 0) {
        let filteredsignupDocs = signupDocs.filter(item => {
          return item.documentType !== signupDocConstants[docName].documentType
        })
        return setSignupDocs([...filteredsignupDocs, newlyUploadedDoc])
        
      }
      setSignupDocs([...signupDocs, newlyUploadedDoc]);
    } else {
      seterrors({...errors, [docName]: "Uploading failed"})
      setfieldValues({...fieldValues, [docName]: ""})
    }
  }

  // navigate to either phone verification or email verification page according to verified condition
  const nextPageNavigation = (phoneVerified, emailVerified) => {
    if (phoneVerified && !emailVerified) { 
      console.log("navigating to email verification", phoneVerified, emailVerified)
      history.push("/verify-email", {phone: fieldValues.phone, password: fieldValues.password })
      return
    }
    console.log("navigating to phone verification", phoneVerified, emailVerified)
    history.push("/verify-otp", {phone: fieldValues.phone, password: fieldValues.password })
  }
  
  // executes on click submit button
  // make api call to sign if input field validation is success
  const signup = async () => {
    if (MandatoryFieldValidation()) {
      setDisableButton(true);
      setLoading(true);
      let response = await postData(`/auth/users/signup`, {
        first_name: fieldValues.firstName,
        last_name: fieldValues.lastName,
        address: "",
        location: [21.4858, 39.1925],
        phone: "966" + fieldValues.phone,
        password: fieldValues.password,
        email: fieldValues.email,
        documents: signupDocs,
        website: fieldValues.companyWebsite
      });
      console.log(response);
      if (response && response.data) {
        // data to store in local storage
        const dataToStore = {
          firstName: fieldValues.firstName,
          lastName: fieldValues.lastName,
          phone: fieldValues.phone,
          email: fieldValues.email,
          docs: signupDocs,
          website: fieldValues.companyWebsite,
          companyRegDoc: fieldValues.companyRegDoc,
          companylicenseDoc: fieldValues.companylicenseDoc,
          vatCertificateDoc: fieldValues.vatCertificateDoc,
        }
        setSignupStorage(dataToStore);
        SimpleAlert(
          t("Hi") +
            " " +
            response.data.first_name +
            " " +
            response.data.last_name,
          t("Account Created, please verify the account"),
          nextPageNavigation(response.data.signup_verification_status, response.data.signup_email_verification)
        );
        let username = fieldValues.firstName + " " + fieldValues.lastName;
        dispatch({
          type: "USER_NAME",
          payload: username,
        });
        localStorage.setItem("login_phone", fieldValues.phone);
        localStorage.setItem("login_email", fieldValues.email);
        localStorage.setItem("password", fieldValues.password);
      } else if (response && response.status && response.status === 400) {
        FailureAlert(
          t("Action Failed"),
          `${response.message?.error ?? t("Something went wrong")}`
        );
      } else {
        FailureAlert(
          t("Action Failed"),
          t("Something went wrong. Please try again later.")
        );
      }
      setLoading(false);
      setDisableButton(false);
    }
  };

  // memorise the object to track the changes
  const defaultValues = useMemo (() =>{
    return {
      defaultCompanyRegDoc: getLocalStorage("signup_companyRegDoc"),
      defaultCompanylicenseDoc: getLocalStorage("signup_companylicenseDoc"),
      defaultvatCertificateDoc: getLocalStorage("signup_vatCertificateDoc"),
    }
  }, [])

  // get data from local storage and set fieldValue state
  useEffect(() => {
    const data = getSignupStorage();
    if (data?.isDataAvailable) { 
      // setIsEdit(data.isDataAvailable === "true")
      setfieldValues({
        firstName: data.firstName,
        lastName: data.lastName,
        password: "",
        phone: data.phone,
        email: data.email,
        docs: data.docs,
        companyRegDoc: data.companyRegDoc,
        companylicenseDoc: data.companylicenseDoc,
        vatCertificateDoc: data.vatCertificateDoc,
        companyWebsite: data.website,
      })
    }
  }, [])

  return (
    <div className="create-account">
      {loading && <Spinner />}
      <img src={Images.shade} className="shade" />
      <div className="logo">
        {/* <img className="olly_logo" src={Images.olly_logo} /> */}
        <img src={imageSelector("logo")} />
      </div>
      <div className="content-section">
        <div className="content-details">
          <FrontPages
            subHeading={t("Already have an account?")}
            linkText="Log in"
            link="/"
          />
          <div className="input-area">
            <div className="input-element">
              <InputField
                placeholder="First name"
                id={"signup__first-name"}
                validate={validate}
                errorField={errors.firstName}
                maxLength={50}
                regex={/[0-9]/g}
                defaultValue={fieldValues.firstName}
              />
            </div>
            <div className="input-element">
              <InputField
                placeholder="Last name"
                id={"signup__last-name"}
                validate={validate}
                errorField={errors.lastName}
                maxLength={50}
                regex={/[0-9]/g}
                defaultValue={fieldValues.lastName}
              />
            </div>
            <div className="input-element">
              <InputField
                placeholder="5xxxxxxxx"
                label="Mobile number"
                maxLength={9}
                id={"signup__phone-number"}
                validate={validate}
                errorField={errors.phone}
                inputData="contact"
                regex={/[^0-9]/g}
                defaultValue={fieldValues.phone}
              />
            </div>
            <div className="input-element">
              <InputField
                placeholder="Email"
                id={"signup__email"}
                validate={validate}
                errorField={errors.email}
                maxLength={80}
                defaultValue={fieldValues.email}
              />
            </div>
            <div className="input-element">
              <PasswordField
                placeholder="Password"
                id={"signup__password"}
                validate={validate}
                isConfirmed={true}
                errorField={errors.password}
              />
            </div>
            <div className="input-element">
              <FileUpload 
                id={"signup__company-registration"}
                placeholder={"Commercial registration document"}
                errorField={errors.companyRegDoc}
                validate={validate}
                maxSize={5.50}
                accept=".jpeg, .png, .pdf"
                name={"companyRegDoc"}
                defaultValue={defaultValues?.defaultCompanyRegDoc}
              />
            </div>
            <div className="input-element">
              <FileUpload 
                id={"signup__company-license"}
                placeholder={"Company license"}
                errorField={errors.companylicenseDoc}
                validate={validate}
                maxSize={5.50}
                accept=".jpeg, .png, .pdf"
                name={"companylicenseDoc"}
                defaultValue={defaultValues?.defaultCompanylicenseDoc}

              />
            </div>
            <div className="input-element">
              <FileUpload 
                id={"signup__vat-certificate"}
                placeholder={"VAT certificate"}
                errorField={errors.vatCertificateDoc}
                validate={validate}
                maxSize={5.50}
                accept=".jpeg, .png, .pdf"
                name={"vatCertificateDoc"}
                defaultValue={defaultValues?.defaultvatCertificateDoc}
              />
            </div>
            <div className="input-element">
              <InputField
                placeholder="Company website(if any)"
                id={"signup__company-website"}
                validate={validate}
                // errorField={errors.firstName}
                maxLength={50}
                defaultValue={fieldValues.website}
              />
            </div>
            <div className="input-element">
              <CheckTC setChecked={setAgreement}/>
            </div>
            <div className="input-element" style={{marginTop: "40px"}}>
              <Button
                text="Sign up"
                onClickBtn={signup}
                isdisabled={!isEnable || disableButton}
              />
            </div>
          </div>
        </div>
        <div className="truck-image">
          <img src={Images.truck} />
        </div>
      </div>
    </div>
  );
};

export default Login;
