import React, { createContext, useState, useEffect, useReducer } from "react";
import "./App.css";
import LandingSection from "./pages/LandingSection";
import LandingSectionNoAuth from "./pages/LandingSectionNoAuth";
import reducer from "./reducer/reducer";
import rtlLanguages from "./utils/rtlLanguages";
import firebase from "./firebase";
import { putData } from "./api";

// Context for Authentication values
export const AuthContext = createContext();

const initialState = {
  isAuthenticated: false,
  api: localStorage.getItem("olly_url") || "",
  userName: "",
  profilePic: null,
  // notification: false,
};
function App() {
  const [state, dispatch] = useReducer(reducer, initialState);
  // for (let i = 0; i < localStorage.length; i++) {
  //   console.log(
  //     localStorage.key(i) + " : " + localStorage.getItem(localStorage.key(i))
  //   );
  // }

  if (
    window.location.origin === "https://userwebsite.ollylogistics.com" ||
    window.location.origin === "https://master.dfu8q8texq0kz.amplifyapp.com"
  ) {
    console.log = function () {};
  }

  let messaging;
  try {
    messaging = firebase.messaging();
  } catch (e) {
    console.log(e);
  }

  useEffect(() => {
    if (localStorage.getItem("access_token") && messaging) {
      messaging
        .requestPermission()
        .then(() => {
          console.log("have permission");
          return messaging.getToken();
        })
        .then((token) => {
          console.log(token);
          const getProfileDetails = async () => {
            let response = await putData("/notification/token/update", {
              token: token,
            });
            if (response && response.data) {
              console.log(response);
            }
          };
          getProfileDetails();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  // for persistent login
  useEffect(() => {
    if (localStorage.getItem("access_token")) {
      dispatch({
        type: "LOGIN",
      });
    }
    if (rtlLanguages.languages.includes(localStorage.getItem("Olly_lang"))) {
      document.body.classList.add("rtl");
    } else {
      document.body.classList.remove("rtl");
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      <div className="App">
        {!state.isAuthenticated ? <LandingSectionNoAuth /> : <LandingSection />}
      </div>
    </AuthContext.Provider>
  );
}

export default App;
