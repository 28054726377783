import React from "react";
import "./SearchBar.css";
import { useTranslation } from "react-i18next";

const Search = ({ text, placeholder, searchHandler }) => {
  const { t } = useTranslation();
  return (
    <div className="search">
      <i className="fa fa-search"></i>
      <input
        type="text"
        value={text}
        placeholder={t(placeholder)}
        onInput={(e) => searchHandler(e.target.value)}
        maxLength={20}
      />
    </div>
  );
};

export default Search;
