import React from "react";
import "./Spinner.css";
import { useTranslation } from "react-i18next";

// Spinner component for loading animation
// Spinner message can be customized by props

const Spinner = ({
    message = "Loading",
    opacity = { backgroundColor: "rgba(255, 255, 255, 0.95)" },
}) => {
    
  const { t } = useTranslation();
    return (
        <div className="spinner" style={opacity}>
            <p>{t(message)+"..."}</p>
        </div>
    );
};

export default Spinner;