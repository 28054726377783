function MandatoryFieldCheck(input) {
  let error = "";
  if (input && input.trim().length === 0) {
    error = "Cannot be whitespace alone";
  } else if (!input) {
    error = "Cannot be empty";
  }
  return error;
}
function ValidateName(input) {
  let error = "";
  if (input && input.trim().length === 0) {
    // error = "Cannot be whitespace alone";
    error = "Enter a valid name";
  } else if (!input) {
    // error = "Cannot be empty";
    error = "Mandatory";
  } else if (input.match("/^[A-Za-z\s]+$/")) {
    error = "Invalid name";
  } else if (input.length > 15) {
    error = "Length is too long";
  }
  return error;
}
function ValidateLoadWeight(input, unit) {
  let error = "";
  if (input && input.trim().length === 0) {
    error = "Cannot be whitespace alone";
  } else if (!input) {
    error = "Cannot be empty";
  // } else if (!input.match(/^[1-9]\d*$/)) {
  //   error = "Invalid weight";
  // } else if (unit === "kg") {
  //   if (input > 50000) error = "Weight should be less than 50000 kg"
  // } else if (unit === "t") {
  //   if (input > 50) error = "Weight should be less than 50 tone"
  }
  return error;
}
function ValidatePhoneNo(input) {
  let error = "";
  if (input && input.trim().length === 0) {
    error = "Enter a valid Mobile number";
  } else if (!input) {
    error = "Mandatory";
  } else if (!input.match(/^5/)) {
    error = "Phone number should start with 5";
  }else if (!input.match(/^[1-9]\d*$/)) {
    error = "Enter a valid Mobile number";
  } else if (input.length < 9) {
    // error = "Invalid phone number";
    error = "Enter a valid Mobile number";
  } else if (input.length > 9) {
    error = "Enter a valid Mobile number";
  }
  return error;
}
function ValidateFileUpload(input) {
  let error = "";
  if (!input) {
    return error = "Mandatory";
  }
  if (input.value.size > input.maxSize * 1048576) {
    error = "File size is too large"
    // error = `Document size - Max ${input.maxSize} MB`
  };
  // if (input && input.trim().length === 0) {
  //   error = "Cannot be whitespace alone";
  // } else if (!input) {
  //   error = "Cannot be empty";
  // } else if (!input.match(/^[1-9]\d*$/)) {
  //   error = "Invalid phone number";
  // } else if (input.length < 9) {
  //   error = "Invalid phone number";
  // } else if (input.length > 9) {
  //   error = "Invalid phone number";
  // }
  return error;
}
function ValidateEmail(input, isMandatory = true) {
  let error = "";
  if ((!isMandatory && input && input.trim().length === 0) || (!isMandatory && !input) ) {
    error = "";
  } else if (input && input.trim().length === 0 && isMandatory) {
    error = "Enter a valid email add";
  } else if (!input && isMandatory) {
    error = "Mandatory";
  } else if (
    !input.match(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
  ) {
    // error = "Invalid email";
    error = "Enter a valid email ID";
  }
  return error;
}
function ValidatePassword(input) {
  let error = "";
  if (input && input.trim().length === 0) {
    error = "Enter a valid password";
  } else if (!input) {
    // error = "Cannot be empty";
    error = "Mandatory";
  } else if (input.length < 8) {
    error = "Minimum 8 characters required";
  } else if (input.length > 16) {
    error = "Maximum length must be 16 characters";
  } else if (!input.match(".*[0-9].*")) {
    // error = "Atleast one number required";
    error = "Enter atleast 1 number";
  } else if (!input.match(".*[a-zA-Z].*")) {
    // error = "Atleast one letter required";
    error = "Enter atleast 1 alphabet";
  } else if (!input.match(".*[a-z].*")) {
    // error = "Atleast one small letter required";
    error = "Enter atleast 1 lower case letter";
  } else if (!input.match(".*[A-Z].*")) {
    // error = "Atleast one capital letter required";
    error = "Enter atleast 1 upper case letter";
  }
  // else if (!input.match(".*[@!#%&()^~{}].*")) {
  //   error = "Atleast one special character is required";
  // }
  return error;
}

export {
  ValidateName,
  ValidatePhoneNo,
  ValidateEmail,
  ValidatePassword,
  ValidateLoadWeight,
  MandatoryFieldCheck,
  ValidateFileUpload,
};
