import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Images from "../../assets";
import FrontPages from "../../components/FrontPages";
import Button from "../../components/SquareButton";
import {
  SimpleAlert,
  FailureAlert,
  SuccessAlert,
} from "../../utils/alertTemplate";
import { postData } from "../../api/NoAuthApi";
import "../../constants/ConfirmAlert.css";
import { useTranslation } from "react-i18next";
import imageSelector from "../../utils/LangImages";
import { otpTimerCount } from "../../constants";
import { removeSignupStorage } from "../../utils/localStorage";
import { secondsToHms } from "../../utils/formatDateTime";

const VerifyEmail = () => {
  const history = useHistory();
  const location = useLocation();

  const { t } = useTranslation();

  const [OTP, setOTP] = useState({
    ssn1: "",
    ssn2: "",
    ssn3: "",
    ssn4: "",
  });

  const [userPhone, setUserPhone] = useState();
  // const [userEmail, setuserEmail] = useState();
  const [timeLeft, setTimeLeft] = useState(otpTimerCount);
  const [formattedTimeLeft, setFormattedTimeLeft] = useState({
    minutes: "",
    seconds: ""
  });

  // execute on click resend button
  const reSend = async () => {
    let  response = await postData(`/users/email_verification_otp`, { phone: "966" + userPhone});
    if (response && response.data) {
      setTimeLeft(otpTimerCount)
      SuccessAlert(response.data);
    }
    else {
      SimpleAlert(
        t("Failed"),
        response.message?.error ?? t("Something went wrong")
      );
    }
    // }
  };

  // execute on click verify otp button
  const verifyOTP = async () => {
    let otp = OTP.ssn1 + OTP.ssn2 + OTP.ssn3 + OTP.ssn4;
    let  response = await postData(`/users/verify_email`, {
      phone: "966" + userPhone,
      // email: "966" + userEmail,
      otp: otp,
    });
    console.log(response);
    if (response && response.data) {
      localStorage.setItem("login_phone", userPhone);
      // localStorage.setItem("login_email", userEmail);
      sessionStorage.setItem("resetPassword_OTP", otp);
      removeSignupStorage();
      SuccessAlert(t("Email verified"), () => {
        history.replace("/welcome", {phone: location.state?.phone, password: location.state?.password});
      });
    } else if (response && response.status && response.status === 400) {
      FailureAlert(
        t("Failed"),
        `${response.message?.error ?? t("Something went wrong")}`
      );
    } else {
      FailureAlert(t("Action Failed"));
    }
  };
  // handle change in OTP input
  const handleChange = (e) => {
    const { maxLength, value, name } = e.target;
    const [fieldName, fieldIndex] = name.split("-");
    // Check if they hit the max character length
    if (value.length >= maxLength) {
      // Check if it's not the last input field
      if (parseInt(fieldIndex, 10) < 4) {
        // Get the next input field
        const nextSibling = document.querySelector(
          `input[name=ssn-${parseInt(fieldIndex, 10) + 1}]`
        );

        // If found, focus the next field
        if (nextSibling !== null) {
          nextSibling.focus();
        }
      }
    }
    setOTP({
      ...OTP,
      [`ssn${fieldIndex}`]: value,
    });
  };

  // navigate to home page if no phone number is passed from previous page
  useEffect(() => {
    console.log("phone:", localStorage.getItem("login_phone"));
    // console.log("email:", localStorage.getItem("login_email"));
    // setUserPhone(localStorage.getItem("login_phone"))
    // setuserEmail(localStorage.getItem("login_email"))
    // setuserName(localStorage.getItem("login_phone"));
    if (!location.state?.phone) return history.push("/");
    let phoneNumber = location.state.phone;
    console.log("phone from phone no verificaiton page >>", phoneNumber);
    setUserPhone(phoneNumber);
  }, [])

    // otp resend count down
    useEffect(() => {
      const {seconds, minutes} = secondsToHms(timeLeft)
      setFormattedTimeLeft({
        minutes,
        seconds
      })
      if(timeLeft===0){
         setTimeLeft(0)
      }
      if (!timeLeft) return;
      const intervalId = setInterval(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);
      return () => clearInterval(intervalId);
    }, [timeLeft]);
  
  return (
    <div className="verify-otp">
      <img src={Images.shade} className="shade" />
      <div className="logo">
        {/* <img className="olly_logo" src={Images.olly_logo} /> */}
        <img src={imageSelector("logo")} />
      </div>
      <div className="content-section">
        <div className="content-details">
          <FrontPages subHeading={t("Verify your email address")} />
          <div className="input-area">
            <div>
              <div className="input-element">
                <p>
                  {t("Check your email for the OTP.")}
                  <button disabled={timeLeft} className="resend-btn" onClick={reSend}>{t("Resend")}</button>
                  {/* <span className="otp__timer">{timeLeft !== 0 && `0:${timeLeft < 10 ? '0' : ''}${timeLeft}`}</span> */}
                  <span className="otp__timer">{timeLeft !== 0 && formattedTimeLeft.minutes +':'+ formattedTimeLeft.seconds}</span>
                </p>
              </div>
              <div className="input-element-otp">
                <input
                  autoFocus={true}
                  type="text"
                  name="ssn-1"
                  maxLength="1"
                  onChange={handleChange}
                />
                <input
                  type="text"
                  name="ssn-2"
                  maxLength="1"
                  onChange={handleChange}
                />
                <input
                  type="text"
                  name="ssn-3"
                  maxLength="1"
                  onChange={handleChange}
                />
                <input
                  type="text"
                  name="ssn-4"
                  maxLength="1"
                  onChange={handleChange}
                />
              </div>
              <div className="input-element" style={{ marginTop: "50px" }}>
                <Button
                  text={"Verify OTP"}
                  onClickBtn={verifyOTP}
                  // isdisabled={passwordError}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="truck-image">
          <img src={Images.truck} />
        </div>
      </div>
    </div>
  );
};

export default VerifyEmail;
