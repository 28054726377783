import React, { useState, useEffect, useRef, useContext } from "react";
import "./SideNav.css";
import Images from "../../assets";
import { NavLink, useLocation } from "react-router-dom";
import { getData } from "../../api";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../../App";
import { logout } from "../../api";
import firebase from "../../firebase";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import notificationCount from "../../utils/notificationCount";

export default function SideNav() {
  const [profileDetails, setProfileDetails] = useState({});

  const location = useLocation();
  const [active, setActive] = useState({});
  const [number_of_notification, setnumber_of_notification] = useState(0);
  // const { state } = useContext(AuthContext);
  
  const { state, dispatch } = useContext(AuthContext);
  const history = useHistory();

  let messaging;
  try {
    messaging = firebase.messaging();
  } catch (e) {
    console.log(e);
  }
  const { t } = useTranslation();

  const node = useRef();
  const [isOpen, setisOpen] = useState(false);

  document.documentElement.style.setProperty(
    "--vh",
    `${window.innerHeight * 0.01}px`
  );

  const openMenu = () => {
    setisOpen(true);
  };
  const closeMenu = () => {
    setisOpen(false);
  };
  useEffect(() => {
    // add when mounted
    document.addEventListener("mousedown", handleClick);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);
  useEffect(() => {
    window.addEventListener("resize", viewPort);
    return () => window.removeEventListener("resize", viewPort);
  }, []);
  const handleClick = (e) => {
    if (!node.current.contains(e.target)) {
      closeMenu();
      return;
    }
  };
  // set viewport height
  const viewPort = () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  };

  const logOut = () => {
    logout();
    dispatch({
      type: "LOGOUT",
    });
    try {
      messaging.deleteToken();
    } catch (e) {
      console.log(e);
    }
    history.push("/");
  };
  const profilePage = () => {
    history.push("/profile");
    closeMenu();
  };

  useEffect(() => {
    let timer = null;
    (async () => {
      setnumber_of_notification(await notificationCount());
    })();

    timer = setInterval(() => {
      (async () => {
        setnumber_of_notification(await notificationCount());
      })();
    }, 10000);

    return () => clearInterval(timer);
  }, []);
  // console.log(number_of_notification);
  try {
    messaging.onMessage((payload) => {
      console.log("onMessage", payload);
      let type = "success";
      if (!location.pathname.includes("/notifications")) {
        switch (type) {
          case "info":
            NotificationManager.info(
              payload.notification.body,
              payload.notification.title,
              3000,
              () => {
                history.push("/notifications");
              }
            );
            break;
          case "success":
            NotificationManager.success(
              payload.notification.body,
              payload.notification.title,
              3000,
              () => {
                history.push("/notifications");
              }
            );
            break;
          case "warning":
            NotificationManager.warning(
              payload.notification.body,
              payload.notification.title,
              3000,
              () => {
                history.push("/notifications");
              }
            );
            break;
          case "error":
            NotificationManager.error(
              payload.notification.body,
              payload.notification.title,
              3000,
              () => {
                history.push("/notifications");
              }
            );
            break;
        }
      }
    });
  } catch (e) {
    console.log(e);
  }

  useEffect(() => {
    let _active = {
      booking: false,
      history: false,
      transaction: false,
      notification: false,
      contact_us: false,
      changePassword: false,
      termsAndConditions: false,
      privacyPolicy: false
    };
    if (
      (location && location.pathname.includes("/booking")) ||
      location.pathname == "/"
    )
      _active.booking = true;
    else if (location && location.pathname.includes("/history"))
      _active.history = true;
    else if (location && location.pathname.includes("/transactions"))
      _active.transaction = true;
    else if (location && location.pathname.includes("/notifications"))
      _active.notification = true;
    else if (location && location.pathname.includes("/contact-us"))
      _active.contact_us = true;
    else if (location && location.pathname.includes("/change-password"))
      _active.changePassword = true;
    // else _active.booking = true;

    setActive(_active);
  }, [location]);

  useEffect(() => {
    const getProfileDetails = async () => {
      let response = await getData("/users/profile");
      if (response && response.data) {
        console.log(response);
        setProfileDetails({
          image: response.data.profile_pic
            ? response.data.profile_pic
            : Images.avatar,
          name: response.data.first_name
            ? response.data.first_name + " " + response.data.last_name
            : "-",
          email: response.data.email ? response.data.email : "-",
          phoneNo: response.data.phone ? response.data.phone : "-",
        });
      }
    };
    getProfileDetails();
  }, []);
  return (
    <div className="sidenav">
      <img
        className="handburg-icon"
        src={Images.handburg_icon}
        onClick={openMenu}
      />
      <div className={`dim-background ${isOpen ? "active" : "hide"}`}></div>
      <div className={`sidenav-div ${isOpen ? "active" : ""}`} ref={node}>
        <div className="sidenav-profile" onClick={profilePage}>
          <img src={state.profilePic ? state.profilePic : profileDetails.image} className="profile-pic" />
          <span className="text">
            {t("Hello")}, <span className="name">{profileDetails.name}</span>
          </span>
        </div>
        <NavLink
          exact
          to="/"
          className="nav-link"
          activeClassName="nav-link--active"
        >
          <span
            className={`title ${active.booking ? "active" : ""}`}
            onClick={closeMenu}
          >
            {t("Book a trailer")}
          </span>
        </NavLink>
        <NavLink
          exact
          to="/history"
          className="nav-link"
          activeClassName="nav-link--active"
        >
          <span
            className={`title ${active.history ? "active" : ""}`}
            onClick={closeMenu}
          >
            {t("History")}
          </span>
        </NavLink>
        <NavLink
          exact
          to="/transactions"
          className="nav-link"
          activeClassName="nav-link--active"
        >
          <span
            className={`title ${active.transaction ? "active" : ""}`}
            onClick={closeMenu}
          >
            {t("Transactions")}
          </span>
        </NavLink>
        <NavLink
          exact
          to="/notifications"
          className="nav-link"
          activeClassName="nav-link--active"
        >
          <span
            className={`title ${active.notification ? "active" : ""}`}
            onClick={closeMenu}
          >
            {t("Notifications")}
            {number_of_notification > 0 &&
              !location.pathname.includes("/notifications") && (
                <img src={Images.ovel} className="ovel" />
              )}
          </span>
        </NavLink>
        {/* <div className="contact-section"> */}
        <NavLink
          exact
          to="/contact-us"
          className="nav-link"
          activeClassName="nav-link--active"
        >
          <span
            className={`title ${active.contact_us ? "active" : ""}`}
            onClick={closeMenu}
          >
            {t("Contact Olly")}
          </span>
        </NavLink>

        <NavLink
          exact
          to="/terms-and-conditions"
          className="nav-link"
          activeClassName="nav-link--active"
          target="_blank"
        >
          <span
            className={`title ${active.termsAndConditions ? "active" : ""}`}
            onClick={closeMenu}
          >
            {t("Terms and Conditions")}
          </span>
        </NavLink>

        <NavLink
          exact
          to="/privacy-policy"
          className="nav-link"
          activeClassName="nav-link--active"
          target="_blank"
        >
          <span
            className={`title ${active.privacyPolicy ? "active" : ""}`}
            onClick={closeMenu}
          >
            {t("Privacy Policy")}
          </span>
        </NavLink>

        <NavLink
          exact
          to="/change-password"
          className="nav-link"
          activeClassName="nav-link--active"
        >
          <span
            className={`title ${active.changePassword ? "active" : ""}`}
            onClick={closeMenu}
          >
            {t("Change password")}
          </span>
        </NavLink>
        <span className={`title `} onClick={() => logOut()}>
          {t("Log out")}
        </span>
        <div className="social-login">
          {/* <img src={Images.facebook} /> */}
          <a href="https://www.facebook.com/" target="_blank" className="link">
            <i
              class="fa fa-facebook-square"
              style={{ fontSize: "30px", color: "#3b5998" }}
            />
          </a>
          {/* <img src={Images.instagram} /> */}
          <a href="https://www.instagram.com/" target="_blank" className="link">
            <i
              class="fa fa-instagram"
              style={{ fontSize: "30px", color: "#E1306C" }}
            />
          </a>
          {/* <img src={Images.twitter} /> */}
          <a href="https://www.twitter.com/" target="_blank" className="link">
            <i
              class="fa fa-twitter-square"
              style={{ fontSize: "30px", color: "#00acee" }}
            />
          </a>
        </div>
        {/* </div> */}
      </div>
      <NotificationContainer />
    </div>
  );
}
