import React from "react";
import Images from "../../assets";
import "./Footer.css";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <div className="footer">
      <div className="policy-texts">
        <a href="/privacy-policy" target="_blank" className="link">
          {t("Privacy Policy")}
        </a>
        <a href="/terms-and-conditions" target="_blank" className="link">
          {t("Terms and Conditions")}
        </a>
      </div>
      <div className="social-login">
        <a href="https://www.facebook.com/" target="_blank" className="link">
          {/* <img src={Images.facebook} className="social" /> */}
          <i
            class="fa fa-facebook-square"
            aria-hidden="true"
            style={{ color: "#3b5998" }}
          />
        </a>
        <a href="https://www.instagram.com/" target="_blank" className="link">
          {/* <img src={Images.instagram} className="social" /> */}
          <i
            class="fa fa-instagram"
            aria-hidden="true"
            style={{ color: "#E1306C" }}
          />
        </a>
        <a href="https://www.twitter.com/" target="_blank" className="link">
          {/* <img src={Images.twitter} className="social" /> */}
          <i
            class="fa fa-twitter-square"
            aria-hidden="true"
            style={{ color: "#00acee" }}
          />
        </a>
      </div>
    </div>
  );
};

export default Footer;
