import React from "react";
import "./NotificationCard.css";
import formatDateTime from "../../utils/formatDateTime";

const NotificationCard = ({ date, id, text, title }) => {
  return (
    <div className="notification-card">
      <p className="title">{title}</p>
      {text.split("**").map(data => (
        <p className = "notification-body">{data}</p>
      ))}
      <p className="date">{formatDateTime(date, "ddd, MMM DD ")} at {formatDateTime(date, " hh:mm a")}</p>
    </div>
  );
};

export default NotificationCard;
