import React from "react";
import "./NoResult.css";
import Images from "../../assets";
import { useTranslation } from "react-i18next";

const NoResult = () => {
  const { t } = useTranslation();
  return (
    <div className="no-result">
      <img src={Images.no_results} />
      <p>{t("Sorry! No results found")}</p>
    </div>
  );
};

export default NoResult;
