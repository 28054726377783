import React, { useState, useEffect } from "react";
import "./VerifyOTP.css";
import { useHistory, useLocation } from "react-router-dom";
import Images from "../../assets";
import FrontPages from "../../components/FrontPages";
import InputField from "../../components/InputField";
import Button from "../../components/SquareButton";
import {
  SimpleAlert,
  FailureAlert,
  SuccessAlert,
} from "../../utils/alertTemplate";
import { postData } from "../../api/NoAuthApi";
import "../../constants/ConfirmAlert.css";
import { ValidatePhoneNo } from "../../utils/Validation";
import { useTranslation } from "react-i18next";
import imageSelector from "../../utils/LangImages";
import { otpTimerCount } from "../../constants";
import { secondsToHms } from "../../utils/formatDateTime";

const Login = () => {
  const history = useHistory();
  const location = useLocation();
  const URL = location.pathname;

  const { t } = useTranslation();

  let isEnable = false;
  const [isverify, setisverify] = useState(false);
  const [OTP, setOTP] = useState({
    ssn1: "",
    ssn2: "",
    ssn3: "",
    ssn4: "",
  });

  const [passwordError, setpasswordError] = useState();
  const [userName, setuserName] = useState();
  const [timeLeft, setTimeLeft] = useState(otpTimerCount);
  const [formattedTimeLeft, setFormattedTimeLeft] = useState({
    minutes: "",
    seconds: ""
  });

  // return true if phone number field is empty
  const MandatoryFieldValidation = () => {
    let phone_error = ValidatePhoneNo(userName);

    setpasswordError(phone_error);
    return phone_error == "" ? true : false;
  };

  // execute on click send otp button
  const sendOTP = async () => { 
    if (MandatoryFieldValidation()) {
      let response; 
      if (URL === "/verify-otp") {
        response = await postData(`/users/signup_verification_otp`, {
          phone: "966" + userName,
        });
      } else if (URL === "/reset-password-otp") { 
        response = await postData(`/users/forgot_password`, {
          phone: "966" + userName,
        });
      }
      if (response && response.data) {
        console.log(response);
        setisverify(true);
        SuccessAlert(response.data);
      }
      // else if (response && response.status && response.status === 400) {
      //   confirmAlert({
      //     title: "Failed",
      //     message: response.message?.error ?? "Something went wrong",
      //     buttons: [
      //       {
      //         label: "Close",
      //         onClick: () => {
      //           return;
      //         },
      //       },
      //     ],
      //   });
      // }
      else {
        // confirmAlert({
        //   title: "Failed",
        //   message: response.message?.error ?? "Something went wrong",
        //   buttons: [
        //     {
        //       label: "Close",
        //       onClick: () => {
        //         return;
        //       },
        //     },
        //   ],
        // });
        SimpleAlert(
          t("Failed"),
          response.message?.error ?? t("Something went wrong")
        );
      }
    }
  };

  // execute on click resend button
  const reSend = async () => {
    if (MandatoryFieldValidation()) {
      let response;
      if (URL === "/verify-otp") {
        response = await postData(`/users/signup_verification_otp`, {
          phone: "966" + userName,
        });
      } else if (URL === "/reset-password-otp") {
        response = await postData(`/users/forgot_password`, {
          phone: "966" + userName,
        });
      }
      if (response && response.data) {
        setTimeLeft(otpTimerCount)
        SuccessAlert(response.data);
      }
      // else if (response && response.status && response.status === 400) {
      // confirmAlert({
      //   title: "Failed",
      //   message: response.message?.error ?? "Something went wrong",
      //   buttons: [
      //     {
      //       label: "Close",
      //       onClick: () => {
      //         return;
      //       },
      //     },
      //   ],
      // });
      // }
      else {
        // confirmAlert({
        //   title: "Failed",
        //   message: response.message?.error ?? "Something went wrong",
        //   buttons: [
        //     {
        //       label: "Close",
        //       onClick: () => {
        //         return;
        //       },
        //     },
        //   ],
        // });
        SimpleAlert(
          t("Failed"),
          response.message?.error ?? t("Something went wrong")
        );
      }
    }
  };

  // execute on click verify otp button
  const verifyOTP = async () => {
    let otp = OTP.ssn1 + OTP.ssn2 + OTP.ssn3 + OTP.ssn4;
    let response;
    if (URL === "/verify-otp") {
      response = await postData(`/users/verify_phone`, {
        phone: "966" + userName,
        otp: otp,
      });
    } else if (URL === "/reset-password-otp") {
      response = await postData(`/users/verify_otp`, {
        phone: "966" + userName,
        otp: otp,
      });
    }
    console.log(response);
    if (response && response.data) {
      localStorage.setItem("login_phone", userName);
      sessionStorage.setItem("resetPassword_OTP", otp);
      if (URL === "/verify-otp") {
        SuccessAlert(t("Mobile number verified"), () => {
          // history.push("/welcome");
          history.replace("/verify-email", {phone: userName, password: location.state?.password});
        });
      } else if (URL === "/reset-password-otp") {
        SuccessAlert(t("OTP verified"), () => history.push("/reset-password"));
      }
    } else if (response && response.status && response.status === 400) {
      FailureAlert(
        t("Failed"),
        `${response.message?.error ?? t("Something went wrong")}`
      );
    } else {
      FailureAlert(t("Action Failed"));
    }
  };

  const validate = (inputText) => {
    // let error = "";
    setuserName(inputText.value);
    // error = ValidatePhoneNo(inputText.value);
    // setpasswordError(error);
  };
  isEnable = true;
  // handle OTP input change
  const handleChange = (e) => {
    const { maxLength, value, name } = e.target;
    const [fieldName, fieldIndex] = name.split("-");
    // Check if they hit the max character length
    if (value.length >= maxLength) {
      // Check if it's not the last input field
      if (parseInt(fieldIndex, 10) < 4) {
        // Get the next input field
        const nextSibling = document.querySelector(
          `input[name=ssn-${parseInt(fieldIndex, 10) + 1}]`
        );

        // If found, focus the next field
        if (nextSibling !== null) {
          nextSibling.focus();
        }
      }
    }
    setOTP({
      ...OTP,
      [`ssn${fieldIndex}`]: value,
    });
  };

  // set isVerify state according to url path
  // navigate to home page if no phone number is passed from previous page
  useEffect(() => {
    if (URL === "/verify-otp") {
      console.log("location from phone verification", location)
      setisverify(true)
      console.log("Phone:", localStorage.getItem("login_phone"));
      // setuserName(localStorage.getItem("login_phone"));
      if (!location.state?.phone) return history.push("/");
      let phoneNumber = location.state.phone;
      console.log("phone from phone no verificaiton page >>", phoneNumber);
      setuserName(phoneNumber);
    }
  }, []);

  // otp resend count down
  useEffect(() => {
    const {seconds, minutes} = secondsToHms(timeLeft)
    setFormattedTimeLeft({
      minutes,
      seconds
    })
    if(timeLeft===0){
       setTimeLeft(0)
    }
    if (!timeLeft) return;
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [timeLeft]);
  
  return (
    <div
      className="verify-otp"
      // style={{
      //   background: `url(${Images.trailer_background})`,
      //   backgroundPosition: "top 0vh right 0vw",
      //   backgroundRepeat: "no-repeat",
      // }}
    >
      <img src={Images.shade} className="shade" />
      <div className="logo">
        {/* <img className="olly_logo" src={Images.olly_logo} /> */}
        <img src={imageSelector("logo")} />
      </div>
      <div className="content-section">
        <div className="content-details">
          {URL === "/verify-otp" ? (
            <FrontPages subHeading={t("Verify your mobile number")} />
          ) : (
            <FrontPages
              subHeading={t("Reset your password using mobile number")}
            />
          )}
          <div className="input-area">
            {(URL !== "/verify-otp" || !isverify )&& 
              <div className="input-element">
                <InputField
                  placeholder="5xxxxxxxx"
                  label="Mobile number"
                  maxLength={9}
                  inputData="contact"
                  validate={validate}
                  errorField={passwordError}
                />
              </div>
            }
            {isverify && (
              <div>
                <div className="input-element">
                  <p>
                    {t("Check your SMS for the OTP.")}
                    <button disabled={timeLeft} className="resend-btn"  onClick={reSend}>{t("Resend")}</button>
                    {/* <span className="otp__timer">{timeLeft !== 0 && `0:${timeLeft < 10 ? '0' : ''}${timeLeft}`}</span> */}
                    <span className="otp__timer">{timeLeft !== 0 && formattedTimeLeft.minutes +':'+ formattedTimeLeft.seconds}</span>
                  </p>
                </div>
                <div className="input-element-otp">
                  <input
                    autoFocus={true}
                    type="text"
                    name="ssn-1"
                    maxLength="1"
                    onChange={handleChange}
                  />
                  <input
                    type="text"
                    name="ssn-2"
                    maxLength="1"
                    onChange={handleChange}
                  />
                  <input
                    type="text"
                    name="ssn-3"
                    maxLength="1"
                    onChange={handleChange}
                  />
                  <input
                    type="text"
                    name="ssn-4"
                    maxLength="1"
                    onChange={handleChange}
                  />
                </div>
                <div className="input-element" style={{ marginTop: "50px" }}>
                  <Button
                    text={"Verify OTP"}
                    onClickBtn={verifyOTP}
                    isdisabled={passwordError}
                  />
                </div>
              </div>
            )}
            {!isverify && (
              <div className="input-element" style={{ marginTop: "50px" }}>
                <Button
                  text={"Send OTP"}
                  onClickBtn={sendOTP}
                  isdisabled={!isEnable}
                />
              </div>
            )}
          </div>
        </div>
        <div className="truck-image">
          <img src={Images.truck} />
        </div>
      </div>
    </div>
  );
};

export default Login;
