import React from "react";
import "./CancelledBooking.css";
import Images from "../../assets";
import Header from "../../components/Header";
import ActionStatus from "../../components/ActionStatus";
import { useTranslation } from "react-i18next";

// this component will get renderd after successful booking cancellation
const PaymentSuccessful = () => {
  const { t } = useTranslation();
  return (
    <div className="cancelled-booking">
      <ActionStatus
        heading="Cancelled booking"
        image={Images.cancelled}
        text={t("The booking has been successfully cancelled") + ". " + t("Your refund is initiated") + "."}
      />
    </div>
  );
};

export default PaymentSuccessful;
